/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  formatScore,
  useScoreboardContext,
} from "../../../contexts/ScoreboardContext";
import Slide from "react-reveal/Slide";
import MapCards from "../MapCards";
import useTranslations from "../../../contexts/useTranslations";
import SlideRight from "../Sliders/SlideRight";
import PropTypes from "prop-types";

export const setSize = (property, size) => {
  return property.substring(0, size).toUpperCase();
};

function ScoreboardGraphic9(props) {
  const {
    currentScore,
    setColors,
    teamA,
    teamB,
    time,
    timeIsRed,
    addedTime,
    currentSport,
    crest,
  } = useScoreboardContext();

  const [teamHome, setTeamHome] = useState();
  const [teamAway, setTeamAway] = useState();
  const t = useTranslations();

  useEffect(() => {
    if (teamA) setTeamHome(setSize(teamA, 3));
  }, [teamA]);

  useEffect(() => {
    if (teamB) setTeamAway(setSize(teamB, 3));
  }, [teamB]);

  useEffect(() => {
    // let color = colors;
    setColors((color) => {
      return { ...color, c1: "red", c2: "green", c3: "white", c4: "white" };
    });
  }, []);

  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        viewBox="0 0 511 125"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1={407}
            x2={407}
            y1={45}
            y2={3}
            gradientTransform="matrix(1 0 0 -1 0 92)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#fcfefd" />
            <stop offset={0.27} stopColor="#f2f2f2" />
            <stop offset={0.51} stopColor="#e5e5e6" />
            <stop offset={0.72} stopColor="#e7e7e7" />
            <stop offset={1} stopColor="#efefef" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1={104}
            x2={104}
            y1={45}
            y2={3}
            gradientTransform="matrix(1 0 0 -1 0 92)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0} stopColor="#fcfefd" />
            <stop offset={0.27} stopColor="#f2f2f2" />
            <stop offset={0.51} stopColor="#e5e5e6" />
            <stop offset={0.72} stopColor="#e7e7e7" />
            <stop offset={1} stopColor="#efefef" />
          </linearGradient>
          <style>
            {`.cls-1{fill:none}.cls-2{letter-spacing:-.09em}.cls-5,.cls-6{isolation:isolate}.cls-5{fill:#fff}.cls-6{font-size:25px}.cls-7{fill:#f5d31d}.cls-8{fill:#216098}.cls-5{font-family:Oswald-SemiBold,Oswald;font-size:${
              currentSport === "r" ? 28 : 40
            }px}.cls-6{font-family:TitilliumWeb-Regular,"Titillium Web"}`}
          </style>
        </defs>
        <AddedTimeComponent addedTime={addedTime} />
        <path
          d="M303 47h208v42H303z"
          style={{
            fill: "url(#linear-gradient)",
          }}
        />
        <path d="M471 50h36v36h-36z" className="cls-1" />
        <text
          className="cls-6"
          transform={`translate(${crest[1] ? 390 : 409.63} 76.31)`}
          textAnchor="middle"
        >
          <tspan x={0} y={0} className="cls-2">
            {teamB}
          </tspan>
        </text>
        <path
          d="M0 47h208v42H0z"
          style={{
            fill: "url(#linear-gradient-2)",
          }}
        />
        <path d="M3 50h36v36H3z" className="cls-1" />
        <text
          className="cls-6"
          transform={`translate(${crest[0] ? 120 : 100} 76.31)`}
          textAnchor="middle"
        >
          <tspan x={0} y={0} className="cls-2">
            {teamA}
          </tspan>
        </text>
        <path
          d="M253 41h-64c10.3 10.97 14.1 36.57 14.71 48H253V41ZM258 41h64c-10.3 10.97-14.1 36.57-14.71 48H258V41Z"
          className="cls-7"
        />
        <path
          d="M249 37h-50c10.43 12.45 12.08 39.85 11.59 52H249V37ZM262 37h50c-10.43 12.45-12.08 39.85-11.59 52H262V37Z"
          className="cls-8"
        />
        <path d="M218 37h75v52h-75z" className="cls-8" />
        <text
          className="cls-5"
          transform={`translate(230.96 ${currentSport === "r" ? 74 : 79.58})`}
          textAnchor="middle"
        >
          <tspan x={0} y={0}>
            {currentScore[0]}
          </tspan>
        </text>
        <text
          className="cls-5"
          transform={`translate(278 ${currentSport === "r" ? 74 : 79.58})`}
          textAnchor="middle"
        >
          <tspan x={0} y={0}>
            {currentScore[2]}
          </tspan>
        </text>
        <text
          className="cls-5"
          transform={`translate(251.06 ${currentSport === "r" ? 72 : 77.58})`}
        >
          <tspan x={0} y={0}>
            {"-"}
          </tspan>
        </text>
        <path
          d="M222.5 0h65c9.11 0 16.5 7.39 16.5 16.5S296.61 33 287.5 33h-65c-9.11 0-16.5-7.39-16.5-16.5S213.39 0 222.5 0Z"
          fill={timeIsRed ? "red" : "#216098"}
        />
        <text
          style={{
            fontSize: 25,
            isolation: "isolate",
            fill: "#fff",
            fontFamily: "TitilliumWeb-Bold",
          }}
          transform="translate(223.32 24.81)"
        >
          <tspan x={0} y={0}>
            {time}
          </tspan>
        </text>
        <MapCards x={145} y={95} team={0} gap={[30, 8]} />
        <MapCards x={310} y={95} team={1} gap={[30, 8]} />
        {crest[0] !== "" ? (
          <image
            href={crest[0]} // URL for the image (modern usage)
            x="25px"
            y="48px"
            width="40"
            height="40"
          />
        ) : (
          ""
        )}
        {crest[1] !== "" ? (
          <image
            href={crest[1]} // URL for the image (modern usage)
            x="450px"
            y="48px"
            width="40"
            height="40"
          />
        ) : (
          ""
        )}
      </svg>
    </div>
  );
}

const AddedTimeComponent = ({ addedTime }) => {
  return (
    <SlideRight start={220} finish={270} show={Number(addedTime) === 0}>
      <rect x="0" y="0" width="80" height="33" fill="#fff" rx="16.5"></rect>
      <text
        x="52"
        y="19"
        dominantBaseline="middle"
        textAnchor="middle"
        fill="#af2a2d"
        fontSize="24"
        fontFamily="'Exo 2', sans-serif"
      >
        +{addedTime}
      </text>
    </SlideRight>
  );
};

AddedTimeComponent.propTypes = {
  addedTime: PropTypes.any,
};

export default ScoreboardGraphic9;
