import React, { useEffect } from "react";
import { useScoreboardContext } from "../../../../contexts/ScoreboardContext";
import { useTennisContext } from "../TennisContext";

function TennisBlueScoreboard() {
  const { teamA, teamB, colors, setColors, handleChangeColor } =
    useScoreboardContext();

  const { sets, legs, currentScore, isFirstServer, notice, showNotice } =
    useTennisContext();

  useEffect(() => {
    setColors((prev) => ({
      ...prev,
      c1: "#fff",
      c2: "#3065c1",
      c3: "#3065c1",
      c4: "#071635",
      c5: "#fff",
      c6: "#fff",
      c7: "#071635",
    }));
  }, []);
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 354 108">
      <defs>
        <style>
          {
            ".cls-3,.cls-4,.cls-7{font-size:28px;font-family:Roboto-Medium,Roboto;font-weight:500}.cls-5{letter-spacing:.00928em}.cls-6{letter-spacing:-.07324em}"
          }
        </style>
      </defs>
      <g id="backgrounds">
        <path
          id="lg-white"
          className="cls-1"
          d="M11 27h226v81H11z"
          fill={colors.c1}
          onClick={(e) => handleChangeColor("c1", e, [0, 0])}
        ></path>
        <path
          id="green-left-bg"
          className="cls-2"
          d="M0 27h11v81H0z"
          fill={colors.c4}
          onClick={(e) => handleChangeColor("c4", e, [0, 0])}
        ></path>
        <path
          id="green-right-bg"
          className="cls-2"
          d="M237 27h39v81h-39z"
          fill={colors.c4}
          onClick={(e) => handleChangeColor("c4", e, [0, 0])}
        ></path>
        <path
          id="purple-right-bg"
          className="cls-3"
          d="M276 27h39v81h-39z"
          fill={colors.c3}
          onClick={(e) => handleChangeColor("c3", e, [0, 0])}
        ></path>
      </g>
      <g id="Names">
        <text
          className="cls-4"
          transform="translate(16.872 57.882)"
          fill={colors.c2}
          onClick={(e) => handleChangeColor("c2", e, [0, 0])}
        >
          {teamA}
        </text>
        <text
          className="cls-4"
          transform="translate(15.872 98.882)"
          fill={colors.c2}
          onClick={(e) => handleChangeColor("c2", e, [0, 0])}
        >
          {teamB}
        </text>
      </g>
      <g id="Sets">
        <text
          className="cls-7"
          transform="translate(249 57.882)"
          fill={colors.c5}
          onClick={(e) => handleChangeColor("c5", e, [0, 0])}
        >
          {sets[0]}
        </text>
        <text
          className="cls-7"
          transform="translate(249 98.882)"
          fill={colors.c5}
          onClick={(e) => handleChangeColor("c5", e, [0, 0])}
        >
          {sets[1]}
        </text>
      </g>
      <g id="Legs">
        <text
          className="cls-7"
          transform="translate(286.915 57.882)"
          fill={colors.c6}
          onClick={(e) => handleChangeColor("c6", e, [0, 0])}
        >
          {legs[0]}
        </text>
        <text
          className="cls-7"
          transform="translate(286.915 98.882)"
          fill={colors.c6}
          onClick={(e) => handleChangeColor("c6", e, [0, 0])}
        >
          {legs[1]}
        </text>
      </g>
      <g id="AD">
        <path
          id="small-white-bg"
          className="cls-1"
          fill={colors.c1}
          onClick={(e) => handleChangeColor("c1", e, [0, 0])}
          d="M315 27h39v81h-39z"
        ></path>
        <text
          className="cls-4"
          textAnchor="middle"
          transform="translate(334 57.882)"
          fill={colors.c7}
          onClick={(e) => handleChangeColor("c7", e, [0, 0])}
        >
          {currentScore?.[0]?.toString()}
        </text>
        <text
          className="cls-4"
          textAnchor="middle"
          transform="translate(334 98.882)"
          fill={colors.c7}
          onClick={(e) => handleChangeColor("c7", e, [0, 0])}
        >
          {currentScore?.[1]?.toString()}
        </text>
      </g>
      {showNotice ? (
        <g id="Notice">
          <path
            id="small-white-bg-2"
            data-name="small-white-bg"
            className="cls-2"
            d="M0 0h146v27H0z"
            fill={colors.c4}
            onClick={(e) => handleChangeColor("c4", e, [0, 0])}
          />
          <text
            transform="translate(73 20.205)"
            textAnchor="middle"
            style={{
              fontSize: "17.10517px",
              fontFamily: "Roboto-Medium,Roboto",
              fontWeight: 500,
            }}
            fill={colors.c5}
            onClick={(e) => handleChangeColor("c5", e, [0, 0])}
          >
            {notice}
          </text>
        </g>
      ) : null}
      <g id="Layer_8" data-name="Layer 8">
        {isFirstServer ? (
          <circle
            className="cls-3"
            cx={220}
            cy={49}
            r={4}
            fill={colors.c3}
            onClick={(e) => handleChangeColor("c3", e, [0, 0])}
          />
        ) : (
          <circle
            className="cls-3"
            cx={220}
            cy={90}
            r={4}
            fill={colors.c3}
            onClick={(e) => handleChangeColor("c3", e, [0, 0])}
          />
        )}
      </g>
      <g id="Line">
        <path
          style={{
            opacity: 0.61,
            stroke: "#9e9e9e",
            strokeWidth: ".25px",
            fill: "none",
            strokeMiterlimit: 10,
          }}
          d="M11 69h400"
        />
      </g>
    </svg>
  );
}

export default TennisBlueScoreboard;
