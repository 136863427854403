import React from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";

import { useTennisContext } from "../../../scoreboard-app/components/Tennis/TennisContext";
import ToggleButtons from "../../ToggleButtons";
import PickleballModalSection from "../PickleballModalSection";
import TennisModalSection from "../TennisModalSection";
import GenerateCodeButton from "./components/MSGenerateCodeButton";
import useTranslations from "../../../contexts/useTranslations";

function TennisSettingsModal() {
  const t = useTranslations();
  const {
    setShowSettingsModal,
    gameModeLegs,
    setGameModeLegs,
    firstToGames,
    setFirstToGames,
    tiebreakLegs,
    setTiebreakLegs,
    isManualScores,
    setIsManualScores,
    initiateDB,
    tennisVariant,
    setTennisVariant,
    firstToPoints,
    setFirstToPoints,
    totalNumberOfGames,
    setTotalNumberOfGames,
    resetScoreboard,
  } = useTennisContext();

  const radios = [
    { name: t("noun:tennis"), value: "0" },
    // { name: "Ping Pong", value: "1" },
    { name: t("noun:pickle-ball"), value: "2" },
  ];

  return (
    <div>
      <Modal.Dialog
        centered
        style={{
          top: -60,
          left: 0,
          zIndex: 400,
          position: "absolute",
          minWidth: 530,
          maxWidth: 530,
          minHeight: 15,
        }}
      >
        <Modal.Body style={{ minHeight: 250 }}>
          <h5 className="text-center">{t("noun:settings")}</h5>
          <Container>
            <Row>
              <ToggleButtons
                variant={tennisVariant}
                setVariant={setTennisVariant}
                radios={radios}
              />
            </Row>
            {tennisVariant === "0" ? (
              <TennisModalSection
                gameModeLegs={gameModeLegs}
                setGameModeLegs={setGameModeLegs}
                firstToGames={firstToGames}
                setFirstToGames={setFirstToGames}
                tiebreakLegs={tiebreakLegs}
                setTiebreakLegs={setTiebreakLegs}
              />
            ) : (
              <PickleballModalSection
                firstToPoints={firstToPoints}
                setFirstToPoints={setFirstToPoints}
                totalNumberOfGames={totalNumberOfGames}
                setTotalNumberOfGames={setTotalNumberOfGames}
              />
            )}
            <Row>
              <Col className="d-flex justify-content-center">
                <GenerateCodeButton initiateDB={initiateDB} />
              </Col>

              <Col className="d-flex justify-content-center">
                {tennisVariant === "0" ? (
                  <Button
                    className="mt-0 w-100"
                    variant="success"
                    onClick={() => setIsManualScores(!isManualScores)}
                  >
                    {!isManualScores
                      ? t("noun:manual-scores")
                      : t("noun:automatic-scores")}
                  </Button>
                ) : (
                  <Button
                    className="mt-0 w-100"
                    variant="success"
                    onClick={() => resetScoreboard()}
                  >
                    {"Reset Scoreboard"}
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {/* <Row className="fixed-bottom"> */}
          <Button
            style={{ float: "right", width: "20%" }}
            className="mt-2"
            variant="secondary"
            onClick={() => setShowSettingsModal(false)}
          >
            Close
          </Button>
          {/* </Row> */}
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  );
}

export default TennisSettingsModal;
