import ScoreboardGraphic1 from "./ScoreboardStyles/ScoreboardGraphic1";
import ScoreboardGraphic2 from "./ScoreboardStyles/ScoreboardGraphic2";
import ScoreboardGraphic3 from "./ScoreboardStyles/ScoreboardGraphic3";
import ScoreboardGraphic4 from "./ScoreboardStyles/ScoreboardGraphic4";
import ScoreboardGraphic5 from "./ScoreboardStyles/ScoreboardGraphic5";
import ScoreboardGraphic6 from "./ScoreboardStyles/ScoreboardGraphic6";
import ScoreboardGraphic7 from "./ScoreboardStyles/ScoreboardGraphic7";
import DartsScoreboard from "./ScoreboardStyles/DartsScoreboard";
import DartsScoreboardSets from "./ScoreboardStyles/DartsScoreboardSets";
import PoolScoreboard1 from "./ScoreboardStyles/Pool/PoolScoreboard1";
import PoolScoreboard2 from "./ScoreboardStyles/Pool/PoolScoreboard2";
import PoolScoreboard3 from "./ScoreboardStyles/Pool/PoolScoreboard3";
import TennisBlueScoreboard from "./Tennis/ScoreboardStyles/TennisBlueScoreboard";
import TennisDGreenScoreboard from "./Tennis/ScoreboardStyles/TennisDGreenScoreboard";
import TennisBlueWhiteScoreboard from "./Tennis/ScoreboardStyles/TennisBlueWhiteScoreboard";
import ScoreboardGraphic8 from "./ScoreboardStyles/ScoreboardGraphic8";
import PickleballBlue from "./Tennis/ScoreboardStyles/PickleballBlue";
import PickleballRed from "./Tennis/ScoreboardStyles/PickleballRed";
import PickleballGreen from "./Tennis/ScoreboardStyles/PickleballGreen";
import DartsScoreboard2 from "./ScoreboardStyles/DartsScoreboard2";
import ScoreboardGraphic9 from "./ScoreboardStyles/ScoreboardGraphic9";
import ScoreboardGraphic10 from "./ScoreboardStyles/ScoreboardGraphic10";
import BasketballScoreboard2 from "./ScoreboardStyles/Basketball/BasketballScoreboard2";
import BasketballScoreboard3 from "./ScoreboardStyles/Basketball/BasketballScoreboard3";
import ScoreboardGraphic11 from "./ScoreboardStyles/ScoreboardGraphic11";

export const sportsSettings = {
  s: {
    playerA: "Team A",
    playerB: "Team B",
    sport: "s",
    halfLength: 45,
    scoreboards: [
      {
        sb: ScoreboardGraphic10,
        hasAddedTime: true,
        hasCrests: true,
        scaleMonitor: 1.5,
        topRightMonitorOffset: [-0.12, 0],
        bottomLeftMonitorOffset: [0, -0.1],
        bottomRightMonitorOffset: [-0.12, -0.1],
        middleMonitorOffset: [-0.25, 0.025],
      }, //
      {
        sb: ScoreboardGraphic7,
        hasAddedTime: true,
        hasCrests: true,
        scaleMonitor: 1.5,
        topRightMonitorOffset: [-0.12, 0],
        bottomLeftMonitorOffset: [0, 0.02],
        bottomRightMonitorOffset: [-0.12, -0.02],
        middleMonitorOffset: [-0.25, 0.025],
      }, //
      {
        sb: ScoreboardGraphic9,
        hasAddedTime: true,
        hasCrests: true,
        scaleMonitor: 1.5,
        topRightMonitorOffset: [-0.15, 0],
        bottomLeftMonitorOffset: [0, -0.1],
        bottomRightMonitorOffset: [-0.15, -0.1],
        middleMonitorOffset: [-0.25, 0.025],
      }, //
      {
        sb: ScoreboardGraphic11,
        hasAddedTime: true,
        hasCrests: true,
        hasWomens: true,
        scaleMonitor: 1.5,
        topRightMonitorOffset: [-0.15, 0],
        bottomLeftMonitorOffset: [0, -0.1],
        bottomRightMonitorOffset: [-0.15, -0.1],
        middleMonitorOffset: [-0.25, 0.025],
      }, // FAI new
      {
        sb: ScoreboardGraphic6,
        hasAddedTime: false,
        hasCrests: true,
        scaleMonitor: 1.5,
        topRightMonitorOffset: [-0.15, 0],
        bottomLeftMonitorOffset: [0, -0.1],
        bottomRightMonitorOffset: [-0.15, -0.1],
        middleMonitorOffset: [-0.25, 0.025],
      }, // cork city
      {
        sb: ScoreboardGraphic8,
        hasAddedTime: false,
        hasCrests: true,
        corkColors: true,
        scaleMonitor: 1.5,
        topRightMonitorOffset: [-0.15, 0],
        bottomLeftMonitorOffset: [0, -0.1],
        bottomRightMonitorOffset: [-0.15, -0.1],
        middleMonitorOffset: [-0.25, 0.025],
      }, // cork city 2
      {
        sb: ScoreboardGraphic5,
        hasAddedTime: false,
        hasCrests: false,
        scaleMonitor: 1.2,
        topLeftMonitorOffset: [-0.1, 0.02],
        topRightMonitorOffset: [-0.1, 0.02],
        bottomLeftMonitorOffset: [-0.1, -0.2],
        bottomRightMonitorOffset: [-0.1, -0.2],
        middleMonitorOffset: [-0.25, -0.15],
      }, //sky
      {
        sb: ScoreboardGraphic3,
        hasAddedTime: true,
        hasCrests: false,
        scaleMonitor: 1.5,
        topRightMonitorOffset: [-0.12, 0],
        bottomLeftMonitorOffset: [0, -0.1],
        bottomRightMonitorOffset: [-0.12, -0.1],
        middleMonitorOffset: [-0.25, 0.025],
      }, // rte
      {
        sb: ScoreboardGraphic2,
        hasAddedTime: false,
        hasCrests: true,
        scaleMonitor: 1.5,
        topRightMonitorOffset: [-0.12, 0],
        bottomLeftMonitorOffset: [0, -0.1],
        bottomRightMonitorOffset: [-0.12, -0.1],
        middleMonitorOffset: [-0.25, 0.025],
      }, // crests
      {
        sb: ScoreboardGraphic1,
        hasAddedTime: false,
        hasCrests: false,
        scaleMonitor: 1.5,
        topRightMonitorOffset: [-0.12, 0],
        bottomLeftMonitorOffset: [0, -0.1],
        bottomRightMonitorOffset: [-0.12, -0.1],
        middleMonitorOffset: [-0.25, 0.025],
      },
      {
        sb: ScoreboardGraphic4,
        hasAddedTime: true,
        hasCrests: false,
        scaleMonitor: 1.5,
        topRightMonitorOffset: [-0.12, 0],
        bottomLeftMonitorOffset: [0, -0.1],
        bottomRightMonitorOffset: [-0.12, -0.1],
        middleMonitorOffset: [-0.25, 0.025],
      }, // last
    ],
  },
  f: {
    playerA: "Team A",
    playerB: "Team B",
    sport: "f",
    halfLength: 30,
    scoreboards: [
      {
        sb: ScoreboardGraphic3,
        hasAddedTime: true,
        hasCrests: false,
        hasTotalPoints: true,
        scaleMonitor: 1.5,
        topRightMonitorOffset: [-0.12, 0],
        bottomLeftMonitorOffset: [0, -0.05],
        bottomRightMonitorOffset: [-0.12, -0.05],
        middleMonitorOffset: [-0.25, 0.025],
      }, // rte
      {
        sb: ScoreboardGraphic2,
        hasAddedTime: true,
        hasCrests: true,
        scaleMonitor: 1.5,
        topRightMonitorOffset: [-0.12, 0],
        bottomLeftMonitorOffset: [0, -0.1],
        bottomRightMonitorOffset: [-0.12, -0.1],
        middleMonitorOffset: [-0.25, 0.025],
      }, // crests
      {
        sb: ScoreboardGraphic1,
        hasAddedTime: false,
        hasCrests: false,
        scaleMonitor: 1.5,
        topRightMonitorOffset: [-0.12, 0],
        bottomLeftMonitorOffset: [0, -0.1],
        bottomRightMonitorOffset: [-0.12, -0.1],
        middleMonitorOffset: [-0.25, 0.025],
      },
    ],
  },
  r: {
    playerA: "Team A",
    playerB: "Team B",
    halfLength: 40,
    sport: "r",
    scoreboards: [
      {
        sb: ScoreboardGraphic10,
        hasAddedTime: true,
        hasCrests: true,
        scaleMonitor: 1.3,
        topRightMonitorOffset: [-0.05, 0],
        bottomLeftMonitorOffset: [0, -0.1],
        bottomRightMonitorOffset: [-0.05, -0.1],
        middleMonitorOffset: [-0.25, 0.025],
      }, //
      {
        sb: ScoreboardGraphic2,
        hasAddedTime: true,
        hasCrests: true,
        scaleMonitor: 1.5,
        topRightMonitorOffset: [-0.12, 0],
        bottomLeftMonitorOffset: [0, -0.1],
        bottomRightMonitorOffset: [-0.12, -0.1],
        middleMonitorOffset: [-0.25, 0.025],
      }, // crests
      {
        sb: ScoreboardGraphic9,
        hasAddedTime: true,
        hasCrests: true,
        scaleMonitor: 1.5,
        topRightMonitorOffset: [-0.15, 0],
        bottomLeftMonitorOffset: [0, -0.1],
        bottomRightMonitorOffset: [-0.15, -0.1],
        middleMonitorOffset: [-0.25, 0.025],
      }, //
      {
        sb: ScoreboardGraphic1,
        hasAddedTime: false,
        hasCrests: false,
        scaleMonitor: 1.5,
        topRightMonitorOffset: [-0.12, 0],
        bottomLeftMonitorOffset: [0, -0.1],
        bottomRightMonitorOffset: [-0.12, -0.1],
        middleMonitorOffset: [-0.25, 0.025],
      },
      {
        sb: ScoreboardGraphic7,
        hasAddedTime: true,
        hasCrests: true,
        scaleMonitor: 1.5,
        topRightMonitorOffset: [-0.12, 0],
        bottomLeftMonitorOffset: [0, -0.1],
        bottomRightMonitorOffset: [-0.12, -0.1],
        middleMonitorOffset: [-0.25, 0.025],
      }, //
      {
        sb: ScoreboardGraphic6,
        hasAddedTime: false,
        hasCrests: true,
        scaleMonitor: 1.5,
        topRightMonitorOffset: [-0.12, 0],
        bottomLeftMonitorOffset: [0, -0.1],
        bottomRightMonitorOffset: [-0.12, -0.1],
        middleMonitorOffset: [-0.25, 0.025],
      }, // cork city
      {
        sb: ScoreboardGraphic5,
        hasAddedTime: false,
        hasCrests: false,
        scaleMonitor: 1.2,
        topRightMonitorOffset: [-0.12, 0],
        bottomLeftMonitorOffset: [0, -0.1],
        bottomRightMonitorOffset: [-0.12, -0.1],
        middleMonitorOffset: [-0.25, 0.025],
      }, //sky
      {
        sb: ScoreboardGraphic3,
        hasAddedTime: true,
        hasCrests: false,
        scaleMonitor: 1.5,
        topRightMonitorOffset: [-0.12, 0],
        bottomLeftMonitorOffset: [0, -0.1],
        bottomRightMonitorOffset: [-0.12, -0.1],
        middleMonitorOffset: [-0.25, 0.025],
      }, // rte
      {
        sb: ScoreboardGraphic4,
        hasAddedTime: true,
        hasCrests: false,
        scaleMonitor: 1.5,
        topRightMonitorOffset: [-0.12, 0],
        bottomLeftMonitorOffset: [0, -0.1],
        bottomRightMonitorOffset: [-0.12, -0.1],
        middleMonitorOffset: [-0.25, 0.025],
      }, // crests
    ],
  },
  d: {
    playerA: "Player A",
    playerB: "Player B",
    halfLength: 0,
    sport: "d",

    scoreboards: [
      {
        sb: DartsScoreboardSets,
        hasAddedTime: false,
        hasCrests: false,
        hasSets: true,
        scaleMonitor: 1.3,
        topLeftMonitorOffset: [0, 0],
        topRightMonitorOffset: [-0.1, 0],
        bottomLeftMonitorOffset: [0, -0.08],
        bottomRightMonitorOffset: [-0.1, -0.08],
      },
      {
        sb: DartsScoreboard,
        hasAddedTime: false,
        scaleMonitor: 1.3,
        hasCrests: false,
        hasSets: false,
        bottomRightMonitorOffset: [-0.1, -0.08],
      },
      {
        sb: DartsScoreboard2,
        scaleMonitor: 1.3,
        hasAddedTime: false,
        hasCrests: true,
        hasSets: true,
        bottomRightMonitorOffset: [-0.1, -0.08],
      },
    ],
  },
  p: {
    playerA: "Player A",
    playerB: "Player B",
    halfLength: 0,
    sport: "p",
    scoreboards: [
      {
        sb: PoolScoreboard2,
        hasAddedTime: false,
        hasCrests: true,
        hasSets: false,
        width: 500,
        scaleMonitor: 1,
        topLeftMonitorOffset: [0, 0],
        topRightMonitorOffset: [-270, 0],
        bottomLeftMonitorOffset: [0, 0],
        bottomRightMonitorOffset: [-0.2, 0],
        middleMonitorOffset: [-0.26, 0.05],
      },
      {
        sb: PoolScoreboard1,
        hasAddedTime: false,
        hasCrests: false,
        hasSets: false,
        width: 500,
      },
      {
        sb: PoolScoreboard3,
        hasAddedTime: false,
        hasCrests: false,
        hasSets: false,
        width: 1080,
        scaleMonitor: 1.7,
        topLeftMonitorOffset: [0, 0],
        topRightMonitorOffset: [-270, 0],
        bottomLeftMonitorOffset: [0, 0],
        bottomRightMonitorOffset: [-0.2, 0],
        middleMonitorOffset: [-0.26, 0.05],
      },
    ],
  },
  t: {
    playerA: "Player A",
    playerB: "Player B",
    sport: "t",
    halfLength: 0,
    scoreboards: [
      {
        sb: TennisBlueScoreboard,
        hasAddedTime: true,
        hasCrests: false,
        bottomRightMonitorOffset: [0, -0.05],
        bottomLeftMonitorOffset: [0, -0.05],
      },
      { sb: TennisDGreenScoreboard, hasAddedTime: true, hasCrests: false },
      { sb: TennisBlueWhiteScoreboard, hasAddedTime: true, hasCrests: false },
    ],
  },
  pb: {
    playerA: "Player A",
    playerB: "Player B",
    sport: "t",
    halfLength: 0,
    scoreboards: [
      { sb: PickleballBlue, hasAddedTime: true, hasCrests: false },
      { sb: PickleballRed, hasAddedTime: true, hasCrests: true },
      { sb: PickleballGreen, hasAddedTime: true, hasCrests: true },
    ],
  },
  bb: {
    playerA: "Team A",
    playerB: "Team B",
    scaleMonitor: 2,
    sport: "bb",
    halfLength: 0,
    scoreboards: [
      {
        sb: BasketballScoreboard3,
        hasAddedTime: true,
        hasCrests: false,
        scaleMonitor: 2,
        topRightMonitorOffset: [-0.2, 0],
        bottomRightMonitorOffset: [-0.2, -0.1],
        bottomLeftMonitorOffset: [0, -0.1],
      },
      {
        sb: BasketballScoreboard2,
        hasAddedTime: true,
        hasCrests: false,
        scaleMonitor: 2,
        topRightMonitorOffset: [-0.3, 0],
        bottomRightMonitorOffset: [-0.3, 0],
      },
    ],
  },
};

export const buttons = [
  {
    name: "goal",
    label: "noun:goal",
    color: "success",
    scorePos: 0,
    dir: 1,
    row: "top",
    bottomLine: "",
  },
  {
    name: "point",
    label: "noun:point",
    color: "warning",
    scorePos: 1,
    dir: 1,
    row: "top",
    bottomLine: "",
  },
  {
    name: "goal",
    label: "noun:goal",
    color: "success",
    scorePos: 2,
    dir: 1,
    row: "top",
    bottomLine: "",
  },
  {
    name: "point",
    label: "noun:point",
    color: "warning",
    scorePos: 3,
    dir: 1,
    row: "top",
    bottomLine: "",
  },
  {
    name: "goal",
    label: "noun:goal",
    color: "success",
    scorePos: 0,
    dir: -1,
    row: "bottom",
    bottomLine: "",
  },
  {
    name: "point",
    label: "noun:point",
    color: "warning",
    scorePos: 1,
    dir: -1,
    row: "bottom",
    bottomLine: "",
  },
  {
    name: "goal",
    label: "noun:goal",
    color: "success",
    scorePos: 2,
    dir: -1,
    row: "bottom",
    bottomLine: "",
  },
  {
    name: "point",
    label: "noun:point",
    color: "warning",
    scorePos: 3,
    dir: -1,
    row: "bottom",
    bottomLine: "",
  },
  {
    name: "r",
    label: "noun:try",
    labelL: "noun:try",
    color: "success",
    scorePos: 0,
    dir: 5,
    dirL: 4,
    row: "top",
    bottomLine: "5",
  },
  {
    name: "r",
    label: "noun:penalty",
    labelL: "noun:penalty",
    color: "warning",
    scorePos: 1,
    dir: 3,
    dirL: 2,
    row: "top",
    bottomLine: "3",
  },
  {
    name: "r",
    label: "noun:try",
    labelL: "noun:try",
    color: "success",
    scorePos: 2,
    dir: 5,
    dirL: 4,
    row: "top",
    bottomLine: "5",
  },
  {
    name: "r",
    label: "noun:penalty",
    labelL: "noun:penalty",
    color: "warning",
    scorePos: 3,
    dir: 3,
    dirL: 2,
    row: "top",
    bottomLine: "3",
  },
  {
    name: "r",
    label: "noun:conversion",
    labelL: "noun:conversion",
    color: "info",
    scorePos: 0,
    dir: 2,
    dirL: 2,
    row: "bottom",
    bottomLine: "2",
  },
  {
    name: "r",
    label: "noun:penalty",
    labelL: "noun:drop-goal",
    color: "danger",
    scorePos: 1,
    dir: 1,
    dirL: 1,
    row: "bottom",
    bottomLine: "1",
  },
  {
    name: "r",
    label: "noun:conversion",
    labelL: "noun:conversion",
    color: "info",
    scorePos: 2,
    dir: 2,
    dirL: 2,
    row: "bottom",
    bottomLine: "2",
  },
  {
    name: "r",
    label: "noun:penalty",
    labelL: "noun:drop-goal",
    color: "danger",
    scorePos: 3,
    dir: 1,
    dirL: 1,
    row: "bottom",
    bottomLine: "1",
  },
];

export const cardsStyle = [
  {
    name: "black",
    color: "dark",
  },
  {
    name: "yellow",
    color: "warning",
  },
  {
    name: "red",
    color: "danger",
  },
];

export const productName = {
  prod_Iv4t7SPzQIg2NK: "Soccer",
  prod_Iv9lYRdDWaPOQ2: "Soccer",
  prod_JAActCbJGX3jDa: "Rugby",
  prod_JAvA3hnAZ41LxD: "Rugby",
  prod_Iv8exxds2qRVyM: "GAA",
  prod_JAAdky5cy9mzOe: "GAA",
  prod_JAAeeBRNQu1hKA: "Soccer, Rugby & GAA",
  prod_JAv5ZHoRf6uiA6: "Soccer, Rugby & GAA",
  prod_JvjzK695vwff9l: "Darts",
  prod_Jvk0HsyfsHIma2: "Darts",
  prod_LPv7scjCNQuHgF: "Tennis",
  prod_LPv8ezbhiNQrwo: "Tennis",
  prod_NxVQcGUpLYzge8: "Basic",
  prod_NxVQjQa9IuELUg: "Premium",
  prod_PUiZzKhAVsIIQz: "All Sports",
};

export const priceName = {
  price_1IJEk6HBLsXMmNgwggNk3lBG: "Monthly Subscription - Soccer",
  price_1IJJRsHBLsXMmNgw4OvEM20L: "Yearly Subscription - Soccer",
  price_1IXqHAHBLsXMmNgwtMi2ck1w: "Monthly Subscription - Rugby",
  price_1IYZKBHBLsXMmNgwA2Mx1k0c: "Yearly Subscription - Rugby",
  price_1IJIMiHBLsXMmNgwSOCcv7Ps: "Monthly Subscription - GAA",
  price_1IXqICHBLsXMmNgwqkOBHksK: "Yearly Subscription - GAA",
  price_1IXqJ0HBLsXMmNgwz4CW9Rv3: "Monthly Subscription - Soccer, Rugby & GAA",
  price_1IYZF6HBLsXMmNgwud1lNtNg: "Yearly Subscription - Soccer, Rugby & GAA",
  price_1JHsVSHBLsXMmNgwByN7MshG: "Monthly Subscription - Darts",
  price_1JHsWQHBLsXMmNgwQtrCT42d: "Yearly Subscription - Darts",
  price_1MDvv7HBLsXMmNgwZpN1jdF8: "Monthly Subscription - Tennis",
  price_1Kj5HwHBLsXMmNgwPExyBN5q: "Yearly Subscription - Tennis",
  price_1OY7UxHBLsXMmNgwBgri61aN: "Monthly Subscription - Premium",
  price_1OY7WVHBLsXMmNgw19a9tYWB: "Yearly Subscription - Premium",
  price_1Ofj8LHBLsXMmNgwlfLAoalw: "Monthly Subscription - All Sports",
  price_1Ofj8LHBLsXMmNgwYJVMW7jE: "Yearly Subscription - All Sports",
};

export const sportsDictionary = {
  f: "gaa",
  s: "soccer",
  r: "rugby",
  t: "tennis",
  p: "pool",
  d: "darts",
};

export const sportsDictionaryCapitalise = {
  f: "GAA",
  s: "Soccer",
  r: "Rugby",
  t: "Tennis/ Pickleball",
  p: "Pool",
  d: "Darts",
  bb: "Basketball",
};

export const priceToSportsDictionary = {
  price_1IJEk6HBLsXMmNgwggNk3lBG: "s",
  price_1IJJRsHBLsXMmNgw4OvEM20L: "s",
  price_1IXqHAHBLsXMmNgwtMi2ck1w: "r",
  price_1IYZKBHBLsXMmNgwA2Mx1k0c: "r",
  price_1IJIMiHBLsXMmNgwSOCcv7Ps: "f",
  price_1IXqICHBLsXMmNgwqkOBHksK: "f",
  price_1IXqJ0HBLsXMmNgwz4CW9Rv3: "s",
  price_1IYZF6HBLsXMmNgwud1lNtNg: "s",
  price_1JHsVSHBLsXMmNgwByN7MshG: "d",
  price_1JHsWQHBLsXMmNgwQtrCT42d: "d",
  price_1MDvv7HBLsXMmNgwZpN1jdF8: "t",
  price_1Kj5HwHBLsXMmNgwPExyBN5q: "t",
  price_1OY7UxHBLsXMmNgwBgri61aN: "p",
  price_1OY7WVHBLsXMmNgw19a9tYWB: "p",
  price_1Ofj8LHBLsXMmNgwlfLAoalw: "s",
  price_1Ofj8LHBLsXMmNgwYJVMW7jE: "s",
  price_1PBHwnHBLsXMmNgwENzDGaCW: "s",
  price_1PBIAPHBLsXMmNgwurzCDSUo: "s",
};

export const stripeProducts = {
  s: {
    price_month: "price_1IJEk6HBLsXMmNgwggNk3lBG",
    product_month: "prod_Iv4t7SPzQIg2NK",
    price_year: "price_1IJJRsHBLsXMmNgw4OvEM20L",
    product_year: "prod_Iv9lYRdDWaPOQ2",
  },
  r: {
    price_month: "price_1IXqHAHBLsXMmNgwtMi2ck1w",
    product_month: "prod_JAActCbJGX3jDa",
    price_year: "price_1IYZKBHBLsXMmNgwA2Mx1k0c",
    product_year: "prod_JAvA3hnAZ41LxD",
  },
  f: {
    price_month: "price_1IJIMiHBLsXMmNgwSOCcv7Ps",
    product_month: "prod_Iv8exxds2qRVyM",
    price_year: "price_1IXqICHBLsXMmNgwqkOBHksK",
    product_year: "prod_JAAdky5cy9mzOe",
  },
  fsr: {
    price_month: "price_1IXqJ0HBLsXMmNgwz4CW9Rv3",
    product_month: "prod_JAAeeBRNQu1hKA",
    price_year: "price_1IYZF6HBLsXMmNgwud1lNtNg",
    product_year: "prod_JAv5ZHoRf6uiA6",
  },
  d: {
    price_month: "price_1JHsVSHBLsXMmNgwByN7MshG",
    product_month: "prod_JvjzK695vwff9l",
    price_year: "price_1JHsWQHBLsXMmNgwQtrCT42d",
    product_year: "prod_Jvk0HsyfsHIma2",
  },
  // t: {
  //   price_month: "price_1Kj5GzHBLsXMmNgwnfRoKvga",
  //   product_month: "prod_LPv7scjCNQuHgF",
  //   price_year: "price_1Kj5HwHBLsXMmNgwPExyBN5q",
  //   product_year: "prod_LPv8ezbhiNQrwo",
  // },
  t: {
    price_month: "price_1MDvv7HBLsXMmNgwZpN1jdF8",
    product_month: "prod_LPv7scjCNQuHgF",
    price_year: "price_1Kj5HwHBLsXMmNgwPExyBN5q",
    product_year: "prod_LPv8ezbhiNQrwo",
  },
  p: {
    price_month: "price_1OY7UxHBLsXMmNgwBgri61aN",
    product_month: "prod_MT66A5V1VKt6bL",
    price_year: "price_1OY7WVHBLsXMmNgw19a9tYWB",
    product_year: "prod_MT640xeQ47Cjno",
  },
  all: {
    price_month: "price_1PBHwnHBLsXMmNgwENzDGaCW",
    product_month: "prod_PUiZzKhAVsIIQz",
    price_year: "price_1PBIAPHBLsXMmNgwurzCDSUo",
    product_year: "prod_PUiZzKhAVsIIQz",
  },
};
