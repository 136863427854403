import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";
import { useScoreboardContext } from "../../contexts/ScoreboardContext";

function ColorPicker() {
  const { showColorPicker, mouse, setShowColorPicker, colors, setColors } =
    useScoreboardContext();
  const [sketchPicker, setSketchPicker] = useState();
  const [currentCol, setCurrentCol] = useState({ r: 192, g: 12, b: 12, a: 1 });

  const handleChange = (newColor) => {
    setCurrentCol(newColor.rgb);
    setColors((color) => {
      return {
        ...color,
        // color: newColor.rgb,
        [colors.currentColor]: newColor.hex,
      };
    });
  };

  const styles = reactCSS({
    default: {
      cover: {
        position: "fixed",
        top: 0,
        right: "0px",
        bottom: "0px",
        left: 0,
        zIndex: 1999,
      },
      posSketchPicker: {
        position: "fixed",
        top: mouse[1] + 90,
        left: mouse[0] - 15,
        zIndex: 2000,
      },
    },
  });

  useEffect(() => {
    if (showColorPicker) {
      setSketchPicker(
        <div>
          <div style={styles.posSketchPicker}>
            <SketchPicker color={currentCol} onChange={handleChange} />
          </div>
          <div
            style={styles.cover}
            onClick={() => setShowColorPicker(false)}
          ></div>
        </div>
      );
    } else {
      setSketchPicker();
    }
  }, [showColorPicker, currentCol]);

  return <div>{sketchPicker}</div>;
}

export default ColorPicker;
