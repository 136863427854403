import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const SlideRight = ({ show, children, start, finish }) => {
  const [xPosition, setXPosition] = useState(start);

  useEffect(() => {
    if (show) {
      setXPosition(start); // Slide back to -80px if addedTime is 0
    } else {
      setXPosition(finish); // Slide to 630px when addedTime is non-zero
    }
  }, [show]);

  return (
    <g
      transform={`translate(${xPosition}, 0)`}
      style={{ transition: "transform 0.5s ease-in-out" }}
    >
      {children}
    </g>
  );
};

SlideRight.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.any,
  start: PropTypes.number,
  finish: PropTypes.number,
};

export default SlideRight;
