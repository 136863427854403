import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  formatScore,
  useScoreboardContext,
} from "../../../contexts/ScoreboardContext";
import { setSize } from "./ScoreboardGraphic3";

import SlideRight from "../Sliders/SlideRight";
import MapCards from "../MapCards";

import "../../assets/fonts/font.css";

function ScoreboardGraphic6() {
  const [namehome, setTeamHome] = useState();
  const [nameaway, setTeamAway] = useState();

  const {
    currentScore,
    colors,
    setColors,
    teamA,
    teamB,
    time,
    timeIsRed,
    handleChangeColor,
    addedTime,
    currentSport,
    crest,
  } = useScoreboardContext();

  useEffect(() => {
    if (teamA) setTeamHome(setSize(teamA, 4));

    if (teamB) setTeamAway(setSize(teamB, 4));
  }, [teamA, teamB]);

  useEffect(() => {
    setColors((color) => {
      return { ...color, c1: "#000", c3: "#fff", c2: "#000", c4: "#fff" };
    });
  }, []);

  return (
    <div id="container_X" style={{ position: "relative" }}>
      <div style={{ zIndex: "20 !important" }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 550 100">
          <defs>
            <style>
              {`
            .cls-1 {
              fill: none;
              stroke: #fff;
              stroke-miterlimit: 10;
              stroke-width: 2px;
            }
            
            .cls-2 {
              font-size: 28px;
              font-family: BebasNeue-Regular, Bebas Neue;
            }
            .cls-3{
              fill: #fff
            }
            `}
            </style>
          </defs>
          <title>scorebard-eirk</title>
          <AddedTimeComponent addedTime={addedTime} />
          <g id="Layer_2" data-name="Layer 2">
            <rect
              x="1"
              y="1"
              width="137"
              height="38"
              fill={colors.c1}
              onClick={(e) => handleChangeColor("c1", e)}
            />
            <rect x="140" y="1" width="76" height="38" />
            <rect
              x="218"
              y="1"
              width="137"
              height="38"
              fill={colors.c2}
              onClick={(e) => handleChangeColor("c2", e)}
            />
            <rect
              x="357"
              y="1"
              width="105"
              height="38"
              fill={timeIsRed ? "#f50505" : "#000"}
            />
            <path
              className="cls-1"
              d="M70,80v38M286,80v38M425,80v38M208,80v38M532,80v38m1-38H69m0,39H533"
              transform="translate(-69 -79)"
            />
          </g>
          <g id="text">
            <text className="cls-2 cls-3" transform="translate(384.6 30)">
              {time}
            </text>
            <text
              className="cls-2 cls-3"
              textAnchor="middle"
              transform="translate(156 30)"
            >
              {formatScore(currentScore, currentSport, 0)}
            </text>
            <text className="cls-2 cls-3" transform="translate(173.9 30)">
              -
            </text>
            <text
              className="cls-2 cls-3"
              textAnchor="middle"
              transform="translate(196 30)"
            >
              {formatScore(currentScore, currentSport, 2)}
            </text>

            <text
              className="cls-2"
              fill={colors.c3}
              transform="translate(90 30)"
              textAnchor="middle"
              onClick={(e) => handleChangeColor("c3", e)}
            >
              {namehome}
            </text>
            <text
              className="cls-2"
              fill={colors.c4}
              transform="translate(260 30)"
              textAnchor="middle"
              onClick={(e) => handleChangeColor("c4", e)}
            >
              {nameaway}
            </text>
          </g>
          {crest[0] !== "" ? (
            <image
              href={crest[0]} // URL for the image (modern usage)
              x="2"
              y="2"
              width="60"
              height="37"
              preserveAspectRatio="xMidYMid slice"
            />
          ) : (
            ""
          )}
          {crest[1] !== "" ? (
            <image
              href={crest[1]} // URL for the image (modern usage)
              x="293"
              y="2"
              width="60"
              height="37"
              preserveAspectRatio="xMidYMid slice"
            />
          ) : (
            ""
          )}

          <MapCards x={79} y={42} team={0} gap={[30, 8]} />
          <MapCards x={218} y={42} team={1} gap={[30, 8]} />
        </svg>
      </div>

      <div
        style={{
          zoom: 1.8,
          MozTransform: "scale(1.8)",
          width: 500,
          height: 100,
          position: "absolute",
          top: 31,
          left: 154,
        }}
      ></div>
    </div>
  );
}

const AddedTimeComponent = ({ addedTime }) => {
  return (
    <SlideRight start={462 - 80} finish={462} show={Number(addedTime) === 0}>
      <rect width="80" height="41" x="0" y="0" fill="#fff" />
      <text
        x="37" // Adjusted based on the rect's x to keep it centered
        y="25"
        dominantBaseline="middle"
        textAnchor="middle"
        fill="#af2a2d"
        fontSize="30"
        fontFamily="'Exo 2', sans-serif"
      >
        +{addedTime}
      </text>
    </SlideRight>
  );
};

AddedTimeComponent.propTypes = {
  addedTime: PropTypes.any,
};

export default ScoreboardGraphic6;
