/* eslint-disable no-unused-vars */
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import PropTypes from "prop-types";

export default function UpDownDisplay({
  displayValue,
  handleUp,
  handleDown,
  buttonBackground,
}) {
  return (
    <Row className="d-flex justify-content-center align-items-center">
      <Col className="d-flex justify-content-center align-items-center">
        <Row>
          <Form.Control
            type="name"
            disabled
            value={displayValue}
            style={{ padding: 0, margin: 0, textAlign: "center" }}
            // onBlur={(e) => updateFB("teamA", e.target.value)}
          />
        </Row>
      </Col>
      <Col>
        <Row>
          <Col className="align-items-center mt-2 ml-1">
            <Row>
              <Button
                variant={buttonBackground}
                className="px-0 mx-0 h-100"
                onClick={() => handleUp()}
              >
                <FaArrowUp />
              </Button>
            </Row>
            <Row>
              <Button
                variant={buttonBackground}
                className="mt-1 px-0 mx-0 h-100"
                onClick={() => handleDown()}
              >
                <FaArrowDown fontSize={16} />
              </Button>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

UpDownDisplay.propTypes = {
  displayValue: PropTypes.number,
  buttonBackground: PropTypes.string,
  handleUp: PropTypes.func,
  handleDown: PropTypes.func,
};
