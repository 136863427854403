import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useScoreboardContext } from "../../../../contexts/ScoreboardContext";
import { useTennisContext } from "../TennisContext";

function PickleballBlue() {
  const { teamA, teamB } = useScoreboardContext();

  const { currentScore, isFirstServer, notice, showNotice, gameNumber } =
    useTennisContext();

  const widthGrey = useMemo(() => {
    let lenGrey = currentScore?.length || 1;
    let lineLength = 53 + (lenGrey - 1) * 48.1;

    return (
      `M222.49 22.08 h` +
      lineLength +
      ` c1.36 0 2.46 1.77 2.46 3.94v70.92c0 1.12-.57 2.03-1.26 2.03
            H200.00 c-1.36 0-2.46-1.77-2.46-3.94v-69c0-2.18 1.1-3.94 2.46-3.94Z`
    );
  }, [gameNumber, currentScore]);

  const Rectangles = ({ number }) => {
    return (
      <>
        <rect
          className="cls-4"
          x={230.6 + 48.1 * number}
          y={25.65}
          width={43.96}
          height={33.04}
          rx={1.28}
          ry={1.28}
        />
        <rect
          className="cls-4"
          x={230.6 + 48.1 * number}
          y={62.47}
          width={43.96}
          height={33.04}
          rx={1.28}
          ry={1.28}
        />
        <text
          className="cls-7"
          textAnchor="middle"
          transform={"translate(" + (252 + 48.1 * number) + ",51.85)"}
        >
          <tspan x={0} y={0}>
            {currentScore?.[number]?.player1}
          </tspan>
        </text>
        <text
          className="cls-7"
          textAnchor="middle"
          transform={"translate(" + (252 + 48.1 * number) + ",89.36)"}
        >
          <tspan x={0} y={0}>
            {currentScore?.[number]?.player2}
          </tspan>
        </text>
      </>
    );
  };

  Rectangles.propTypes = {
    number: PropTypes.number.isRequired,
  };

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 469.09 98.96"
    >
      <defs>
        <style>
          {
            ".cls-2{letter-spacing:-.07em}.cls-3{fill:#e6e7e7}.cls-4,.cls-5{fill:#fff}.cls-6,.cls-7{fill:#0162a2}.cls-8{letter-spacing:0}.cls-5,.cls-7{isolation:isolate;font-family:Roboto-Black,Roboto;font-size:25.95px}"
          }
        </style>
      </defs>
      <path className="cls-3" d={widthGrey} />
      <path
        className="cls-3"
        d="M2.27 22.08h226.05c1.26 0 2.27 1.77 2.27 3.94v70.92c0 1.12-.52 2.03-1.17 2.03H2.27C1.01 98.97 0 97.2 0 95.03v-69c0-2.18 1.02-3.94 2.27-3.94Z"
      />
      {showNotice ? (
        <>
          <rect
            className="cls-3"
            width={146.97}
            height={29.29}
            rx={2.06}
            ry={2.06}
          />
          <text
            transform="translate(32.88 18.9)"
            style={{
              isolation: "isolate",
              fill: "#010101",
              fontFamily: "Roboto-Medium,Roboto",
              fontSize: "15.95px",
            }}
          >
            <tspan x={0} y={0}>
              {notice}
            </tspan>
          </text>
        </>
      ) : null}

      <rect
        className="cls-6"
        x={5.36}
        y={25.65}
        width={221.26}
        height={33.04}
        rx={1.28}
        ry={1.28}
      />
      <rect
        className="cls-6"
        x={5.36}
        y={62.47}
        width={221.26}
        height={33.04}
        rx={1.29}
        ry={1.29}
      />

      {currentScore?.length > 0
        ? currentScore.map((number, key) => {
            return <Rectangles key={key} number={key} />;
          })
        : null}

      <text className="cls-5" transform="translate(11.74 51.85)">
        <tspan x={0} y={0}>
          {teamA}
        </tspan>
      </text>
      <text className="cls-5" transform="translate(13.14 89.36)">
        <tspan x={0} y={0}>
          {teamB}
        </tspan>
      </text>
      {isFirstServer ? (
        <circle className="cls-4" cx={214.78} cy={42.17} r={3.27} />
      ) : (
        <circle className="cls-4" cx={214.78} cy={79.28} r={3.27} />
      )}
    </svg>
  );
}

export default PickleballBlue;
