import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useScoreboardContext } from "../../../../contexts/ScoreboardContext";
import { useTennisContext } from "../TennisContext";
import useTranslations from "../../../../contexts/useTranslations";

export default function PickleballRed() {
  const t = useTranslations();
  const { teamA, teamB, crest } = useScoreboardContext();

  const { currentScore, isFirstServer, notice, showNotice } =
    useTennisContext();

  const Rectangle = ({ number }) => {
    let scoreVal = useMemo(
      () => currentScore.length - number - 1,
      [currentScore, number]
    );

    Rectangle.propTypes = {
      number: PropTypes.string.isRequired,
    };

    return (
      <>
        {number > 0 && number < currentScore.length ? (
          <>
            {/* {"M" + (287.45 + 40.9 * (number - 1)) + " 0h41.1v68.8h-41.1z"} */}
            <rect
              className="cls-8"
              x={287.45 + 40.9 * (number - 1)}
              y="22.63"
              width="41.1"
              height="68.8"
            />
            <text
              className="cls-4"
              textAnchor="middle"
              transform={`translate(${307.14 + 40.9 * (number - 1)} 47.31)`}
            >
              <tspan x="0" y="0">
                {currentScore?.[scoreVal]?.player1}
              </tspan>
            </text>

            <text
              className="cls-4"
              textAnchor="middle"
              transform={`translate(${307.14 + 40.9 * (number - 1)} 81.76)`}
            >
              <tspan x="0" y="0">
                {currentScore?.[scoreVal]?.player2}
              </tspan>
            </text>
          </>
        ) : null}
      </>
    );
  };

  return (
    <div>
      {crest[0] === "" ? (
        ""
      ) : (
        <img
          alt={`${t("noun:crest")} 1`}
          style={{
            position: "absolute",
            top: 480.5,
            left: 90,
            display: "box",
            width: 45,
            height: 25,
            zAxis: 100,
            objectFit: "cover",
          }}
          src={crest[0]}
        />
      )}
      {crest[1] === "" ? (
        ""
      ) : (
        <img
          alt={`${t("noun:crest")} 2`}
          style={{
            position: "absolute",
            top: 534,
            left: 90,
            display: "box",
            width: 45,
            height: 25,
            zAxis: 100,
            objectFit: "cover",
          }}
          src={crest[1]}
        />
      )}
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 451.85 91.44"
      >
        <defs>
          <style>
            {
              ".cls-2{font-size:18.15px}.cls-2,.cls-3,.cls-4{font-family:Roboto-Bold,Roboto}.cls-2,.cls-4{fill:#fff}.cls-7{fill:#dc1e31}.cls-3,.cls-8{fill:#231f20}.cls-3,.cls-4{font-size:19.23px}"
            }
          </style>
        </defs>
        <path className="cls-8" d="M0 22.63h246.35v68.8H0z" />
        <path
          style={{
            fill: "#ed1c24",
          }}
          d="M246.35 22.63h41.1v68.8h-41.1z"
        />
        <path
          style={{
            fill: "none",
            opacity: 0.08,
            stroke: "#4f4f4f",
            strokeMiterlimit: 10,
          }}
          d="M0 57.04h287.5"
        />

        {currentScore?.length > 0
          ? currentScore.map((number, key) => {
              return <Rectangle key={key} number={key} />;
            })
          : null}

        <text className="cls-2" transform="translate(54 46.41)">
          <tspan x={0} y={0}>
            {teamA}
          </tspan>
        </text>
        <text className="cls-2" transform="translate(54 81)">
          <tspan x={0} y={0}>
            {teamB}
          </tspan>
        </text>
        {isFirstServer ? (
          <path
            id="tri1"
            className="cls-7"
            d="m232.62 47.31-7.4-7.4 7.4-7.41v14.81z"
          />
        ) : (
          <path
            id="tri2"
            className="cls-7"
            d="m232.62 81.57-7.4-7.4 7.4-7.41v14.81z"
          />
        )}
        <text
          textAnchor="middle"
          className="cls-3"
          transform="translate(266 47.31)"
        >
          <tspan x={0} y={0}>
            {currentScore?.[currentScore.length - 1]?.player1}
          </tspan>
        </text>
        <text
          textAnchor="middle"
          className="cls-3"
          transform="translate(266 81.18)"
        >
          <tspan x={0} y={0}>
            {currentScore?.[currentScore.length - 1]?.player2}
          </tspan>
        </text>

        <g id="flag2"></g>
        <g id="flag1"></g>

        {showNotice ? (
          <>
            <path className="cls-8" d="M0 1h189.08v22.78H0z" />
            <text
              className="cls-2"
              textAnchor="middle"
              transform="translate(93 19.53)"
            >
              <tspan x={0} y={0}>
                {notice}
              </tspan>
            </text>
          </>
        ) : null}
      </svg>
    </div>
  );
}
