import React, { useEffect, useState } from "react";
import { findMatchingLocale, getMessagesWithLocale } from "../libs/i18n";
import { IntlProvider as I18NProvider } from "react-intl";
import PropTypes from "prop-types";

const IntlProvider = ({ children }) => {
  const [locale, setLocale] = useState("");
  const [messages, setMessages] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const resolvedLocale = await findMatchingLocale();
      setLocale(resolvedLocale);
      getMessagesWithLocale(resolvedLocale).then((msgs) => setMessages(msgs));
    };

    fetchData();
  }, []);

  return (
    <I18NProvider
      locale={locale || "en"}
      messages={messages}
      textComponent={React.Fragment}
    >
      {children}
    </I18NProvider>
  );
};

IntlProvider.propTypes = {
  children: PropTypes.object,
};

export default IntlProvider;
