/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

const MonitorContext = React.createContext();

export function useMonitor() {
  return useContext(MonitorContext);
}

// const lang = navigator?.language?.split("-")?.[0];

export function MonitorProvider({ monitorInfo, children }) {
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [halfLength, setHalfLength] = useState(0);
  const [time, setTime] = useState("00:00");
  const [timeForward, setTimeForward] = useState(false);
  const [started, setStarted] = useState(false);
  const [firstHalfCounter, setFirstHalfCounter] = useState(0);
  const [secondHalfCounter, setSecondHalfCounter] = useState(0);
  const [timeIsRed, setTimeIsRed] = useState(0);
  const [startTime, setStartTime] = useState();

  useEffect(() => {
    if (monitorInfo) {
      console.log({ monitorInfo });
      setStartTime(monitorInfo.startTime);
      console.log({ dataStart: monitorInfo?.startTime, startTime });
      if (
        monitorInfo?.started === true &&
        monitorInfo?.startTime !== startTime
      ) {
        if (
          monitorInfo?.timeStop.seconds !== undefined &&
          monitorInfo?.timeStop.minutes !== undefined
        ) {
          const currentTime = new Date();
          const elapsedTime = Math.abs(
            Math.floor((currentTime - new Date(monitorInfo.startTime)) / 1000)
          ); // in seconds

          // Calculate minutes and remaining seconds
          const newMinutes = Math.floor(elapsedTime / 60);
          const newSeconds = elapsedTime % 60;

          setSeconds(newSeconds);
          setMinutes(newMinutes);
          if (monitorInfo?.started !== undefined) {
            setStarted(monitorInfo.started);
          }
        }
      }
      // if clock is stopped
      else if (
        monitorInfo?.started === false &&
        monitorInfo?.startTime !== startTime
      ) {
        if (monitorInfo?.started !== undefined) {
          setStarted(monitorInfo.started);
        }

        setSeconds(monitorInfo?.timeStop?.seconds);
        setMinutes(monitorInfo?.timeStop?.minutes);
      }
    }
  }, [monitorInfo, startTime]);

  const value = {
    time,
    seconds,
    minutes,
    halfLength,
    setHalfLength,
    setSeconds,
    setMinutes,
    setTime,
    setTimeIsRed,
    timeForward,
    setTimeForward,
    started,
    setStarted,
    firstHalfCounter,
    setFirstHalfCounter,
    secondHalfCounter,
    setSecondHalfCounter,
    timeIsRed,
  };

  return (
    <MonitorContext.Provider value={value}>{children}</MonitorContext.Provider>
  );
}

MonitorProvider.propTypes = {
  children: PropTypes.object,
  setUID: PropTypes.func,
};
