import React, { useEffect } from "react";
import { useScoreboardContext } from "../../../../contexts/ScoreboardContext";
import { useTennisContext } from "../TennisContext";

function TennisDGreenScoreboard() {
  const { teamA, teamB, colors, setColors, handleChangeColor } =
    useScoreboardContext();

  const { sets, legs, currentScore, isFirstServer, notice, showNotice } =
    useTennisContext();

  useEffect(() => {
    setColors((prev) => ({
      ...prev,
      c1: "#0c7880",
      c2: "#e7ec9d",
      c3: "#004553",
      c4: "#fff",
      c5: "#fff",
      c6: "#fff",
      c7: "#003b3e",
    }));
  }, []);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1210.38 264.83">
      <defs>
        <style>
          {
            ".cls-6{fill:#0c7880}.cls-5,.cls-8,.cls-9{font-size:73.63px;font-family:Roboto-Medium,Roboto}"
          }
        </style>
      </defs>
      <g id="Layer_1" data-name="Layer 1">
        <path
          style={{
            fill: colors.c3,
          }}
          d="M0 52.77h1210.38v212.06H0z"
          id="PlayersBG"
          onClick={(e) => handleChangeColor("c3", e, [0, 100])}
        />
        <text
          className="cls-8"
          fill={colors.c4}
          onClick={(e) => handleChangeColor("c4", e, [0, 100])}
          transform="translate(16.8 139.24)"
        >
          <tspan x={0} y={0}>
            {teamA}
          </tspan>
        </text>
        <path
          id="Games"
          d="M937.94 52.77h111.92v212.06H937.94z"
          fill={colors.c1}
          onClick={(e) => handleChangeColor("c1", e, [0, 0])}
        />
        <text
          className="cls-9"
          transform="translate(971.51 138.11)"
          fill={colors.c6}
          onClick={(e) => handleChangeColor("c6", e, [0, 100])}
        >
          <tspan x={0} y={0}>
            {legs[0]}
          </tspan>
        </text>
        <text
          className="cls-9"
          transform="translate(970.04 239.06)"
          fill={colors.c6}
          onClick={(e) => handleChangeColor("c6", e, [0, 100])}
        >
          <tspan x={0} y={0}>
            {legs[1]}
          </tspan>
        </text>
        <path
          id="PointsBG"
          d="M1049.86 52.77h160.52v212.06h-160.52z"
          fill={colors.c7}
          onClick={(e) => handleChangeColor("c7", e, [0, 0])}
        />
        <text
          className="cls-5"
          textAnchor="middle"
          transform="translate(1130 138.35)"
          fill={colors.c2}
          onClick={(e) => handleChangeColor("c2", e, [0, 0])}
        >
          <tspan x={0} y={0}>
            {typeof currentScore?.[0] === "object" ? 0 : currentScore?.[0]}
          </tspan>
        </text>
        <text
          className="cls-5"
          textAnchor="middle"
          transform="translate(1130 239.96)"
          fill={colors.c2}
          onClick={(e) => handleChangeColor("c2", e, [0, 0])}
        >
          <tspan x={0} y={0}>
            {typeof currentScore?.[1] === "object" ? 0 : currentScore?.[1]}
          </tspan>
        </text>
        <text
          className="cls-8"
          fill={colors.c4}
          onClick={(e) => handleChangeColor("c4", e, [0, 100])}
          transform="translate(17.62 240.86)"
        >
          <tspan x={0} y={0}>
            {teamB}
          </tspan>
        </text>
        <text
          className="cls-8"
          transform="translate(834.12 137.45)"
          fill={colors.c5}
          onClick={(e) => handleChangeColor("c5", e, [0, 100])}
        >
          <tspan x={0} y={0}>
            {sets[0]}
          </tspan>
        </text>
        <text
          transform="translate(835.85 239.06)"
          className="cls-8"
          fill={colors.c5}
          onClick={(e) => handleChangeColor("c5", e, [0, 100])}
        >
          <tspan x={0} y={0}>
            {sets[1]}
          </tspan>
        </text>
        {isFirstServer ? (
          <ellipse
            cx={795.03}
            cy={113.49}
            rx={14.39}
            ry={15.13}
            id="circle-p1"
            fill={colors.c2}
            onClick={(e) => handleChangeColor("c2", e, [0, 0])}
          />
        ) : (
          <ellipse
            fill={colors.c2}
            onClick={(e) => handleChangeColor("c2", e, [0, 0])}
            cx={795.03}
            cy={216.57}
            rx={14.39}
            ry={15.13}
            id="circle-p2"
          />
        )}
        <path
          style={{
            fill: "none",
            opacity: 0.04,
            stroke: "#fff",
            strokeMiterlimit: 10,
          }}
          d="M1.39 160.28h1208.99"
        />
      </g>
      {showNotice ? (
        <g id="Note">
          <path
            id="NoticeBG"
            fill={colors.c1}
            onClick={(e) => handleChangeColor("c1", e, [0, 0])}
            d="M0 0h368.62v52.77H0z"
          />
          <text
            transform="translate(76.97 42.6)"
            style={{
              fontSize: "44.18px",
              fontFamily: "Roboto-Medium,Roboto",
            }}
            fill={colors.c6}
            onClick={(e) => handleChangeColor("c6", e, [0, 100])}
          >
            <tspan x={0} y={0}>
              {notice}
            </tspan>
          </text>
        </g>
      ) : null}
    </svg>
  );
}

export default TennisDGreenScoreboard;
