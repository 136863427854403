/* eslint-disable no-unused-vars */
import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import FullPageLoader from "../components/PageLoading";
import NavbarTop from "../components/Sections/NavbarTop";
import ScrollToTop from "../functions/ScrollToTop";
import NotFoundPage from "./404Error";
import OrderConfirmation from "./Confirmation";
import { useAuth } from "../contexts/AuthContext";
import RedirectToExternalURL from "./RedirectToExternalUrl";
import Share from "./Share";
import Monitor from "./Monitor";

const Dashboard = lazy(() => import("./Dashboard"));
const ScoreboardApp = lazy(() => import("../scoreboard-app/ScoreboardApp"));
const Options = lazy(() => import("./Options"));
const Signup = lazy(() => import("./Signup"));
const Login = lazy(() => import("./Login"));
const ForgotPassword = lazy(() => import("./ForgotPassword"));
const Pricing = lazy(() => import("./Pricing"));
const Canceled = lazy(() => import("./Canceled"));
const TermsAndConditions = lazy(() => import("./TermsAndConditions"));
const PrivacyPolicy = lazy(() => import("./PrivacyPolicy"));
const FAQ = lazy(() => import("./FAQ"));
const ContactUs = lazy(() => import("./ContactUs"));
const Demo = lazy(() => import("./Demo"));
const Instructions = lazy(() =>
  import("../components/Instructions/Instructions")
);

const isExcludedPath = () => {
  const excludedPaths = ["/testing.html", "/testing1.html"];
  return excludedPaths.includes(window.location.pathname);
};

const publicRoutes = [
  { path: "/", component: Pricing, exact: true },
  { path: "/scoreboard", component: Login, exact: true },
  { path: "/faq", component: FAQ, exact: true },
  {
    path: "/blog",
    component: () => (
      <RedirectToExternalURL url="https://obscoreboard.com/blog" />
    ),
    exact: true,
  },
  { path: "/features", component: Demo, exact: true },
  { path: "/pricing", component: Pricing, exact: true },
  { path: "/instructions", component: Instructions, exact: true },
  { path: "/privacy-policy", component: PrivacyPolicy, exact: true },
  { path: "/terms-and-conditions", component: TermsAndConditions, exact: true },
  { path: "/contact-us", component: ContactUs, exact: true },
  { path: "/signup", component: Signup, exact: false },
  { path: "/signup?subscription=monthly", component: Signup, exact: false },
  { path: "/signup?subscription=yearly", component: Signup, exact: false },
  { path: "/login", component: Login, exact: true },
  { path: "/forgot-password", component: ForgotPassword, exact: true },
  { path: "/share", component: Share, exact: true },
  { path: "/monitor", component: Monitor, exact: true },
  { path: "/*", component: NotFoundPage, exact: false },
];

const loggedInRoutes = [
  { path: "/", component: Pricing, exact: true },
  { path: "/dashboard/pricing", component: Pricing, exact: true },
  { path: "/dashboard/", component: Pricing, exact: true },
  { path: "/instructions/", component: Instructions, exact: true },
  { path: "/canceled/*", component: Canceled, exact: true },
  {
    path: "/settings/",
    component: () => <Options isBilling={false} />,
    exact: true,
  },
  {
    path: "/billing/",
    component: () => <Options isBilling={true} />,
    exact: true,
  },
  { path: "/faq", component: FAQ, exact: true },
  {
    path: "/blog",
    component: <RedirectToExternalURL url="https://obscoreboard.com/blog" />,
    exact: true,
  },
  { path: "/features", component: Demo, exact: true },
  { path: "/pricing", component: Pricing, exact: true },
  { path: "/signup?subscription=monthly", component: Signup, exact: false },
  { path: "/signup?subscription=yearly", component: Signup, exact: false },
  { path: "/instructions", component: Instructions, exact: true },
  { path: "/privacy-policy", component: PrivacyPolicy, exact: true },
  { path: "/terms-and-conditions", component: TermsAndConditions, exact: true },
  { path: "/contact-us", component: ContactUs, exact: true },
  { path: "/forgot-password", component: ForgotPassword, exact: true },
  {
    path: "/*",
    component: () => <Redirect to="/" />,
    exact: false,
  },
];

const subscribedRoutes = [
  { path: "/", component: Dashboard, exact: true },
  { path: "/dashboard", component: Dashboard, exact: true },
  { path: "/dashboard/pricing", component: Pricing, exact: true },
  { path: "/canceled/", component: Canceled, exact: true },
  {
    path: "/order-confirmation/:sessionId",
    component: OrderConfirmation,
    exact: true,
  },
  { path: "/faq", component: FAQ, exact: true },
  {
    path: "/blog",
    component: <RedirectToExternalURL url="https://obscoreboard.com/blog" />,
    exact: true,
  },
  { path: "/features", component: Demo, exact: true },
  { path: "/instructions", component: Instructions, exact: true },
  { path: "/privacy-policy", component: PrivacyPolicy, exact: true },
  { path: "/terms-and-conditions", component: TermsAndConditions, exact: true },
  { path: "/contact-us", component: ContactUs, exact: true },
  { path: "/forgot-password", component: ForgotPassword, exact: true },
  { path: "/monitor", component: Monitor, exact: true },
  {
    path: "/settings/",
    component: () => <Options isBilling={false} />,
    exact: true,
  },
  {
    path: "/billing/",
    component: () => <Options isBilling={true} />,
    exact: true,
  },
  {
    path: "/scoreboard",
    component: ScoreboardApp,
    exact: true,
  },
  {
    path: "/controller",
    component: ScoreboardApp,
    exact: true,
  },
  { path: "/instructions", component: Instructions, exact: true },
  {
    path: "/buttons",
    component: ScoreboardApp,
    exact: true,
  },
  { path: "/*", component: () => <Redirect to="/" />, exact: false },
];

function Routes() {
  let routes;

  const { currentUser, subscription, loading } = useAuth();

  if (loading)
    return (
      <div className="mt-6">
        <FullPageLoader />
      </div>
    );

  console.log({ currentUser, subscription });

  if (!currentUser) {
    routes = publicRoutes;
  } else if (currentUser && subscription) {
    routes = subscribedRoutes;
  } else if (currentUser) {
    routes = loggedInRoutes;
  } else {
    routes = publicRoutes;
  }

  // if (loadingSubscription && subscription !== undefined)
  //   return <FullPageLoader />;
  return (
    <Router>
      <NavbarTop />
      <ScrollToTop />

      <Suspense fallback={<FullPageLoader />}>
        <Switch>
          {routes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              component={route.component}
              exact={route.exact}
            />
          ))}
          {isExcludedPath() ? null : (
            // Add a catch-all route for other pages
            <Route component={NotFoundPage} />
          )}
        </Switch>
      </Suspense>
    </Router>
  );
}

Routes.propTypes = {};

export default Routes;
