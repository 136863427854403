import React from "react";
import ReactDOM from "react-dom";
import "./App.css";
import App from "./pages/App";
import "./styles/custom.scss";
import "./styles/theme.css";
import "./scoreboard-app/components/Initialise_Settings";
import "react-toastify/dist/ReactToastify.css";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
