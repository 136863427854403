import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import ToggleButtons from "../ToggleButtons";
import useTranslations from "../../contexts/useTranslations";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";

function PickleballModalSection({
  firstToPoints,
  setFirstToPoints,
  totalNumberOfGames,
  setTotalNumberOfGames,
}) {
  const t = useTranslations();
  const radios = [
    { name: "11", value: "11" },
    { name: "15", value: "15" },
    { name: "21", value: "21" },
  ];

  const radioGames = [
    { name: "1", value: "1" },
    { name: "3", value: "3" },
    { name: "5", value: "5" },
  ];

  useEffect(() => {
    logEvent(analytics, "settings_modal_opened", {
      sport: "pickleball", //
    });
  }, []);

  return (
    <Row>
      <Col className="d-flex align-items-middle  flex-column">
        <small>{t("noun:first-to-points")}</small>
        <ToggleButtons
          variant={firstToPoints?.toString()}
          radios={radios}
          setVariant={(val) => setFirstToPoints(parseInt(val))}
        />
      </Col>
      <Col className="d-flex align-items-middle flex-column">
        <small>{t("noun:best-of-games")}</small>
        <ToggleButtons
          variant={totalNumberOfGames?.toString()}
          setVariant={(val) => setTotalNumberOfGames(parseInt(val))}
          radios={radioGames}
        />
      </Col>
    </Row>
  );
}

PickleballModalSection.propTypes = {
  firstToPoints: PropTypes.number,
  setFirstToPoints: PropTypes.func,
  totalNumberOfGames: PropTypes.number,
  setTotalNumberOfGames: PropTypes.number,
};

export default PickleballModalSection;
