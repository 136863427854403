/* eslint-disable  */
import React, { useEffect, useState } from "react";
import "./share.css";
import ShareUI from "./ui";
import { useGetMonitorInfo } from "../../services/monitorService";
import { MonitorProvider } from "../../contexts/MonitorContext";
import Timer from "../../scoreboard-app/components/Timer";

const Share = () => {
  const { data: monitorInfo, error } = useGetMonitorInfo();

  console.log({ monitorInfo });

  return (
    <MonitorProvider monitorInfo={monitorInfo}>
      <ShareUI activeComponents={monitorInfo?.activeComponents} />
    </MonitorProvider>
  );
};

export default Share;
