/* eslint-disable */
import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useRef,
} from "react";
import PropTypes from "prop-types";
import { useScoreboardContext } from "../../contexts/ScoreboardContext";
import { db, fbf } from "../../firebase";
import { onValue, ref, set, update } from "firebase/database";
import { httpsCallable } from "firebase/functions";

let bcast;

try {
  bcast = new BroadcastChannel("app-data");
} catch (error) {
  bcast = null;
}

const PoolContext = createContext();

export function usePoolContext() {
  return useContext(PoolContext);
}

export const PoolProvider = ({ children }) => {
  const {
    currentScore,
    setCurrentScore,
    currentSport,
    currentScoreboard,
    setCurrentScoreboard,
    colors,
    teamA,
    teamB,
    setTeamA,
    setTeamB,
    currentUser,
    enableFB,
    setEnableFB,
    setCrest,
    crest,
  } = useScoreboardContext();

  const [isRemote, setIsRemote] = useState(false);
  const {
    //   currentSport,
    //   currentScoreboard,
    //   setCurrentScoreboard,
    //   teamA,
    //   setTeamA,
    //   teamB,
    //   setTeamB,
    generatedCode,
    setGeneratedCode,
    setCurrentSport,
    //   isPhoneController,
    //   currentUser,
  } = useScoreboardContext();

  let value;

  const [fromA, setFromA] = useState("");
  const [fromB, setFromB] = useState("");
  const [raceTo, setRaceTo] = useState("");
  const [handicap, setHandicap] = useState([0, 0]);
  const [startHandicap, setStartHandicap] = useState([0, 0]);
  const [bottomLabel, setBottomLabel] = useState("Last 16");
  const [isHandicapEnabled, setIsHandicapEnabled] = useState(false);
  const [tableNumber, setTableNumber] = useState(8);
  const [url, setUrl] = useState("https://pooladmin.se/running.php?id=2342");

  const [enableListener, setEnableListener] = useState(false);
  // const [undoHistory, setUndoHistory] = useState([
  //   {
  //     score: currentScore,
  //     legs: [0, 0],
  //     currentPlayer: currentPlayer,
  //     firstPlayer: firstPlayer,
  //   },
  // ]);
  // const [isRemote, setIsRemote] = useState(false);

  // const undo = () => {
  //   const undoHistoryLength = undoHistory.length;

  //   if (undoHistoryLength > 1) {
  //     const lastHistory = undoHistory[undoHistoryLength - 1];
  //     let _legs = lastHistory.legs;
  //     let _currentPlayer = lastHistory.currentPlayer;
  //     let _firstPlayer = lastHistory.firstPlayer;
  //     let _currentScore = lastHistory.score;
  //     let _sets = lastHistory.sets;

  //     setCurrentScore([..._currentScore]);
  //     setLegs(_legs);
  //     setSets(_sets);
  //     setCurrentPlayer(_currentPlayer);
  //     setFirstPlayer(_firstPlayer);

  //     setUndoHistory((hist) => {
  //       let temp = [...hist];
  //       temp.pop();
  //       return temp;
  //     });
  //   } else {
  //     setCurrentPlayer(1);
  //     setLegs([0, 0]);
  //     setSets([0, 0]);
  //     setCurrentScore([checkout, checkout]);
  //     setFirstPlayer(1);
  //   }
  // };

  // const addHistory = () => {
  //   setUndoHistory((hist) => {
  //     let temp = [...hist];
  //     // let newCurrentScore = currentScore;
  //     // newCurrentScore[currentPlayer-1] = _score;
  //     temp.push({
  //       score: [...currentScore],
  //       legs: [...legs],
  //       sets: [...sets],
  //       currentPlayer: currentPlayer,
  //       firstPlayer: firstPlayer,
  //     });

  //     return temp;
  //   });
  // };

  const sync = async () => {
    const parsePoolData = httpsCallable(fbf, "parsePoolAdminData");

    const result = await parsePoolData({ table: tableNumber, url: url });

    let match = result?.data?.match;

    if (match) {
      setTeamA(match?.player1);
      setTeamB(match?.player2);
      setRaceTo(match?.raceTo);
      setHandicap([match?.rankPlayer1, match?.rankPlayer2]);
      setBottomLabel(match?.round);
      setIsHandicapEnabled(true);
    }

    updateFB({
      teamA: match?.player1,
      teamB: match?.player2,
      raceTo: match?.raceTo,
      handicap: [match?.rankPlayer1, match?.rankPlayer2],
      bottomLabel: match?.round,
      isHandicapEnabled: true,
    });
  };

  const updateFB = (updateObject) => {
    if (enableFB) {
      const userRef = ref(db, `scores/${generatedCode}`);
      update(userRef, updateObject);
    }
  };

  const initiateDBListener = async (genCode) => {
    const code = genCode || generatedCode;
    setGeneratedCode(code);
    try {
      const scoresRef = ref(db, "monitors/" + code);
      const unsubscribe = onValue(scoresRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setFromA(data?.fromA || "");
          setFromB(data?.fromB || "");
          setCurrentSport(data?.currentSport);
          if (data?.score) setCurrentScore([...data.score]);
          setTeamA(data?.teamA || "Team A");
          setTeamB(data?.teamB || "Team B");
          setEnableListener(true);
          setEnableFB(true);
          if (data?.bottomLabel !== undefined)
            setBottomLabel(data?.bottomLabel);
          if (data?.raceTo !== undefined) setRaceTo(data?.raceTo);
          if (data?.handicap !== undefined) setHandicap(data?.handicap);
          if (data?.isHandicapEnabled !== undefined)
            setIsHandicapEnabled(data?.isHandicapEnabled);
          if (data?.crest) setCrest(data?.crest);
          if (data?.currentScoreboard !== undefined)
            setCurrentScoreboard(data?.currentScoreboard);
          // setInvalidCode(false);
          // if (bcast != null) bcast.postMessage(["score", data.score]);
        } else {
          // setInvalidCode(true);
        }
      });

      return unsubscribe;
    } catch {
      console.log("error caught");
    }

    return () => {};
  };

  const addScore = (e, team, multiplier) => {
    e.preventDefault();
    let score = currentScore;

    if (currentSport === "r" && (team == 1 || team === 3)) {
      team--;
    }
    score[team] = score[team] + 1 * multiplier;

    // if (score[team] <= 0) score[team] = 0;

    setCurrentScore(() => [...score]);

    if (bcast != null) {
      bcast.postMessage(["score", [...score]]);
    }

    if (enableFB === true) {
      update(ref(db, "monitors/" + generatedCode), {
        score: score,
      });
    }
  };

  // useEffect(() => {
  //   if (enableFB) {
  //     updateFB({
  //       legs,
  //       sets,
  //       currentScore,
  //       currentScoreboard,
  //       currentPlayer,
  //       isFirstTo,
  //       firstTo,
  //       competitionName,
  //       firstToLegs,
  //       isFirstToLegs,
  //       firstToLegsOG,
  //       currentScoreboard,
  //       teamA,
  //       teamB,
  //       checkout,
  //       firstPlayer,
  //       currentRound,
  //     });
  //   }
  // }, [
  //   currentPlayer,
  //   currentRound,
  //   currentScoreboard,
  //   firstToLegs,
  //   isFirstToLegs,
  //   firstToLegsOG,
  //   checkout,
  //   competitionName,
  // ]);

  const initiateDB = (code) => {
    setEnableFB(true);

    const scoresRef = ref(db, "monitors/" + code);
    set(scoresRef, {
      fromA,
      fromB,
      raceTo,
      currentSport,
      score: currentScore,
      currentScoreboard,
      colors,
      teamA,
      teamB,
      bottomLabel,
      raceTo,
      handicap,
      isHandicapEnabled,
      crest,
    });
  };

  // prettier-ignore
  const matrix =   [
[[0,0], [1,0], [1,0], [1,-1], [1,-1], [2,0], [2,-1], [3, 0], [3,-1], [3,-2], [3,-3]],
[[1,0], [0,0], [1,0], [1,0], [1,-1], [2,0], [2,-1], [3, 0], [3,-1], [3,-2], [3,-3]],
[[1,0], [1,0], [0,0], [1,0], [1,0], [1,-1], [2,0], [2,-1], [3, 0], [3,-1], [3,-2]],
[[1, -1], [1, 0], [1, 0], [0, 0], [1, 0], [1, 0], [1, -1], [2, 0], [3,0], [3, -1] , [3, -1]], 
[[1, -1], [1, -1], [1, 0], [1, 0], [0, 0], [1, 0], [1, -1], [1, -1], [2,-1], [3, 0] , [3, -1]], 
[[2, 0], [2, 0], [1, -1], [1, 0], [1, 0], [0,0], [1, 0], [1, -1], [2, 0], [3, 0], [3, -1]], 
[[2, -1], [2, -1], [2, 0], [1, -1], [1, -1], [1, 0], [1,1], [2,1], [2, 0], [3, 1], [3, 0]], 
[[3, 0], [3, 0], [2, -1], [2, 0], [1, -1], [1, -1], [2, 1], [1, 1], [2, 1], [2, 0], [3, 0]], 
[[3, -1], [3, -1], [3, 0],[3,0], [2, -1], [2, 0],[2,0], [2, 1], [2, 2], [3, 2], [3, 1]], 
[[3, -2], [3, -2], [3, -1], [3,-1], [3, 0], [3, 0], [3, 1], [2, 0], [3, 2], [3, 3], [3, 2]], 
[[3, -3], [3,-3], [3, -2], [3, -1], [3, -1], [3, -1], [3, 0], [3, 0], [3, 1], [3, 2], [3, 3]]
]

  const r = {
    [-7]: 0,
    [-6]: 1,
    [-5]: 2,
    [-4]: 3,
    [-3]: 4,
    [-2]: 5,
    [-1]: 6,
    [0]: 7,
    [1]: 8,
    [2]: 9,
    [3]: 10,
  };

  const prevHandicapRef = useRef();

  useEffect(() => {
    // Check if the previous values are the same as the current values
    const prevHandicap = prevHandicapRef.current;
    const handicapChanged = prevHandicap
      ? prevHandicap[0] !== handicap[0] || prevHandicap[1] !== handicap[1]
      : true;
    prevHandicapRef.current = handicap;

    if (isHandicapEnabled && handicapChanged) {
      if (
        handicap[0] <= 3 &&
        handicap[1] >= -7 &&
        handicap[1] <= 3 &&
        handicap[0] >= -7 &&
        handicap[0] !== "" &&
        handicap[1] !== ""
      ) {
        let start =
          matrix[r[!isNaN(handicap[1]) ? handicap[1] : 0]][
            r[!isNaN(handicap[0]) ? handicap[0] : 0]
          ];
        console.log(matrix[r[0]]);
        console.log({ start, handicap });

        if (handicap[0] < handicap[1]) {
          const temp = start[0];
          start[0] = start[1];
          start[1] = temp;
        }

        let _score = [start[0], 0, start[1], [0]];
        setStartHandicap(start);
        setCurrentScore([start[0], 0, start[1], [0]]);
        updateFB({ score: _score });
      }
    }
  }, [isHandicapEnabled, handicap]);

  useEffect(() => {
    console.log("in pool context");
    if (currentSport === "p" && enableFB) {
      console.log("initate db listener - pool");
      initiateDBListener(generatedCode);
    }
  }, [currentSport, enableFB]);

  value = {
    fromA,
    setFromA,
    fromB,
    setFromB,
    raceTo,
    setRaceTo,
    initiateDB,
    enableFB,
    setEnableFB,
    isRemote,
    setIsRemote,
    initiateDBListener,
    enableListener,
    addScore,
    handicap,
    setHandicap,
    startHandicap,
    setStartHandicap,
    bottomLabel,
    setBottomLabel,
    isHandicapEnabled,
    setIsHandicapEnabled,
    tableNumber,
    setTableNumber,
    url,
    setUrl,
    sync,
  };

  return <PoolContext.Provider value={value}>{children}</PoolContext.Provider>;
};

PoolProvider.propTypes = {
  children: PropTypes.node,
};
