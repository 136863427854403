import React, { useEffect, useState } from "react";
import { Navbar as Navbar, Nav, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import logo from "../../img/new-logo.png";
import { GearFill } from "react-bootstrap-icons";
import PropTypes from "prop-types";
import useTranslations from "../../contexts/useTranslations";
import { useSubscription } from "../../contexts/SubscriptionContext";
import { useLocation } from "react-router-dom";

function NavbarTop() {
  const { currentUser, logout, setCurrentUser } = useAuth();
  const [buttons, setButtons] = useState();
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const t = useTranslations();
  const { setSubscription } = useSubscription();
  const location = useLocation();

  const handleLogout = async (e) => {
    e.preventDefault();
    setExpanded(false);
    try {
      await logout();
      setCurrentUser();
      setSubscription(false);
      history.push("/");
    } catch (error) {
      console.log(error);
    }
  };

  const handleOptions = (e) => {
    e.preventDefault();
    setExpanded(false);
    history.push("/settings");
  };

  let displayButtons = [
    {
      style: "btn btn-link text-1000 fw-medium ",
      label: "noun:sign-in",
      link: "/login",
      signedIn: false,
      onClick: "",
    },
    {
      style: "btn btn-info rounded-pill ",
      label: "noun:start-free-trial",
      link: "/signup",
      signedIn: false,
      onClick: "",
    },
    {
      style: "btn btn-link text-1000 fw-medium order-1 order-lg-0",
      label: "noun:settings",
      link: "/settings",
      signedIn: true,
      onClick: handleOptions,
    },
    {
      style: "btn btn-secondary rounded-pill order-0",
      label: "noun:logout",
      link: "/",
      signedIn: true,
      onClick: handleLogout,
    },
  ];

  useEffect(async () => {
    setButtons(
      displayButtons.map((button, key) => {
        let userBool = currentUser ? true : false;

        if (userBool == false && button.signedIn == false) {
          return (
            <Link style={{ float: "left" }} to={button.link} key={key}>
              <button
                className={button.style}
                onClick={() => setExpanded(false)}
              >
                {button.link === "/signup" ? (
                  <i className="icon-tiny fa fa-soccer-ball-o mr-2"></i>
                ) : null}
                {t(button.label)}
              </button>
            </Link>
          );
        }

        if (userBool == true && button.signedIn == true) {
          return (
            <Link style={{ float: "left" }} to={button.link} key={key}>
              <button
                className={button.style}
                onClick={(e) => button.onClick(e)}
              >
                {t(button.label)}
              </button>
            </Link>
          );
        }
      })
    );
  }, [currentUser, t]);

  if (location.pathname === "/share" || location.pathname === "/monitor") {
    return <></>;
  }

  return (
    <section className="pb-0 pt-7 mb-2">
      <div>
        <style>
          {
            ".gear:hover{color: #0fffdd, fill: #0fffdd} .gear{color:#0275d8, fill: #0275d8 }"
          }
        </style>
        <main>
          <Navbar
            className="py-3 "
            expand="lg"
            fixed="top"
            style={{
              backgroundColor: "#0CAF57",
              boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.05)",
              padding: "0 7vw",
            }}
            expanded={expanded}
          >
            {/* <div className="container"> */}
            {/* <Navbar.Brand href="#home">React Bootstrap Navbar</Navbar.Brand> */}
            <Link to="/" className="navbar-brand">
              <img src={logo} alt="logo" width="200" />
            </Link>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={() => setExpanded(expanded ? false : "expanded")}
            />

            <Navbar.Collapse
              id="basic-navbar-nav"
              className="collapse navbar-collapse"
            >
              {!currentUser ? (
                <div
                  className="d-flex flex-1 mx-auto"
                  // style={{ backgroundColor: "red" }}
                >
                  <ul className="navbar-nav w-100 border-bottom border-lg-bottom-0 pt-2 pt-lg-0 mt-1">
                    <li className="ml-3 bb-1">
                      <a
                        className="nav-link"
                        href="https://obscoreboard.com"
                        onClick={() => setExpanded(false)}
                      >
                        {t("noun:home")}
                      </a>
                    </li>
                    <li className=" ml-3 bb-1">
                      <Link
                        className="nav-link"
                        onClick={() => setExpanded(false)}
                        to="/faq"
                      >
                        {t("noun:faq")}
                      </Link>
                    </li>
                    <li className=" ml-3 bb-1">
                      <Link
                        className="nav-link"
                        onClick={() => setExpanded(false)}
                        to="/features"
                      >
                        {t("noun:features")}
                      </Link>
                    </li>
                    <li className=" ml-3 bb-1">
                      <a
                        className="nav-link"
                        href="https://obscoreboard.com/blog"
                        onClick={() => setExpanded(false)}
                      >
                        {t("noun:blog")}
                      </a>
                    </li>
                    <li className=" ml-3  bb-1">
                      <Link
                        className="nav-link"
                        onClick={() => setExpanded(false)}
                        to="/pricing"
                      >
                        {t("noun:pricing")}
                      </Link>
                    </li>
                    <li className=" ml-3 bb-1">
                      <Link
                        className="nav-link"
                        onClick={() => setExpanded(false)}
                        to="/contact-us"
                      >
                        {t("noun:contact")}
                      </Link>
                    </li>
                  </ul>
                </div>
              ) : (
                <>
                  <Link to="/dashboard">
                    <Button className="btn btn-secondary btn-sm">
                      {t("noun:dashboard")}
                    </Button>
                  </Link>

                  <Link to="/instructions/">
                    <Button
                      className="btn btn-sm text-secondary ml-1"
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #2C6E63",
                      }}
                    >
                      {t("noun:instructions")}
                    </Button>
                  </Link>
                </>
              )}

              <Nav className={currentUser ? "ml-auto" : ""}>
                <form className="d-flex py-3 py-lg-0">
                  {currentUser ? (
                    <Link to="/settings" onClick={() => setExpanded(false)}>
                      <GearFill
                        className="secondary"
                        style={{ marginTop: 7, float: "left" }}
                        size={24}
                      />
                    </Link>
                  ) : null}
                  {buttons}
                </form>
              </Nav>
            </Navbar.Collapse>
            {/* </div> */}
          </Navbar>
        </main>
      </div>
    </section>
  );
}

NavbarTop.propTypes = {
  subscribeSb: PropTypes.func,
};

export default NavbarTop;
