/* eslint-disable */
import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
} from "react";
import PropTypes from "prop-types";

import { useScoreboardContext } from "../../../contexts/ScoreboardContext";
import { db } from "../../../firebase";
import useTranslations from "../../../contexts/useTranslations";
import { onValue, ref, set, update } from "firebase/database";

const TennisContext = createContext();

export function useTennisContext() {
  return useContext(TennisContext);
}

export const TennisProvider = ({ children }) => {
  const t = useTranslations();

  const {
    currentSport,
    currentScoreboard,
    setCurrentScoreboard,
    teamA,
    setTeamA,
    teamB,
    setTeamB,
    generatedCode,
    colors,
    enableFB,
    setEnableFB,
    tennisVariant,
    setTennisVariant,
    crest,
  } = useScoreboardContext();

  // 0 = tennis, 1 = ping pong, 2 = pickle ball
  const [sets, setSets] = useState([0, 0]);
  const [legs, setLegs] = useState([0, 0]);
  const [gameModeLegs, setGameModeLegs] = useState("t");
  const [tiebreakLegs, setTiebreakLegs] = useState(6);
  const [firstToGames, setFirstToGames] = useState(7);
  const [firstToPoints, setFirstToPoints] = useState(11);
  const [gameNumber, setGameNumber] = useState(0); // live game number
  const [totalNumberOfGames, setTotalNumberOfGames] = useState(3);

  const [finalLegIsTiebreaker, setFinalLegIsTiebreaker] = useState(true);
  const [scoreIsTiebreaker, setScoreIsTiebreaker] = useState(false);
  const [isFirstServer, setIsFirstServer] = useState(true);
  const [currentScore, setCurrentScore] = useState([0, 0]);
  const [notice, setNotice] = useState(t("noun:notice-text"));
  const [showNotice, setShowNotice] = useState(false);
  const [showModalSettings, setShowSettingsModal] = useState(false);
  const [showModalFeedback, setShowModalFeedback] = useState(false);
  const [isManualScores, setIsManualScores] = useState(false);
  const [gameIsOver, setGameIsOver] = useState(false);

  const [undoHistory, setUndoHistory] = useState([
    {
      currentScore: currentScore,
      legs: [0, 0],
      sets: [0, 0],
      isFirstServer: isFirstServer,
      finalLegIsTiebreaker,
      scoreIsTiebreaker,
      gameNumber,
      gameIsOver,
    },
  ]);

  let value;

  const addScore = (player) => {
    if (tennisVariant === "0") {
      if (scoreIsTiebreaker) addPointsTiebreaker(player);
      else addPointsRegular(player);
    } else {
      addPointsPickle(player + 1);
    }
  };

  const addPointsRegular = (player) => {
    let otherPlayer = player === 0 ? 1 : 0;
    let points = 0;
    let _isFirstServer = isFirstServer;

    addHistory();

    if (
      (currentScore[player] === 40 && currentScore[otherPlayer] !== 40) ||
      currentScore[player] === "AD"
    ) {
      if (gameModeLegs === "t") {
        getTiebreakLegs(player);
      } else if (gameModeLegs === "a") {
        getAdvantage(player);
      }

      setCurrentScore([0, 0]);
      setShowNotice(false);
      _isFirstServer = !_isFirstServer;

      updateFB({
        showNotice: false,
        notice,
        currentScore: [0, 0],
        isFirstServer: _isFirstServer,
      });
      setIsFirstServer(_isFirstServer);
    } else if (currentScore[player] === 0) {
      points = 15;
    } else if (currentScore[player] === 15) {
      points = 30;
    } else {
      points = 40;
      if (
        (isFirstServer ? 1 : 0) === player &&
        currentScore[otherPlayer] < 40
      ) {
        setNotice(t("noun:breakpoint"));
        setShowNotice(true);
        updateFB({
          showNotice: true,
          notice: t("noun:breakpoint"),
        });
      }
    }

    if (points === 40 && currentScore[otherPlayer] === 40) {
      setShowNotice(false);
    }

    if (currentScore[player] === 40 && currentScore[otherPlayer] === 40) {
      points = "AD";
      lastPoints(player, otherPlayer, "AD", "");
    } else if (currentScore[otherPlayer] === "AD") {
      points = "LV";
      lastPoints(player, otherPlayer, 40, 40);
    } else
      setCurrentScore((score) => {
        let _score = [...score];
        _score[player] = points;
        updateFB({ currentScore: _score });
        return _score;
      });

    if ((isFirstServer ? 1 : 0) === player && points === "AD") {
      let note = t("noun:breakpoint");
      setNotice(note);
      setShowNotice(true);
      updateFB({ notice: note, showNotice: true });
    }

    if (points === "LV" && showNotice) {
      setShowNotice(false);
      updateFB({ showNotice: false, notice });
    }
  };

  const addPointsTiebreaker = (player) => {
    let otherPlayer = player === 0 ? 1 : 0;
    let cs1 = currentScore[player] + 1;
    let cs2 = currentScore[otherPlayer];
    let _currentScore = [...currentScore];
    let _sets = [...sets];
    let _isFirstServer = isFirstServer;
    let _scoreIsTiebreaker = scoreIsTiebreaker;

    addHistory();

    if (cs1 >= firstToGames && cs1 - cs2 >= 2) {
      _currentScore = [0, 0];
      _sets[player]++;
      setSets(_sets);
      setLegs([0, 0]);
      setScoreIsTiebreaker(false);
      _isFirstServer = !_isFirstServer;
      _scoreIsTiebreaker = false;
      setIsFirstServer(_isFirstServer);
    } else {
      _currentScore[player]++;

      if ((_currentScore[0] + _currentScore[1]) % 2 != 0) {
        _isFirstServer = !_isFirstServer;
        setIsFirstServer(_isFirstServer);
      }
    }
    updateFB({
      sets: [..._sets],
      legs: [...legs],
      scoreIsTiebreaker: _scoreIsTiebreaker,
      isFirstServer: _isFirstServer,
      currentScore: _currentScore,
    });
    // updateFB({ currentScore: _currentScore, isFirstServer: _isFirstServer });
    setCurrentScore(_currentScore);
  };

  const getTiebreakLegs = (player) => {
    let otherPlayer = player === 0 ? 1 : 0;
    let _sets = [...sets];
    let _legs = [...legs];
    let l1 = _legs[player] + 1;
    let l2 = _legs[otherPlayer];
    let _tiebreakLegs = parseInt(tiebreakLegs);
    let _scoreIsTiebreaker = scoreIsTiebreaker;

    if (finalLegIsTiebreaker && l1 === _tiebreakLegs && l2 === _tiebreakLegs) {
      _scoreIsTiebreaker = true;
      setScoreIsTiebreaker(_scoreIsTiebreaker);
      setCurrentScore([0, 0]);
      _legs[player]++;
      setLegs(_legs);
    } else {
      if (l1 >= _tiebreakLegs && l1 - l2 >= 2) {
        _sets[player]++;
        _legs = [0, 0];
      } else {
        _legs[player]++;
      }

      setSets(_sets);
      setLegs(_legs);
    }

    updateFB({
      sets: _sets,
      legs: _legs,
      scoreIsTiebreaker: _scoreIsTiebreaker,
    });
  };

  const getAdvantage = (currentPlayer) => {
    let otherPlayer = currentPlayer === 0 ? 1 : 0;
    let l1 = legs[currentPlayer] + 1;
    let l2 = legs[otherPlayer];

    if (l1 >= firstToGames && l1 - l2 > 1) {
      setLegs([0, 0]);
      setSets((_sets) => {
        let temp = [..._sets];
        temp[currentPlayer]++;
        updateFB({ sets: temp, legs: [0, 0] });
        return temp;
      });
    } else {
      setLegs((_legs) => {
        let temp = [..._legs];
        temp[currentPlayer]++;
        updateFB({ legs: temp });
        return temp;
      });
    }
  };

  const addPointsPickle = (player) => {
    let _currentScore = [...currentScore];
    let _legs = [...legs];
    let otherPlayer = player === 1 ? 2 : 1;

    if (!gameIsOver) addHistory();

    let _p1Score = _currentScore[gameNumber]["player" + player];
    let _p2Score = _currentScore[gameNumber]["player" + otherPlayer];

    if (_p1Score + 1 >= firstToPoints && _p1Score + 1 - _p2Score > 1) {
      if (gameIsOver === false) {
        _currentScore[gameNumber]["player" + player]++;

        if (
          _legs[player - 1] + 1 + 1 <
          totalNumberOfGames - _legs[player - 1]
        ) {
          _legs[player - 1]++;
          setLegs(_legs);
          _currentScore = [...currentScore, { player1: 0, player2: 0 }];
          updateFB({
            currentScore: _currentScore,
            legs: _legs,
          });
          setGameNumber((_num) => {
            let _temp = _num + 1;
            updateFB({ gameNumber: _temp });
            return _temp;
          });
        } else {
          updateFB({
            notice: "Match Over",
            gameIsOver: true,
            showNotice: true,
          });
          setNotice("Match Over");
          setShowNotice(true);
          setGameIsOver(true);
        }
      }
    } else {
      _currentScore[gameNumber]["player" + player]++;
    }

    updateFB({
      currentScore: [..._currentScore],
    });

    if (!gameIsOver) setCurrentScore([..._currentScore]);
  };

  const resetScoreboard = () => {
    let _currentScore = [0, 0];
    if (tennisVariant === "0") {
      setCurrentScore(_currentScore);
      setSets([0, 0]);
    } else {
      _currentScore = [{ player1: 0, player2: 0 }];
      setCurrentScore(_currentScore);
      setGameNumber(0);
      setGameIsOver(false);
    }

    setGameIsOver(false);
    setIsFirstServer(true);
    setLegs([0, 0]);
    setShowNotice(false);

    updateFB({
      currentScore: _currentScore,
      sets: [0, 0],
      legs: [0, 0],
      gameNumber: 0,
      gameIsOver: false,
      isFirstServer: true,
      showNotice: false,
    });
  };

  const initiateDB = (code) => {
    setEnableFB(true);
    set(
      ref(db, "monitors/" + code), // Reference to the location to set
      {
        started: false,
        sets: [...sets],
        legs: [...legs],
        currentScore: [...currentScore],
        firstToPoints,
        gameNumber,
        totalNumberOfGames,
        gameIsOver,
        firstToGames,
        tennisVariant,
        teamA,
        teamB,
        currentSport,
        currentScoreboard,
        gameModeLegs,
        tiebreakLegs,
        firstToGames,
        finalLegIsTiebreaker,
        scoreIsTiebreaker,
        isFirstServer,
        notice,
        showNotice,
        isManualScores,
        colors,
        currentScoreboard,
        crest,
      }
    );
  };

  // const getFirstTo = (currentPlayer) => {
  //   if (legs[currentPlayer] + 1 >= firstToLegs && hasSets) {
  //     setLegs([0, 0]);
  //     setSets((_sets) => {
  //       let tempSets = [..._sets];
  //       tempSets[currentPlayer]++;
  //       return tempSets;
  //     });
  //   } else {
  //     setLegs((leg) => {
  //       let tempScore = [...leg];
  //       tempScore[currentPlayer]++;
  //       return tempScore;
  //     });
  //     setCurrentScore([0, 0]);
  //   }
  // };

  // const getBestOf = (currentPlayer) => {
  //   let otherPlayer = currentPlayer === 0 ? 1 : 0;
  //   let l1 = legs[currentPlayer] + 1;
  //   let l2 = legs[otherPlayer];

  //   if (l1 > firstToLegs - l1 && hasSets) {
  //     setLegs([0, 0]);
  //     setSets((_sets) => {
  //       let temp = [..._sets];
  //       temp[currentPlayer]++;
  //       return temp;
  //     });
  //   } else if (l2 > firstToLegs - l2 && hasSets) {
  //     setLegs([0, 0]);
  //     setSets((_sets) => {
  //       let temp = [..._sets];
  //       temp[currentPlayer]++;
  //       return temp;
  //     });
  //   } else {
  //     setLegs((_legs) => {
  //       let tempScore = [..._legs];
  //       tempScore[currentPlayer]++;
  //       return tempScore;
  //     });
  //   }
  // };

  const addHistory = useCallback(() => {
    const newArray =
      tennisVariant === "2"
        ? currentScore.map((obj) => ({ ...obj }))
        : [...currentScore];
    let _legs = [...legs];
    let _sets = [...sets];
    let _gameNumber = gameNumber;
    let _gameIsOver = gameIsOver;
    let _showNotice = showNotice;

    setUndoHistory((hist) => {
      let temp = [...hist];

      temp.push({
        currentScore: newArray,
        legs: _legs,
        sets: _sets,
        isFirstServer: isFirstServer,
        finalLegIsTiebreaker,
        scoreIsTiebreaker,
        gameNumber: _gameNumber,
        gameIsOver: _gameIsOver,
        showNotice: _showNotice,
      });

      return temp;
    });
  }, [
    currentScore,
    legs,
    sets,
    isFirstServer,
    finalLegIsTiebreaker,
    scoreIsTiebreaker,
    gameNumber,
    gameIsOver,
    showNotice,
  ]);

  const undo = () => {
    const undoHistoryLength = undoHistory.length;

    if (undoHistoryLength > 1) {
      const lastHistory = undoHistory[undoHistoryLength - 1];
      let _legs = [...lastHistory.legs];
      let _sets = [...lastHistory.sets];
      let _firstServer = lastHistory?.isFirstServer;
      let _currentScore = [...lastHistory.currentScore];
      let _scoreIsTiebreaker = lastHistory?.scoreIsTiebreaker;
      let _showNotice = lastHistory?.showNotice;
      let _gameIsOver = lastHistory?.gameIsOver;
      let _gameNumber = lastHistory?.gameNumber;

      updateFB({
        currentScore: [..._currentScore],
        sets: [...sets],
        legs: [..._legs],
        isFirstServer: _firstServer,
        scoreIsTiebreaker: _scoreIsTiebreaker,
        showNotice: _showNotice,
        gameNumber: _gameNumber,
        gameIsOver: _gameIsOver,
      });

      setCurrentScore([..._currentScore]);
      setLegs([..._legs]);
      setSets([..._sets]);
      setScoreIsTiebreaker(_scoreIsTiebreaker);
      setFinalLegIsTiebreaker(lastHistory.finalLegIsTiebreaker);
      setIsFirstServer(_firstServer);
      setShowNotice(_showNotice);
      setGameNumber(_gameNumber);
      setGameIsOver(_gameIsOver);

      setUndoHistory((hist) => {
        let temp = [...hist];
        temp.pop();
        return temp;
      });
    } else {
      let _legs = [0, 0];
      let _sets = [0, 0];
      let _currentScore = [0, 0];
      let _gameNumber = 0;
      let _gameIsOver = false;
      let _showNotice = false;
      let _isFirstServer = true;

      setLegs(_legs);
      setSets(_sets);
      setGameNumber(_gameNumber);
      setShowNotice(_showNotice);
      setGameIsOver(_gameIsOver);
      setIsFirstServer(_isFirstServer);
      if (tennisVariant === "0") _currentScore = [0, 0];
      else _currentScore = [{ player1: 0, player2: 0 }];

      setCurrentScore(_currentScore);
      updateFB({
        legs: [..._legs],
        sets: [..._sets],
        currentScore: [..._currentScore],
        gameNumber: _gameNumber,
        gameIsOver: _gameIsOver,
        isFirstServer: _isFirstServer,
        scoreIsTiebreaker: false,
        showNotice: _showNotice,
      });
    }
  };

  const lastPoints = (player, otherPlayer, point1, point2) => {
    setCurrentScore((score) => {
      let _score = [...score];
      _score[otherPlayer] = point2;
      _score[player] = point1;
      updateFB({ currentScore: _score });
      return _score;
    });
  };

  const updateFB = (updateObject) => {
    if (enableFB) {
      update(
        ref(db, "monitors/" + generatedCode), // Reference to the location to update
        updateObject // Data to update
      );
    }
  };

  useEffect(() => {
    if (enableFB) {
      const code = generatedCode;

      try {
        const scoresRef = ref(db, "monitors/" + code);

        const unsubscribe = onValue(scoresRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();

            // Setters for simple key-value pairs
            if (data?.teamA !== undefined) setTeamA(data.teamA);
            if (data?.teamB !== undefined) setTeamB(data.teamB);
            if (data?.firstToPoints !== undefined)
              setFirstToPoints(data.firstToPoints);
            if (data?.gameNumber !== undefined) setGameNumber(data.gameNumber);
            if (data?.totalNumberOfGames !== undefined)
              setTotalNumberOfGames(data.totalNumberOfGames);
            if (data?.firstToGames !== undefined)
              setFirstToGames(data.firstToGames);
            if (data?.tennisVariant !== undefined)
              setTennisVariant(data.tennisVariant);
            if (data?.notice !== undefined) setNotice(data.notice);
            if (data?.gameModeLegs !== undefined)
              setGameModeLegs(data.gameModeLegs);
            if (data?.tiebreakLegs !== undefined)
              setTiebreakLegs(data.tiebreakLegs);
            if (data?.currentScoreboard !== undefined)
              setCurrentScoreboard(data.currentScoreboard);

            // Setters for arrays with length check
            if (data?.sets?.length === 2) setSets([...data.sets]);
            if (data?.legs?.length === 2) setLegs([...data.legs]);
            if (data?.currentScore !== undefined)
              setCurrentScore([...data.currentScore]);

            // Setters for boolean properties
            if (typeof data?.gameIsOver === "boolean")
              setGameIsOver(data.gameIsOver);
            if (typeof data?.isFirstServer === "boolean")
              setIsFirstServer(data.isFirstServer);
            if (typeof data?.showNotice === "boolean")
              setShowNotice(data.showNotice);
            if (typeof data?.finalLegIsTiebreaker === "boolean")
              setFinalLegIsTiebreaker(data.finalLegIsTiebreaker);
            if (typeof data?.isManualScores === "boolean")
              setIsManualScores(data.isManualScores);
            if (typeof data?.scoreIsTiebreaker === "boolean")
              setScoreIsTiebreaker(data.scoreIsTiebreaker);
          }
        });

        // Cleanup function to stop listening when component is unmounted
        return () => unsubscribe();
      } catch {
        console.log("Error initializing Firebase listener");
      }
    }
  }, [enableFB]);

  value = {
    sets,
    setSets,
    legs,
    setLegs,
    currentScore,
    setCurrentScore,
    undoHistory,
    undo,
    addScore,
    undo,
    isFirstServer,
    setIsFirstServer,
    notice,
    setNotice,
    showNotice,
    setShowNotice,
    showModalSettings,
    setShowSettingsModal,
    gameModeLegs,
    setGameModeLegs,
    showModalFeedback,
    setShowModalFeedback,
    firstToGames,
    setFirstToGames,
    tiebreakLegs,
    setTiebreakLegs,
    isManualScores,
    setIsManualScores,
    initiateDB,
    enableFB,
    updateFB,
    scoreIsTiebreaker,
    setScoreIsTiebreaker,
    tennisVariant,
    setTennisVariant,
    gameNumber,
    setGameNumber,
    firstToPoints,
    setFirstToPoints,
    totalNumberOfGames,
    setTotalNumberOfGames,
    resetScoreboard,
    setUndoHistory,
  };

  return (
    <TennisContext.Provider value={value}>{children}</TennisContext.Provider>
  );
};

TennisProvider.propTypes = {
  children: PropTypes.node,
};
