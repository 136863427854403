import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";
import "firebase/analytics";
// import { getFunctions } from "firebase/functions";

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "gaa-scoreboard.firebaseapp.com",
  databaseURL: "https://gaa-scoreboard.firebaseio.com",
  projectId: "gaa-scoreboard",
  storageBucket: "gaa-scoreboard.appspot.com",
  messagingSenderId: "1072470034913",
  appId: "1:1072470034913:web:95bd9b8ec9d3915a382aa0",
  measurementId: "G-3K4R8KCLQS",
});

// // TODO: MAKE SURE TO SWITCH BACK
// const app = firebase.initializeApp({
//   apiKey: process.env.REACT_APP_FIREBASE_KEY,
//   authDomain: "localhost:9099",
//   databaseURL: "http://localhost:9000?ns=gaa-scoreboard",
//   projectId: "gaa-scoreboard",
//   storageBucket: "gaa-scoreboard.appspot.com",
//   messagingSenderId: "1072470034913",
//   appId: "1:1072470034913:web:95bd9b8ec9d3915a382aa0",
//   measurementId: "G-YK0PLJKRDN",
// });

export const auth = getAuth();
export const db = getDatabase(app);
export const dbfs = getFirestore();
export const firestore = getFirestore();
export const googleAuthProvider = new GoogleAuthProvider();
// export const fbf = getFunctions(app, "europe-west1");
export const fbf = getFunctions(app, "europe-west2");
export const analytics = getAnalytics(app);
// export const analytics = getAnalytics(app);
export default app;
