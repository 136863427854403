import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useScoreboardContext } from "../../contexts/ScoreboardContext";

function MapCards(props) {
  const { cards } = useScoreboardContext();
  let countColCardsT1 = -1;
  let countRowCardsT1 = 0;
  const [returnedCards, setReturnedCards] = useState();

  useEffect(() => {
    if (cards)
      setReturnedCards(
        cards[props.team].length > 0
          ? cards[props.team].map((card, key) => {
              if (card.length !== 0)
                if (countColCardsT1 % 2) {
                  countColCardsT1 = 0;
                  if (key >= 2) countRowCardsT1++;
                } else countColCardsT1++;

              return (
                <rect
                  fill={card}
                  key={key}
                  x={
                    props.x +
                      (props.right ? 1 : -1) *
                        (countColCardsT1 * props.gap[0]) *
                        props.multiplier +
                      countColCardsT1 || 2
                  }
                  y={
                    props.y +
                    (props.down ? 1 : -1) *
                      props.gap[1] *
                      countRowCardsT1 *
                      props.multiplier
                  }
                  width={27 * props.multiplier}
                  height={6 * props.multiplier}
                />
              );
            })
          : null
      );
  }, [JSON.stringify(cards)]);

  return <svg height="200px">{returnedCards}</svg>;
}

MapCards.propTypes = {
  team: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
  gap: PropTypes.array,
  multiplier: PropTypes.number,
  down: PropTypes.bool,
  right: PropTypes.bool,
};

MapCards.defaultProps = {
  multiplier: 1,
  down: true,
  right: true,
};

export default MapCards;
