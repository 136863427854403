import React from "react";
import { useScoreboardContext } from "../../contexts/ScoreboardContext";
import PropTypes from "prop-types";
function InputButton(props) {
  const { setModalCrestsState } = useScoreboardContext();
  // const refer3 = React.createRef();

  // const handleChange = (e) => {
  //   const file = e.target.files[0];
  //   if (!file) return;

  //   let crestFiles = crest;

  //   const reader = new FileReader();

  //   reader.onloadend = () => {
  //     if (props.refer === "crest1") crestFiles[0] = reader.result;

  //     if (props.refer === "crest2") crestFiles[1] = reader.result;
  //     setCrest([...crestFiles]);
  //   };

  //   if (file) {
  //     reader.readAsDataURL(file);
  //   } else {
  //     console.log("error");
  //   }
  // };

  const handleSetModalCrestsState = () => {
    let state = 1;
    if (props.refer === "crest2") state = 2;

    setModalCrestsState(state);
  };

  return (
    <div>
      <div
        className="btn btn-secondary"
        style={{
          width: 100,
          height: 30,
          position: "absolute",
          left: props.left,
          top: props.top,
        }}
        onClick={handleSetModalCrestsState}
      >
        <div
          style={{
            height: 50,
            zAxis: 10,
            position: "relative",
            left: 0,
            top: -5,
          }}
        >
          {props.text}
        </div>
        {/* <input
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            opacity: 0,
            width: "100%",
            height: 30,
            zAxis: 5,
          }}
          type="file"
          id={props.refer}
          ref={refer3}
          name="image"
          onChange={(e) => handleSetModalCrestsState(e)}
          accept="image/*"
        ></input> */}
      </div>
    </div>
  );
}

InputButton.propTypes = {
  left: PropTypes.number,
  top: PropTypes.number,
  refer: PropTypes.string,
  text: PropTypes.string,
};

export default InputButton;
