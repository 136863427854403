import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";

const FirebaseProvider = ({ children }) => {
  useEffect(() => {
    logEvent(analytics, "app_loaded");

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }

    gtag("js", new Date());

    // gtag("config", "G-XVVNQZKNC0");
    gtag("config", "AW-980212512");

    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "GTM-TV5SCBH");
  }, []);

  return <>{children}</>;
};

FirebaseProvider.propTypes = {
  children: PropTypes.object,
};

export default FirebaseProvider;
