/* eslint-disable no-unused-vars */
import React, { useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import ScoreButton from "./ScoreButton";

export default function GameControls({ currentScore, handleChangeScore }) {
  const getBgColor = useCallback(
    (score) => {
      return score === currentScore ? "success" : "danger";
    },
    [currentScore]
  );

  return (
    <Row>
      <Col>
        <Row>
          <ScoreButton
            handlePress={() => handleChangeScore(0)}
            displayValue={0}
            buttonBackground={getBgColor(0)}
          />
        </Row>
        <Row>
          <ScoreButton
            handlePress={() => handleChangeScore(15)}
            displayValue={15}
            buttonBackground={getBgColor(15)}
          />
        </Row>
        <Row>
          <ScoreButton
            handlePress={() => handleChangeScore(30)}
            displayValue={30}
            buttonBackground={getBgColor(30)}
          />
        </Row>
        <Row>
          <ScoreButton
            handlePress={() => handleChangeScore(40)}
            displayValue={40}
            buttonBackground={getBgColor(40)}
          />
        </Row>
        <Row>
          <ScoreButton
            handlePress={() => handleChangeScore("AD")}
            displayValue={"AD"}
            buttonBackground={getBgColor("AD")}
          />
        </Row>
      </Col>
    </Row>
  );
}

GameControls.propTypes = {
  handleChangeScore: PropTypes.func,
  currentScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
