/* eslint-disable no-unused-vars */
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import PropTypes from "prop-types";

export default function ScoreButton({
  displayValue,
  handlePress,
  buttonBackground,
}) {
  return (
    <Button
      variant={buttonBackground}
      className="w-100 mb-1 p-0"
      onClick={() => handlePress()}
    >
      {displayValue}
    </Button>
  );
}

ScoreButton.propTypes = {
  displayValue: PropTypes.number,
  buttonBackground: PropTypes.string,
  handlePress: PropTypes.func,
};
