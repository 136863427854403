import React from "react";
import { Link } from "react-router-dom";

import "./style.css"; // Style file for the error page

const NotFoundPage = () => {
  return (
    <div className="vertical-center">
      <div className="container">
        <div id="notfound" className="text-center ">
          <h1>😮</h1>
          <h2>Oops! Page Not Be Found</h2>

          <p>Sorry but the page you are looking for does not exist.</p>
          <Link to="/">Back to homepage</Link>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
