/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useScoreboardContext } from "../../contexts/ScoreboardContext";
import { sportsSettings } from "../../scoreboard-app/components/Initialise_Settings";
import Timer from "../../scoreboard-app/components/Timer";
import "./monitor.css";
import TimerRemote from "../../scoreboard-app/components/TimerRemote";
import { BasketballProvider } from "../../scoreboard-app/components/Basketball/BasketballContext";
import { PoolProvider } from "../../scoreboard-app/hooks/PoolContext";
import { DartsProvider } from "../../scoreboard-app/hooks/DartsContext";
import Tennis from "../../scoreboard-app/components/Tennis/Tennis";

function Internal() {
  const {
    enableFB,
    enableListener,
    initiateDBListener,
    currentSport,
    currentScoreboard,
    setTimeForward,
    setMinutes,
    setSeconds,
    updateFB,
    setTime,
    generatedCode,
    seconds,
    minutes,
    halfLength,
    setTimeIsRed,
    timeForward,
    started,
    setStarted,
    setFirstHalfCounter,
    setSecondHalfCounter,
    updateTimerState,
    setGeneratedCode,
    setEnableFB,
    setMode,
    currentScore,
    getControllerInitialValuesMonitor,
  } = useScoreboardContext();

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const position = searchParams.get("position");
  const leftOffset = Number(searchParams.get("offsetX") || 0);
  const topOffset = Number(searchParams.get("offsetY") || 0);
  const scale = Number(searchParams.get("scale") || 0);

  useEffect(() => {
    if (id) {
      getControllerInitialValuesMonitor(id);
      if (position) setMode(position);
    }
  }, [id, generatedCode]);

  useEffect(() => {}, []);

  // Listen for changes in enableFB to initiate DB listener
  useEffect(() => {
    console.log("re-render");
    let unsubscribe = () => {};

    // if (enableFB && ["f", "s", "r"].includes(currentSport)) {
    //   initiateDBListener(generatedCode).then((unsub) => {
    //     unsubscribe = unsub;
    //   });
    // }

    return () => {
      if (typeof unsubscribe === "function") unsubscribe();
    };
  }, [enableFB, currentSport]);

  useEffect(() => {
    setTimeForward(true);
    setMinutes(0);
    setSeconds(0);
    setTime("00:00");

    updateFB({ timeForward: true, time: "0" });
  }, [currentSport]);

  return (
    <div id="container-share">
      <Timer />
      <div className="presentation-place">
        {currentSport && (
          <Scoreboard
            currentScoreboard={currentScoreboard}
            currentSport={currentSport}
            position={position}
            scale={scale}
            topOffset={topOffset}
            leftOffset={leftOffset}
            enableFB={enableFB}
          />
        )}
      </div>
    </div>
  );
}

Internal.propTypes = {
  Scoreboard: PropTypes.elementType,
};

export default React.memo(Internal);

const Scoreboard = ({
  index,
  leftOffset = 0,
  topOffset = 0,
  scale = 1,
  position = "fullscreen",
  currentScoreboard = 0,
  currentSport = "f",
  enableFB,
}) => {
  const monitorWrapperRef = useRef(null);
  let margins = {};
  const [heightWrapper, setHeightWrapper] = useState(1080);
  const [height, setHeight] = useState(0);
  const [widthWrapper, setWidthWrapper] = useState(1920);

  const {
    topLeftMonitorOffset = [0, 0],
    topRightMonitorOffset = [0, 0],
    bottomLeftMonitorOffset = [0, 0],
    bottomRightMonitorOffset = [0, 0],
    middleMonitorOffset = [0, 0],
    scaleMonitor = 1,
  } = (currentSport &&
    sportsSettings[currentSport]?.scoreboards[currentScoreboard]) ||
  {};

  switch (position) {
    case "top-left":
      margins = {
        top: `calc(2% - ${topOffset}px + ${
          topLeftMonitorOffset[1] * heightWrapper
        }px)`,
        left: `calc(2% + ${leftOffset}px + ${
          topLeftMonitorOffset[0] * widthWrapper
        }px)`,
      };
      break;
    case "top-right":
      margins = {
        top: `calc(2% - ${topOffset}px + ${
          topRightMonitorOffset[1] * heightWrapper
        }px)`,
        left: `calc(68% + ${leftOffset}px + ${
          topRightMonitorOffset[0] * widthWrapper
        }px)`,
      };
      break;
    case "bottom-right":
      margins = {
        top: `calc(81% - ${topOffset}px + ${
          bottomRightMonitorOffset[1] * heightWrapper
        }px)`,
        left: `calc(66% + ${leftOffset}px  + ${
          bottomRightMonitorOffset[0] * widthWrapper
        }px)`,
      };
      break;
    case "bottom-left":
      margins = {
        top: `calc(81% - ${topOffset}px + ${
          bottomLeftMonitorOffset[1] * heightWrapper
        }px)`,
        left: `calc(2% + ${leftOffset}px + ${
          bottomLeftMonitorOffset[0] * widthWrapper
        }px)`,
      };
      break;
    case "fullscreen":
      margins = {
        position: "default",
        marginLeft: "auto",
        marginRight: "auto",
      };
      break;
    case "bottom-middle":
      margins = {
        top: `calc(75% - ${topOffset}px + ${
          bottomLeftMonitorOffset[1] * heightWrapper
        }px)`,
        marginLeft: "auto",
        marginRight: "auto",
      };
      break;
    case "middle":
      margins = {
        top: `calc(33% - ${topOffset}px + ${
          middleMonitorOffset[1] * heightWrapper
        }px)`,
        left: `calc(50% + ${middleMonitorOffset[0] * widthWrapper}px)`,
        marginLeft: "auto",
        marginRight: "auto",
      };
      break;
  }

  const ScoreboardComponent =
    currentSport &&
    sportsSettings[currentSport]?.scoreboards[currentScoreboard]?.sb;

  useEffect(() => {
    if (monitorWrapperRef.current) {
      setWidthWrapper(monitorWrapperRef.current.offsetWidth);
      setHeightWrapper(monitorWrapperRef.current.offsetHeight);
    }

    const handleResize = () => {
      if (monitorWrapperRef.current) {
        setWidthWrapper(monitorWrapperRef.current.offsetWidth);
        setHeightWrapper(monitorWrapperRef.current.offsetHeight);
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      id="monitor-wrapper"
      ref={monitorWrapperRef}
      style={{
        height: "100%",
        margin: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        key={index}
        style={{
          width: `${
            position === "fullscreen" ? 100 : 30 * scale * scaleMonitor
          }%`,
          padding: "2%",
          position: "absolute",
          // backgroundColor: "blue",
          ...margins,
          // marginLeft: `calc(2% - ${leftOffset}px)`,
          // marginTop: `calc(2% - ${topOffset}px)`,
        }}
      >
        <SportsWrapper
          currentSport={currentSport}
          currentScoreboard={currentScoreboard}
          enableFB={enableFB}
        >
          {enableFB ? <ScoreboardComponent /> : null}
        </SportsWrapper>
      </div>
    </div>
  );
};

const SportsWrapper = ({
  children,
  currentSport,
  currentScoreboard,
  enableFB,
}) => {
  const SpecificSportController = useMemo(() => {
    if (currentSport === "r" || currentSport === "f" || currentSport === "s")
      return (
        <>
          <Timer />
          {children}
        </>
      );

    if (currentSport === "t") return <Tennis isController={false} />;

    if (currentSport === "d") return <DartsProvider>{children}</DartsProvider>;

    if (currentSport === "p") return <PoolProvider>{children}</PoolProvider>;

    if (currentSport === "bb") {
      return (
        <BasketballProvider>
          <TimerRemote />
          {children}
        </BasketballProvider>
      );
    }
  }, [currentSport, currentScoreboard, enableFB]);

  return SpecificSportController;
};
