import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useScoreboardContext } from "../../../../contexts/ScoreboardContext";
import { AiOutlineCopy } from "react-icons/ai";
import PropTypes from "prop-types";
import useTranslations from "../../../../contexts/useTranslations";
import { analytics, db } from "../../../../firebase";
import { logEvent } from "firebase/analytics";
import { ref, update } from "firebase/database";

function GenerateCodeButton({ initiateDB }) {
  const t = useTranslations();
  const { currentUser, displayCode, setDisplayCode, setGeneratedCode } =
    useScoreboardContext();
  const [showCopied, setShowCopied] = useState(false);

  const updateFB = (code, updateObject) => {
    const userRef = ref(db, `customers/${currentUser?.uid}/${code}`);
    update(userRef, updateObject);
  };

  const generateCode = () => {
    let chars = "ABCDEF0123456789".split("");
    let output = "";
    let output6 = "";

    for (let i = 0; i < 12; i++) {
      let x = Math.floor(Math.random() * chars.length);
      output += chars[x];

      if (i < 6) {
        output6 += chars[x];
      }
    }

    // output = "ABCDEF";

    logEvent(analytics, "code_generated");

    setGeneratedCode(output);
    setDisplayCode(output6);
    updateFB(output6, { monitor: output });
    initiateDB(output);
  };

  const copyGenCode = () => {
    navigator.clipboard.writeText(displayCode);
    setShowCopied(true);
    const timeout = setTimeout(() => {
      setShowCopied(false);
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  };

  return (
    <div className="w-100 p-0">
      <Button
        className="btn btn-primary p-0 w-100"
        style={{ width: "100%", height: 37.5, fontSize: 15 }}
        variant="primary"
        id="generate-code-btn"
        onClick={() =>
          displayCode === t("noun:generate-code")
            ? generateCode()
            : copyGenCode()
        }
        // disabled={generatedCode.length === 6 ? true : false}
      >
        {displayCode === t("noun:generate-code")
          ? displayCode
          : displayCode.substring(0, 3) + "-" + displayCode.substring(3)}

        {showCopied ? (
          <>
            {" "}
            <AiOutlineCopy />
          </>
        ) : null}
      </Button>
    </div>
  );
}

GenerateCodeButton.propTypes = {
  initiateDB: PropTypes.func,
};

export default GenerateCodeButton;
