import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  formatScore,
  useScoreboardContext,
} from "../../../contexts/ScoreboardContext";
import MapCards from "../MapCards";
import SlideRight from "../Sliders/SlideRight";
import SlideDown from "../Sliders/SlideDown";

export const setSize = (property, size) => {
  return property.substring(0, size).toUpperCase();
};

function ScoreboardGraphic3(props) {
  const {
    currentScore,
    colors,
    setColors,
    teamA,
    teamB,
    time,
    timeIsRed,
    handleChangeColor,
    addedTime,
    currentSport,
    showTotalScore,
  } = useScoreboardContext();

  const [teamHome, setTeamHome] = useState();
  const [teamAway, setTeamAway] = useState();

  useEffect(() => {
    if (teamA) setTeamHome(setSize(teamA, 3));
  }, [teamA]);

  useEffect(() => {
    if (teamB) setTeamAway(setSize(teamB, 3));
  }, [teamB]);

  useEffect(() => {
    // let color = colors;
    setColors((color) => {
      return { ...color, c1: "red", c2: "green", c3: "white", c4: "white" };
    });
  }, []);

  return (
    <div id="container_X" style={{ zIndex: 20 }}>
      <style>
        {
          ".prefix__st1{fill:#ffffff}.prefix__st6{font-family: 'Exo 2', sans-serif}.prefix__st7{font-size:19.8695px}.cls-23{fill:#ff0;}.cls-24{fill:red;}.cls-1{fill:#08578a;}"
        }
      </style>
      <style>{"#prefix__Layer_1{z-index: 100}"}</style>
      <svg
        id="prefix__Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 441.08 110"
        xmlSpace="preserve"
        {...props}
      >
        <AddedTimeComponent addedTime={addedTime} />
        <TotalScore
          showTotalScore={showTotalScore}
          currentScore={currentScore}
        />
        {/* Final Position */}
        {/* <Slide right={true} > */}
        {/* <rect xmlns="http://www.w3.org/2000/svg" x="37.9" y="29.6" id="ET-Label" className="prefix__st1" width="31" height="30"/> */}
        {/* </Slide> */}
        {/* <rect xmlns="http://www.w3.org/2000/svg" x="68.9" y="29.6" id="ET-Label" className="prefix__st1" width="31" height="30"/> */}
        {/* <rect xmlns="http://www.w3.org/2000/svg" x="5" y="29" id="ET-Label" className="cls-1" width="33" height="31"/> */}
        <path
          id="prefix__Time"
          fill={timeIsRed ? "#b4211e" : "#010002"}
          d="M68.41 29.33h69.54v30.8H68.41z"
        />
        <path
          id="prefix__Team-A-BG"
          fill={colors.c1}
          onClick={(e) => handleChangeColor("c1", e)}
          d="M153.85 29.33h55.63v30.8h-55.63z"
        />
        <path
          id="prefix__Team-A-Score"
          fill="#010002"
          d="M209.48 29.33h55.63v30.8h-55.63z"
        />
        <path
          id="prefix__Team-B-Score"
          fill="#010001"
          d="M286.97 29.33h55.63v30.8h-55.63z"
        />
        <path
          id="prefix__Team-B-BG"
          fill={colors.c2}
          onClick={(e) => handleChangeColor("c2", e)}
          d="M342.61 29.33h55.63v30.8h-55.63z"
        />
        <text className="prefix__st1 prefix__st6 prefix__st7" x="77" y="52">
          {time}
        </text>
        {/* <text
        x="10"
        y="52.134"
        // transform="matrix(1.0262 0 0 1 6.876 52.134)"
        className="prefix__st1 prefix__st6 prefix__st7"
      >
        {"ET"}
      </text> */}
        <text
          x="182"
          y="52"
          textAnchor="middle"
          className="prefix__st6 prefix__st7"
          fill={colors.c4}
          onClick={(e) => handleChangeColor("c4", e)}
        >
          {teamHome}
        </text>
        <text
          x="237"
          y="52"
          textAnchor="middle"
          className="prefix__st1 prefix__st6 prefix__st7"
        >
          {formatScore(currentScore, currentSport, 0)}
        </text>
        <text
          x="315"
          y="52"
          textAnchor="middle"
          className="prefix__st1 prefix__st6 prefix__st7"
        >
          {formatScore(currentScore, currentSport, 2)}
        </text>
        <text
          x="370"
          y="52"
          textAnchor="middle"
          fill={colors.c3}
          onClick={(e) => handleChangeColor("c3", e)}
          className="prefix__st6 prefix__st7"
        >
          {teamAway}
        </text>
        {/* Adds Cards to page*/}
        <MapCards x={154} y={62} team={0} gap={[27, 8]} />

        <MapCards x={343} y={62} team={1} gap={[27, 8]} />
      </svg>
    </div>
  );
}

const AddedTimeComponent = ({ addedTime }) => {
  return (
    <SlideRight start={355} finish={398} show={Number(addedTime) === 0}>
      <rect x="0" y="29.4" width="43" height="30.6" fill="#fff"></rect>
      <text
        x="20"
        y="47"
        dominantBaseline="middle"
        textAnchor="middle"
        fill="#af2a2d"
        fontSize="20"
        fontFamily="'Exo 2', sans-serif"
      >
        +{addedTime}
      </text>
    </SlideRight>
  );
};

AddedTimeComponent.propTypes = {
  addedTime: PropTypes.any,
};

// eslint-disable-next-line no-unused-vars
const TotalScore = ({ currentScore, showTotalScore }) => {
  return (
    <>
      <SlideDown start={60} finish={35} show={showTotalScore}>
        <rect x="209" y="0" width="56" height="25" fill="#fff"></rect>
        <text
          x="200"
          y="16"
          fill="#000"
          fontSize="20"
          textAnchor="middle"
          alignmentBaseline="middle"
          dx="38"
          dy="-2"
        >
          {(currentScore?.[0] || 0) * 3 + (currentScore?.[1] || 0)}
        </text>

        <rect x="287" y="0" width="56" height="25" fill="#fff"></rect>
        <text
          x="278"
          y="16"
          fill="#000"
          fontSize="20"
          textAnchor="middle"
          alignmentBaseline="middle"
          dx="37.5"
          dy="-2"
        >
          {(currentScore?.[2] || 0) * 3 + (currentScore?.[3] || 0)}
        </text>
      </SlideDown>
    </>
  );
};

TotalScore.propTypes = {
  currentScore: PropTypes.array,
  showTotalScore: PropTypes.bool,
};

export default ScoreboardGraphic3;
