import React from "react";
import { TennisProvider } from "./TennisContext";
import TennisController from "./TennisController";
import TennisScoreboard from "./TennisScoreboard";
import PropTypes from "prop-types";
import { isMonitor } from "../../../contexts/ScoreboardContext";

const Tennis = ({ isController }) => {
  return (
    <TennisProvider>
      {isMonitor ? null : <TennisController isController={isController} />}
      <TennisScoreboard isController={isController} />
    </TennisProvider>
  );
};

Tennis.propTypes = {
  isController: PropTypes.bool,
};

Tennis.defaultProps = {
  isController: false,
};

export default Tennis;
