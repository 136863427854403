import React, { useState } from "react";
import { Alert, Button, Container, Form, Modal, Row } from "react-bootstrap";
import { useAuth } from "../../../contexts/AuthContext";

import { useTennisContext } from "../../../scoreboard-app/components/Tennis/TennisContext";
// import GenerateCodeButton from "./components/MSGenerateCodeButton";

function FeedbackModal() {
  const { currentUser } = useAuth();
  const { setShowModalFeedback } = useTennisContext();
  const [loading, setLoading] = useState();
  const [error, setError] = useState("");

  const setErrorTimer = (err) => {
    setError(err);

    if (err[1] === "success")
      document.getElementById("text-area-box").value = "";

    setTimeout(() => {
      setError(["", ""]);
    }, 5000);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    let str = document.getElementById("text-area-box").value;
    str = str.replace(/(?:\r\n|\r|\n)/g, "<br>");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    fetch(
      "https://europe-west2-gaa-scoreboard.cloudfunctions.net/sendMessage?email=" +
        currentUser.email +
        "&name=" +
        currentUser.displayName +
        "&message=" +
        str,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          setErrorTimer(["Message sent", "success"]);
          setLoading(false);
        } else {
          setErrorTimer([
            "There was an error sending your email, please try again later",
            "danger",
          ]);
          setLoading(false);
        }
      })
      .catch((error) => {
        setErrorTimer([error.message, "danger"]);
        setLoading(false);
      });
  };

  return (
    <div>
      <Modal.Dialog
        centered
        style={{
          top: -0,
          left: 0,
          zIndex: 400,
          position: "absolute",
          width: 1200,
          height: 150,
        }}
      >
        <Modal.Body>
          <h5 className="text-center">Feedback</h5>
          <Container>
            <Form onSubmit={(e) => handleOnSubmit(e)}>
              <Row>
                <p className="text-justify">
                  This is a new scoreboard and we would love your feedback. If
                  you have any issues or would like to add different features/
                  more control/ less control please reach out to us!
                </p>
              </Row>
              <Row>
                {error[0] && <Alert variant={error[1]}> {error[0]}</Alert>}
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  id="text-area-box"
                  className="w-100"
                  as="textarea"
                  placeholder="Leave a comment here"
                  style={{ height: "100px" }}
                />
              </Row>
              <Row>
                <Button className="mt-2" variant="success" type="submit">
                  Submit
                </Button>
              </Row>
            </Form>
          </Container>
          <Button
            disabled={loading}
            style={{ float: "right", width: "20%" }}
            className="mt-2"
            variant="secondary"
            onClick={() => setShowModalFeedback(false)}
          >
            Close
          </Button>
        </Modal.Body>
      </Modal.Dialog>
    </div>
  );
}

export default FeedbackModal;
