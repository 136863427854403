import Cookies from "universal-cookie";
// import { useState } from "react";
// import { dbfs } from "../firebase";
// import { collection, doc, getDocs, onSnapshot } from "firebase/firestore";
import {
  productName,
  // stripeProducts,
} from "../scoreboard-app/components/Initialise_Settings";
// const { dbfs } = lazy(() => import("../firebase"));
// [football,soccer,rugby,darts,tennis,pool ]
// let allSportsArray = [false, false, false, false, false, false];
// creates any cookie
export const createCookie = (cookieName, value, path, expiresInMonths) => {
  const cookie = new Cookies();
  let date = new Date();
  if (
    cookie.set(cookieName, value, {
      path: path,
      expires: new Date(date.setMonth(date.getMonth() + expiresInMonths)),
    })
  )
    return true;
  else return false;
};

// checks any cookie
export const verifyCookie = async (cookieName) => {
  const cookie = new Cookies();
  const cookieStatus = cookie.get(cookieName);

  if (cookieStatus) {
    return cookieStatus;
  } else {
    return false;
  }
};

export const currency_symbols = {
  usd: "$", // US Dollar
  eur: "€", // Euro
  crc: "₡", // Costa Rican Colón
  gbp: "£", // British Pound Sterling
  ils: "₪", // Israeli New Sheqel
  inr: "₹", // Indian Rupee
  jpy: "¥", // Japanese Yen
  krw: "₩", // South Korean Won
  ngn: "₦", // Nigerian Naira
  php: "₱", // Philippine Peso
  pln: "zł", // Polish Zloty
  pyg: "₲", // Paraguayan Guarani
  thb: "฿", // Thai Baht
  uah: "₴", // Ukrainian Hryvnia
  vnd: "₫", // Vietnamese Dong
};

export const formatSubscriptionData = (data) => {
  return data?.data?.map((subscription) => {
    const {
      Status,
      ProductId,
      Payment,
      SubscriptionId,
      CancelAtEnd,
      CancelAt,
      Recurring,
      Currency,
    } = subscription;

    const payment = `${currency_symbols[Currency]}${(Payment / 100).toFixed(
      2
    )}/ ${Recurring?.interval}`;

    return {
      Name: productName[ProductId],
      Status: CancelAtEnd ? "cancelled" : Status,
      Payment: payment,
      SubscriptionId: SubscriptionId,
      CancelAt: CancelAt,
    };
  });
};

export const parseTime = (time, setMinutes, setSeconds) => {
  let splitTime = time.split(/[.:]/);
  let min = parseInt(splitTime[0]);
  let sec = 0;

  if (splitTime.length > 1) sec = parseInt(splitTime[1]);
  if (sec >= 60) sec = 0;

  if (!isNaN(min) && !isNaN(sec) && min >= 0 && sec >= 0) {
    setSeconds(sec);
    setMinutes(min);
  } else {
    setSeconds(0);
    setMinutes(0);
  }

  return [min, sec];
};

export function setCookie(name, value) {
  // Calculate the expiration date 30 years from now
  var expirationDate = new Date();
  expirationDate.setFullYear(expirationDate.getFullYear() + 30);

  // Convert the expiration date to the required format for setting a cookie
  var expires = expirationDate.toUTCString();

  // Set the cookie
  document.cookie = name + "=" + value + "; expires=" + expires + "; path=/";
}

export function getCookie(name) {
  // Split the document.cookie string into individual cookies
  var cookies = document.cookie.split(";");

  // Loop through each cookie to find the one with the specified name
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    // Remove any leading or trailing spaces
    cookie = cookie.trim();
    // Check if this cookie is the one we're looking for
    if (cookie.indexOf(name + "=") === 0) {
      // If found, return the value of the cookie
      return cookie.substring(name.length + 1);
    }
  }
  // If cookie with the specified name is not found, return null
  return null;
}

// export function App_functions() {
//   const [display, setDisplay] = useState("none");
//   const [userInfo, setUserInfo] = useState(null);
//   const [uid, setUID] = useState(null);
//   // const [loadingSubscription, setLoadingSubscription] = useState(true)
//   const [subscription, setSubscription] = useState(null);
//   const [subscriptionIncludes, setSubscriptionIncludes] = useState(null);

//   // checks to see if user accepted cookie
//   const checkCookie = async () => {
//     const cookie = new Cookies();
//     const cookieStatus = cookie.get("cookieAccept");

//     if (cookieStatus) {
//       setDisplay("none");
//     } else {
//       setDisplay("block");
//     }
//   };
//   // handler for accepting cookie;
//   // creates 30 day cookie
//   const acceptCookie = (e) => {
//     e.preventDefault();
//     const cookies = new Cookies();
//     let date = new Date();
//     cookies.set("cookieAccept", true, {
//       path: "/",
//       expires: new Date(date.setMonth(date.getMonth() + 1)),
//     });
//     setDisplay("none");
//   };

//   // Gets user details such as subscription details, number of users alowed in account, and the sport(s)
//   // which the user is subscribed to.
//   const setUserInfoApp = async () => {
//     try {
//       // checks that firebase db exists
//       if (dbfs != null || dbfs != undefined) {
//         // if user is logged in
//         if (uid) {
//           console.log(uid.uid);
//           const customerSnap = onSnapshot(
//             doc(dbfs, "customers/" + uid.uid),
//             (doc) => {
//               if (doc.exists()) setUserInfo(doc.data());
//             }
//           );

//           () => customerSnap();

//           const snapshot = await getDocs(
//             collection(dbfs, "customers", uid.uid, "subscriptions")
//           );

//           snapshot.forEach((doc) => {
//             // doc.data() is never undefined for query doc snapshots
//             console.log(doc.data());
//           });

//           // if no details in subscription set subscription => false
//           if (snapshot.size === 0) {
//             setSubscription(false);
//             // setLoadingSubscription(false);
//           }
//           let flag = false;

//           var itemsProcessed = 0;
//           // loops through all subscriptions and checks for an active or active trial
//           // subscription
//           snapshot.forEach(async (doc, array) => {
//             if (doc.exists) {
//               let document = await doc.data();

//               let status = await document.status;

//               if (status === "active" || status == "trialing") {
//                 setSubscription(true);
//                 setSubscriptionIncludes((prod) => {
//                   let a1 = getProductType(document.product.path);
//                   let a2 = prod || allSportsArray;
//                   let result = a1.map((b, i) => b || a2[i]);
//                   return result;
//                 });
//                 flag = true;
//               }

//               if (itemsProcessed === array && flag === false) {
//                 setSubscription(false);
//               }
//             }
//           });
//         }
//       }
//     } catch (error) {
//       // console.log("logged out");
//     }
//   };

//   const getProductType = (product_id) => {
//     // gaa, soccer, rugby, darts
//     let products = allSportsArray;

//     if (product_id) {
//       switch (product_id.replace("products/", "")) {
//         case stripeProducts.f.product_month: // gaa_month -- gaa_year
//           products = [true, false, false, false, false];
//           break;
//         case stripeProducts.f.product_year: // gaa_month -- gaa_year
//           products = [true, false, false, false, false];
//           break;
//         case stripeProducts.s.product_month: // soccer_month -- soccer_year
//           products = [false, true, false, false, false];
//           break;
//         case stripeProducts.s.product_year: // soccer_month -- soccer_year
//           products = [false, true, false, false, false];
//           break;
//         case stripeProducts.fsr.product_month: // soccer -- gaa- rugby
//           products = [true, true, true, false];
//           break;
//         case stripeProducts.fsr.product_year:
//           products = [true, true, true, false];
//           break;
//         case stripeProducts.r.product_month: // rugby_month
//           products = [false, false, true, false, false];
//           break;
//         case stripeProducts.r.product_year: // rugby_year
//           products = [false, false, true, false, false];
//           break;
//         case stripeProducts.d.product_year:
//           products = [false, false, false, true, false];
//           break;
//         case stripeProducts.d.product_month:
//           products = [false, false, false, true, false];
//           break;
//         case stripeProducts.t.product_year:
//           products = [false, false, false, false, true];
//           break;
//         case stripeProducts.t.product_month:
//           products = [false, false, false, false, true];
//           break;
//         // case stripeProducts.t.product_year:
//         //   products = [false, false, false, false, true];
//         //   break;
//         // case stripeProducts.t.product_month:
//         //   products = [false, false, false, false, true];
//         //   break;
//         case stripeProducts.p.product_year:
//           products[5] = true;
//           break;
//         case stripeProducts.p.product_month:
//           console.log(
//             "stripeProducts.p.product_month",
//             stripeProducts.p.product_month
//           );
//           products[5] = true;
//           break;
//       }
//     }
//     return products;
//   };

//   const values = {
//     display,
//     checkCookie,
//     acceptCookie,
//     setUserInfoApp,
//     userInfo,
//     setUserInfo,
//     uid,
//     setUID,
//     subscription,
//     subscriptionIncludes,
//     getProductType,
//   };

//   return values;
// }
