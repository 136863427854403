import React, { useEffect, useState } from "react";
import "../../assets/fonts/font.css";
import PropTypes from "prop-types";
import { setSize } from "./ScoreboardGraphic3";
import {
  formatScore,
  useScoreboardContext,
} from "../../../contexts/ScoreboardContext";
import MapCards from "../MapCards";

// cork city
function ScoreboardGraphic6(props) {
  const [namehome, setTeamHome] = useState();
  const [nameaway, setTeamAway] = useState();

  const {
    colors,
    // timeIsRed,
    handleChangeColor,
    teamA,
    teamB,
    currentSport,
    time,
    currentScore,
    crest,
  } = useScoreboardContext();

  useEffect(() => {
    setTeamHome(setSize(teamA, 4));
    setTeamAway(setSize(teamB, 4));
  }, [teamA, teamB, props.currentUser]);

  var lightenColor = (color, percent) => {
    var num = parseInt(color.replace("#", ""), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      B = ((num >> 8) & 0x00ff) + amt,
      G = (num & 0x0000ff) + amt;

    return (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
      (G < 255 ? (G < 1 ? 0 : G) : 255)
    )
      .toString(16)
      .slice(1);
  };

  return (
    <div id="container_X" style={{ position: "relative" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 729.68 300"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="147.24"
            x2="159.61"
            y1="446.993"
            y2="446.993"
            gradientTransform="translate(-22.26 -414.403)"
            gradientUnits="userSpaceOnUse"
          >
            <stop
              offset="0"
              stopColor={"#" + lightenColor(colors.c1, 10)}
            ></stop>
            <stop offset="1" stopColor={colors.c1}></stop>
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="717.31"
            x2="729.68"
            y1="32.59"
            y2="32.59"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor={colors.c2}></stop>
            <stop
              offset="1"
              stopColor={"#" + lightenColor(colors.c2, 10)}
            ></stop>
          </linearGradient>
          <linearGradient
            id="linear-gradient-3"
            x1="159.6"
            x2="739.57"
            y1="446.993"
            y2="446.993"
            gradientTransform="translate(-22.26 -414.403)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#05295d"></stop>
            <stop offset="0.405" stopColor="#5b84c1"></stop>
            <stop offset="0.634" stopColor="#6288c5"></stop>
            <stop offset="0.793" stopColor="#658ac7"></stop>
            <stop offset="1" stopColor="#004b8b"></stop>
            <stop offset="1" stopColor="#5781bf"></stop>
          </linearGradient>
          <linearGradient
            id="linear-gradient-4"
            x1="449.585"
            x2="449.585"
            y1="471.083"
            y2="421.803"
            gradientTransform="translate(-22.26 -414.403)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#4f4f4f"></stop>
            <stop offset="0.054" stopColor="#626262"></stop>
            <stop offset="0.162" stopColor="#939393"></stop>
            <stop offset="0.258" stopColor="#c4c4c4"></stop>
            <stop offset="0.479" stopColor="#fff"></stop>
            <stop offset="0.591" stopColor="#fff"></stop>
            <stop offset="0.682" stopColor="#fcfcfc"></stop>
            <stop offset="0.742" stopColor="#f4f4f4"></stop>
            <stop offset="0.774" stopColor="#ebebeb"></stop>
            <stop offset="0.836" stopColor="#e8e8e8"></stop>
            <stop offset="0.878" stopColor="#dfdfdf"></stop>
            <stop offset="0.914" stopColor="#d0d0d0"></stop>
            <stop offset="0.947" stopColor="#bababa"></stop>
            <stop offset="0.978" stopColor="#9f9f9f"></stop>
            <stop offset="1" stopColor="#858585"></stop>
          </linearGradient>
          <radialGradient
            id="radial-gradient"
            cx="776.542"
            cy="445.893"
            r="62.367"
            gradientTransform="matrix(.9754 0 0 1 -330.11 -414.403)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#658ac7"></stop>
            <stop offset="0.55" stopColor="#004b8b"></stop>
            <stop offset="1" stopColor="#05295d"></stop>
          </radialGradient>
        </defs>
        <path
          fill="#fff"
          stroke="#fff"
          strokeMiterlimit="10"
          d="M0.5 5.44H117.9V59.839999999999996H0.5z"
        ></path>
        <path
          fill="url(#linear-gradient)"
          d="M124.98 5.35H137.35V59.83H124.98z"
          onClick={(e) => handleChangeColor("c1", e)}
        ></path>
        <path
          fill="url(#linear-gradient-2)"
          d="M717.31 5.35H729.68V59.83H717.31z"
          onClick={(e) => handleChangeColor("c2", e)}
        ></path>
        <path
          fill="url(#linear-gradient-3)"
          d="M137.34 5.35H717.3100000000001V59.83H137.34z"
        ></path>
        <path
          fill="url(#linear-gradient-4)"
          d="M155.77 7.4L163.06 56.68 691.6 56.68 698.88 7.4 155.77 7.4z"
        ></path>
        <path
          fill="url(#radial-gradient)"
          d="M346.97 0L355.45 62.98 499.21 62.98 507.69 0 346.97 0z"
        ></path>
        <text
          fontFamily="Assistant-Regular, Assistant"
          fontSize="40"
          transform="translate(276 44.642)"
          textAnchor="middle"
        >
          {namehome}
        </text>
        <text
          fontFamily="Assistant-Regular, Assistant"
          fontSize="40"
          transform="translate(570 44.642)"
          textAnchor="middle"
        >
          {nameaway}
        </text>
        <text
          fontFamily="Assistant-Regular, Assistant"
          fontSize="40"
          transform="translate(15.821 45.642)"
        >
          {time}
        </text>
        <text
          fill="#fff"
          fontFamily="Assistant-SemiBold, Assistant"
          fontSize="40"
          fontWeight="600"
          textAnchor="middle"
          transform="translate(387 44.677)"
        >
          {formatScore(currentScore, currentSport, 0)}
        </text>
        <text
          fill="#fff"
          fontFamily="Assistant-SemiBold, Assistant"
          fontSize="40"
          fontWeight="600"
          textAnchor="middle"
          transform="translate(464,44.6768)"
        >
          {formatScore(currentScore, currentSport, 2)}
        </text>
        <text
          fill="#fff"
          fontFamily="Assistant-SemiBold, Assistant"
          fontSize="40"
          fontWeight="600"
          transform="translate(420.6639 41.642)"
        >
          -
        </text>

        {/* Adds Cards to page*/}
        <MapCards x={180} y={62} team={0} gap={[27, 8]} multiplier={2} />

        <MapCards x={560} y={62} team={1} gap={[27, 8]} multiplier={2} />

        {crest[0] !== "" ? (
          <image
            href={crest[0]} // URL for the image (modern usage)
            x="170px"
            y="9.5px"
            width="44"
            height="44"
          />
        ) : (
          ""
        )}

        {crest[1] !== "" ? (
          <image
            href={crest[1]} // URL for the image (modern usage)
            x="640px"
            y="9.5px"
            width="44px"
            height="44px"
          />
        ) : (
          ""
        )}
      </svg>
    </div>
  );
}

ScoreboardGraphic6.propTypes = {
  color: PropTypes.string,
  namehome: PropTypes.string,
  nameaway: PropTypes.string,
  crest1: PropTypes.string,
  crest2: PropTypes.string,
  time: PropTypes.string,
  scorehome: PropTypes.string,
  scoreaway: PropTypes.string,
  sport: PropTypes.string,
  currentUser: PropTypes.object,
};

export default ScoreboardGraphic6;
