import React from "react";
import PropTypes from "prop-types";
import { ButtonGroup, ToggleButton } from "react-bootstrap";

function ToggleButtons({ radios, variant, setVariant }) {
  {
    console.log({ variant });
  }
  return (
    <ButtonGroup>
      {radios?.map((radio, idx) => (
        <ToggleButton
          key={idx}
          className="no_radio_button"
          id={`radio-${idx}`}
          type="radio"
          variant={idx === variant ? "primary" : "outline-primary"}
          name="radio"
          value={radio.value}
          checked={variant === radio.value}
          onChange={(e) => setVariant(e.currentTarget.value)}
        >
          {radio.name}
        </ToggleButton>
      ))}
    </ButtonGroup>
  );
}

ToggleButtons.propTypes = {
  radios: PropTypes.array,
  variant: PropTypes.string,
  setVariant: PropTypes.func,
};

export default ToggleButtons;
