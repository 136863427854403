import { useCallback } from "react";
import { useIntl } from "react-intl";

const useTranslations = () => {
  const { formatMessage } = useIntl();

  const t = useCallback(
    (key, values) => {
      try {
        // Attempt to format the message
        return formatMessage({ id: key, defaultMessage: key }, values);
      } catch (error) {
        // Handle errors gracefully
        console.error(`Translation error for key "${key}":`, error);

        // Return a fallback message in case of an error
        return key;
      }
    },
    [formatMessage]
  );

  return t;
};

export default useTranslations;
