const LOCALES = [
  { locale: "en", match: /en/ },
  { locale: "es", match: /es/ },
  { locale: "fr", match: /fr/ },
  { locale: "pt", match: /pt/ },
  { locale: "ja", match: /ja/ },
  { locale: "kr", match: /kr/ },
];

export default LOCALES;
