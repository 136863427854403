import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Form, Row } from "react-bootstrap";
import useTranslations from "../../contexts/useTranslations";
import { analytics } from "../../firebase";
import { logEvent } from "firebase/analytics";

function TennisModalSection({
  gameModeLegs,
  setGameModeLegs,
  firstToGames,
  setFirstToGames,
  tiebreakLegs,
  setTiebreakLegs,
}) {
  const t = useTranslations();

  useEffect(() => {
    logEvent(analytics, "settings_modal_opened", {
      sport: "tennis", //
    });
  }, []);

  return (
    <>
      <Row>
        <Col>
          <Form.Check
            checked={gameModeLegs === "a"}
            onChange={() => setGameModeLegs("a")}
            type="switch"
            id="custom-switch"
            label={t("noun:advantage-game")}
          />
        </Col>
        <Col>
          <Form.Check
            checked={gameModeLegs === "t"}
            onChange={() => setGameModeLegs("t")}
            type="switch"
            label={t("noun:tiebreak-game")}
            id="disabled-custom-switch"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Text>
              {gameModeLegs === "t"
                ? t("noun:win-tiebreak-set") + " "
                : t("noun:win-set-after") +
                  " " +
                  firstToGames +
                  " " +
                  t("noun:games")}
            </Form.Text>
            <Form.Control
              type="name"
              value={gameModeLegs === "t" ? tiebreakLegs : firstToGames}
              onChange={(e) =>
                gameModeLegs === "t"
                  ? setTiebreakLegs(e.target.value)
                  : setFirstToGames(e.target.value)
              }
              // onBlur={(e) => updateFB("teamA", e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col>
          {gameModeLegs === "t" ? (
            <Form.Group>
              <Form.Text>{t("noun:tiebreak-points-game")}</Form.Text>
              <Form.Control
                type="name"
                value={firstToGames}
                onChange={(e) => setFirstToGames(e.target.value)}
                // onBlur={(e) => updateFB("teamA", e.target.value)}
              />
            </Form.Group>
          ) : null}
        </Col>
      </Row>
    </>
  );
}

TennisModalSection.propTypes = {
  gameModeLegs: PropTypes.string,
  setGameModeLegs: PropTypes.func,
  firstToGames: PropTypes.number,
  setFirstToGames: PropTypes.func,
  tiebreakLegs: PropTypes.number,
  setTiebreakLegs: PropTypes.func,
};

export default TennisModalSection;
