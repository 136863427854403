import availableLocales from "../config/translations/locales";
import translations from "../config/translations";

const DEFAULT_LOCALE = "en";

export const findMatchingLocale = async (language = undefined) => {
  let deviceLanguage = "en"; // TODO: add device language
  // const deviceLanguage = navigator.language || navigator.userLanguage;

  const matchingLocale = availableLocales.find(({ match }) =>
    match.test(language ? language : deviceLanguage)
  );

  return matchingLocale ? matchingLocale?.locale : DEFAULT_LOCALE;
};

export const getMessagesWithLocale = async (locale) => {
  const { localMessages } =
    translations[locale] || translations[DEFAULT_LOCALE];

  // if (process.env.NODE_ENV === 'development') {
  return localMessages;
  // }
};
