/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
  formatScore,
  isMonitor,
  useScoreboardContext,
} from "../../../contexts/ScoreboardContext";
import "../../assets/fonts/font.css";
import MapCards from "../MapCards";
import { setSize } from "./ScoreboardGraphic3";

function ScoreboardGraphic5() {
  const {
    colors,
    setColors,
    timeIsRed,
    handleChangeColor,
    teamA,
    teamB,
    currentSport,
    time,
    currentScore,
    enableFB,
    cards,
  } = useScoreboardContext();

  const [namehome, setTeamHome] = useState();
  const [nameaway, setTeamAway] = useState();

  useEffect(() => {
    setTeamAway(setSize(teamB, 3));
    setTeamHome(setSize(teamA, 3));
  }, [teamA, teamB]);

  useEffect(() => {
    if (!isMonitor)
      setColors((prev) => ({
        ...prev,
        c1: "#468300",
        c2: "#EE0000",
        c3: "#fff",
        c4: "#fff",
      }));
  }, []);

  return (
    <div id="container_X" style={{ position: "relative", height: 300 }}>
      <style>
        {
          ".cls-1{isolation:isolate;}.cls-2{opacity:0.73;mix-blend-mode:multiply;}.cls-5{opacity:0.18;}.cls-6{fill:#fff;}.cls-7{font-size:30.2467px;font-family:Dubai-Medium, Dubai;font-weight:500;textWhite{fill:#fff!important}}"
        }
      </style>
      {/* Adds Cards to page*/}
      <svg
        className="ml-10"
        xmlns="http://www.w3.org/2000/svg"
        width="70%"
        height="200px"
        viewBox="0 0 338 58"
      >
        <defs></defs>
        <g className="cls-1">
          <g id="Layer_1" data-name="Layer 1">
            <image
              className="cls-2"
              width="338"
              height="58"
              xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAVIAAAA6CAYAAADiBdFsAAAACXBIWXMAAAsSAAALEgHS3X78AAADH0lEQVR4Xu3bzW4bNxRA4UPJlYHEQdMs2iBdFX3/1zLSAHEi/wFRpGEXl5TGIxkoepc8H0CMRErbgzvyuNRakST9f1eXNksppb+8dC5JA6oA9cL0WeZ7LaB9rdrq7yVpRLWtqa1K9PQYz2NIW0RXwJqYVDftuuYUVEkaSQ/oAdgDu3Y9AFOP6fzWvhDR3ABvgJt27UF1MpU0kj6J9oA+A4/tupudR0hnt/RXRDzfA78DvxFB3RCRNaSSRlGJyXNHBPQb8KWdTcChlFJqrXU+kfZp9Ab4A/gb+AR8AN5ymkolaQR9Gn0C7oBbooHzW/wJTrf2lybST8BfwEfgHRFZQyppFJUI5gPwue19JSbTe07drK/9RnpDTKIfgT+BX9v+Ckkaw0SEdNveb3nlp85lSFdt75q4nX9HRPR92zOkkkYxAT/a63uiiddEI188ybR8IH/+/OgV8AtR32sMqaSxTO26IVrYA3r2OOjF/2zi/KH8+ZKkUczb9+ojoIZRkpIMqSQlGVJJSjKkkpRkSCUpyZBKUpIhlaQkQypJSYZUkpIMqSQlGVJJSjKkkpRkSCUpyZBKUpIhlaQkQypJSYZUkpIMqSQlGVJJSjKkkpRkSCUpyZBKUpIhlaQkQypJSYZUkpIMqSQlGVJJSjKkkpRkSCUpyZBKUpIhlaQkQypJSYZUkpIMqSQlGVJJSrp6Zb+2NS2WJI1i2b/exTPLkM7juQd+AjvgRzt3gpU0iolo345o4Z6XUT2ah7RHdN++/AQ8ANt2vsGQShrHRER0S7TwiWhjD+oxpsuQHtoXH4E74HM7uydCWpCkMVSihw9EC++INu6IVp6FtN/774Fn4Dtw2862wNv2WUMqaRS9iU9ERG+JNj63/eNvpvOJdD6N/tM+8BW4IabRNYZU0jiWd+nfgC+8nEqBFtJaay2lzCdS2uvvRET7NGpIJY1ifqe+I9r4yGwirbVWgNKulFIK8cekNRHOHtB12zeikkbT/wh/4BTUfXs/nYUUjjHta8UpoEZU0qiWz9VXZtMoLEJ63IygggGVpK5CFHR5cDGkkqT/7l9J9+pSCcIdTgAAAABJRU5ErkJggg=="
            />
            <rect
              fill={colors.c1}
              onClick={(e) => handleChangeColor("c1", e)}
              x="259"
              y="6"
              width="73"
              height="46"
            />
            <rect
              fill={colors.c2}
              onClick={(e) => handleChangeColor("c2", e)}
              x="93"
              y="6"
              width="73"
              height="46"
            />
            <rect x="166" y="6" width="93" height="46" />
            <rect
              x="6"
              y="6"
              width="88"
              fill={timeIsRed ? "#ff0000" : "#000"}
              height="46"
            />
          </g>
          <g id="Layer_4" data-name="Layer 4">
            <g className="cls-5">
              <polygon
                className="cls-6"
                points="6 37 6 6 332 6 332 24.821 6 37"
              />
            </g>
          </g>
          <g id="Text">
            <text
              className="cls-7"
              fill="#fff"
              transform="translate(13.7661 38.9697)"
            >
              {time}
            </text>
            <text
              textAnchor="middle"
              fill={colors.c3}
              onClick={(e) => handleChangeColor("c3", e)}
              className="cls-7"
              transform="translate(130 38.9697)"
            >
              {namehome}
            </text>
            <text
              textAnchor="middle"
              className="cls-7"
              fill="#fff"
              transform="translate(185 38.9697)"
            >
              {formatScore(currentScore, currentSport, 0)}
            </text>
            <text
              textAnchor="middle"
              className="cls-7"
              fill="#fff"
              transform="translate(236 38.9697)"
            >
              {formatScore(currentScore, currentSport, 2)}
            </text>
            <text
              className="cls-7"
              fill="#fff"
              transform="translate(205.7661 37.9697)"
            >
              -
            </text>
            <text
              textAnchor="middle"
              fill={colors.c4}
              onClick={(e) => handleChangeColor("c4", e)}
              className="cls-7"
              transform="translate(295 38.9697)"
            >
              {nameaway}
            </text>
          </g>
          <MapCards
            x={94}
            y={55}
            multiplier={1.23}
            team={0}
            gap={[30, 8]}
            cards={cards}
          />
          <MapCards
            x={260}
            y={55}
            multiplier={1.2}
            team={1}
            gap={[30, 8]}
            cards={cards}
          />
        </g>
      </svg>
    </div>
  );
}

export default ScoreboardGraphic5;
