/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from "react";
import { useScoreboardContext } from "../../../../contexts/ScoreboardContext";
import { useBasketballContext } from "../../Basketball/BasketballContext";
import { setSize } from "../ScoreboardGraphic3";

function BasketballScoreboard3() {
  const {
    scoreA,
    scoreB,
    period,
    announcement,
    showAnnouncement,
    currentTeam,
    hasShotClock,
    shotClockTime,
    foulsACount,
    foulsBCount,
    numFoulsPerPeriod,
    showFoulsCounter,
  } = useBasketballContext();

  useEffect(() => {
    setColors((_colors) => {
      let temp = {
        ...colors,
        c1: "#ea2512",
        c2: "#3577ff",
        c3: "#fff",
        c4: "#fff",
      };
      return temp;
    });
  }, []);

  const { teamA, teamB, time, crest, colors, handleChangeColor, setColors } =
    useScoreboardContext();

  const [teamHome, setTeamHome] = useState();
  const [teamAway, setTeamAway] = useState();

  const [isExpanded, setIsExpanded] = useState(undefined);

  useEffect(() => {
    if (showAnnouncement !== undefined) toggleClip(showAnnouncement);
  }, [showAnnouncement]);

  const toggleClip = (notExpanded) => {
    setIsExpanded(notExpanded);
  };

  useEffect(() => {
    if (teamA) setTeamHome(setSize(teamA, 3));
  }, [teamA]);

  useEffect(() => {
    if (teamB) setTeamAway(setSize(teamB, 3));
  }, [teamB]);

  // Calculate the total width and spacing
  const totalWidth = 136;
  const spaceWidth = totalWidth / numFoulsPerPeriod;
  const width = spaceWidth * (3 / 4);
  const spacing = spaceWidth * (1 / 4);

  const paths = useMemo(() => {
    const path = [];

    if (!showFoulsCounter) return path;

    for (let i = 0; i < numFoulsPerPeriod; i++) {
      const translateX = i * (width + spacing);
      path.push(
        <rect
          key={i}
          x="68"
          y="125"
          width={width}
          height="5"
          fill={foulsACount > i ? "yellow" : "white"}
          transform={`translate(${translateX} 0)`}
        />
      );
    }
    return path;
  }, [numFoulsPerPeriod, width, spacing, foulsACount, showFoulsCounter]);

  const paths2 = useMemo(() => {
    const path = [];

    if (!showFoulsCounter) return path;

    for (let i = 0; i < numFoulsPerPeriod; i++) {
      const translateX = i * (width + spacing);
      path.push(
        <rect
          key={i}
          x="215"
          y="125"
          width={width}
          height="5"
          fill={foulsBCount > i ? "yellow" : "white"}
          transform={`translate(${translateX} 0)`}
        />
      );
    }
    return path;
  }, [numFoulsPerPeriod, width, spacing, foulsBCount, showFoulsCounter]);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 159.52">
      <defs>
        <style>
          {
            ".cls-1{fill:none}.cls-10,.cls-12,.cls-13,.cls-8,.cls-9{fill:#fff}.cls-14{fill:#e2e3e5}.cls-16{fill:#606060}.cls-10,.cls-12,.cls-13,.cls-17,.cls-9{isolation:isolate}.cls-12,.cls-9{font-family:Tomorrow-SemiBold,Tomorrow}.cls-9{font-size:18px}.cls-10{font-size:27px}.cls-10,.cls-17{font-family:Tomorrow-Regular,Tomorrow}.cls-20{letter-spacing:-.01em}.cls-12{font-size:30px}.cls-22{letter-spacing:-.01em}.cls-13{font-family:Tomorrow-ExtraBold,Tomorrow;font-size:35px}.cls-25{letter-spacing:0}.cls-45{font-family: Tomorrow-Regular,Tomorrow; font-size: 28px}"
          }
          {`.show-scoreboard {
          opacity: 1;
          transform: translateX(0);
          transition:  transform 1s ease-in-out;
          }

          .hide-scoreboard {
            opacity: 1;
            transform: translateX(-70%);
            transition: transform 1s ease-in-out;
            pointer-events: none;
          } 
          `}
        </style>
      </defs>
      <g id="Layer_1">
        <g className={isExpanded ? "show-scoreboard" : "hide-scoreboard"}>
          <path d="M790 0h2v92h-2z" />
          <path d="M416 0h374v92H416z" className="cls-14" />
          <text
            className="cls-45"
            transform="translate(603 55)"
            textAnchor="middle"
          >
            {announcement +
              " " +
              (currentTeam === 0 ? teamA : teamB).toUpperCase()}
          </text>
        </g>
        <path d="M414 0h2v92h-2z" className="cls-8" />

        <path d="M354 32h60v60h-60z" className="cls-14" />
        <path d="M354 32h60v60h-60z" className="cls-1" />
        <path d="M0 32h60v60H0V32Z" className="cls-14" />
        <path d="M0 32h60v60H0z" className="cls-1" />
        <path d="M62 32h144v42H62zM208 32h144v42H208z" />
        <path
          d="M62 74h144v46H62z"
          fill={colors.c1}
          onClick={(e) => handleChangeColor("c1", e)}
        />
        <text
          className="cls-12"
          transform="translate(133.5 108)"
          textAnchor="middle"
          onClick={(e) => handleChangeColor("c3", e)}
          style={{
            fill: colors.c3,
          }}
        >
          {scoreA}
        </text>
        <path
          d="M208 74h144v46H208z"
          fill={colors.c2}
          onClick={(e) => handleChangeColor("c2", e)}
        />
        <path d="M62 120h144v15H62z" />

        <path d="M208 120h144v15H208z" />

        <path d="M62 135h144v2H62zM206 135h2v24h-2z" className="cls-8" />

        <path
          d="M62 135h144v2H62zM206z"
          className="cls-8"
          transform={`translate(146 0)`}
        />

        <path d="M302 0h112v30H302zM112 0h190v30H112zM0 0h112v30H0z" />
        <path
          d="M0 30h414v2H0zM206 32h2v103h-2zM60 32h2v60h-2zM352 32h2v60h-2z"
          className="cls-8"
        />
      </g>
      <g id="Layer_2">
        <text
          className="cls-13"
          transform="translate(135 65.5)"
          textAnchor="middle"
        >
          <tspan x={0} y={0}>
            {teamHome}
          </tspan>
        </text>
        <text
          className="cls-13"
          transform="translate(280 66.5)"
          textAnchor="middle"
        >
          <tspan x={0} y={0}>
            {teamAway}
          </tspan>
        </text>
        <text
          className="cls-12"
          transform="translate(279.7 108)"
          textAnchor="middle"
          onClick={(e) => handleChangeColor("c4", e)}
          style={{
            fill: colors.c4,
          }}
        >
          <tspan x={0} y={0}>
            {scoreB}
          </tspan>
        </text>
        <g id="Layer_3">{paths}</g>
        <g id="Layer_3"> {paths2}</g>
        <path d="M100 137h106v22H100zM62 137l38 22v-22H62Z" />
        {foulsBCount >= numFoulsPerPeriod ? (
          <g>
            <text className="cls-9" transform="translate(115 154)">
              <tspan x={0} y={0} className="cls-22">
                {"BONUS"}
              </tspan>
            </text>
          </g>
        ) : null}
        <path d="M208 137h106v22H208zM352 137l-38 22v-22h38Z" />
        {foulsACount >= numFoulsPerPeriod ? (
          <g>
            <text className="cls-9" transform="translate(233 154)">
              <tspan x={0} y={0} className="cls-22">
                {"BONUS"}
              </tspan>
            </text>
          </g>
        ) : null}
        {hasShotClock ? (
          <text className="cls-10" transform="translate(340.89 24.3)">
            <tspan x={0} y={0}>
              {shotClockTime}
            </tspan>
          </text>
        ) : null}
        <text
          style={{
            fontFamily: "Wallpoet,Wallpoet",
            fontSize: 28,
            isolation: "isolate",
            fill: "#fff",
          }}
          transform="translate(185 22.54)"
          textAnchor="middle"
        >
          <tspan x={22.15} y={0}>
            {time}
          </tspan>
        </text>
        <text className="cls-10" transform="translate(38.32 24.3)">
          <tspan x={0} y={0}>
            {period}
          </tspan>
        </text>
        <g id="xs">
          <image xlinkHref={crest[0]} x="3" y="35" width="54" height="54" />
          <image xlinkHref={crest[1]} x="357" y="35" width="54" height="54" />
        </g>
      </g>
    </svg>
  );
}

export default BasketballScoreboard3;
