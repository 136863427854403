import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { Helmet } from "react-helmet";
import useTranslations from "../contexts/useTranslations";

function FullPageLoader() {
  const t = useTranslations();

  return (
    <div
      className="fp-container"
      style={{ width: "100%", height: "100%", position: "fixed" }}
    >
      <Helmet>
        <title>{t("pricing:obscoreboard-pricing")}</title>
        <meta name="description" content={t("pricing:latest-pricing")} />
      </Helmet>
      <div className="fp-loader">
        <Loader type="Bars" color="#104b68" height={100} width={100} />
      </div>
    </div>
  );
}

export default FullPageLoader;
