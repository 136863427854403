import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import UpDownDisplay from "./UpDownDisplay";
import GameControls from "./GameControls";

function AllScoreControls({
  currentPlayer,
  currentScore,
  sets,
  games,
  setCurrentScore,
  setCurrentGames,
  setCurrentSets,
  setShowNotice,
  scoreIsTiebreaker,
}) {
  return (
    <Col className="ml-1">
      <Row className="d-flex justify-content-center border p-1">
        <Col className="pb-3">
          <Row className="d-flex justify-content-center">Sets</Row>
          <UpDownDisplay
            displayValue={sets}
            buttonBackground={"success"}
            handleUp={() => {
              setCurrentScore([0, 0]);
              setCurrentGames([0, 0]);
              setShowNotice(false);
              setCurrentSets((_sets) => {
                let _temp = [..._sets];
                _temp[currentPlayer] += 1;
                return _temp;
              });
            }}
            handleDown={() =>
              setCurrentSets((_sets) => {
                let _temp = [..._sets];
                _temp[currentPlayer] -= 1;
                return _temp;
              })
            }
          />
        </Col>
        <Col className="ml-1 pb-3">
          <Row className="d-flex justify-content-center">Games</Row>
          <UpDownDisplay
            displayValue={games}
            buttonBackground={"warning"}
            handleUp={() => {
              setCurrentScore([0, 0]);
              setShowNotice(false);
              setCurrentGames((_games) => {
                let _temp = [..._games];
                _temp[currentPlayer] += 1;
                return _temp;
              });
            }}
            handleDown={() =>
              setCurrentGames((_games) => {
                let _temp = [..._games];
                _temp[currentPlayer] -= 1;
                return _temp;
              })
            }
          />
        </Col>
        <Col className="ml-1 pb-3">
          <Row className="d-flex justify-content-center">Points</Row>
          {!scoreIsTiebreaker ? (
            <GameControls
              currentScore={currentScore[currentPlayer]}
              handleChangeScore={(innerScore) => {
                setCurrentScore((cs) => {
                  let otherPlayer = currentPlayer === 0 ? 1 : 0;
                  let _temp = [...cs];
                  if (innerScore === "AD" && currentScore[otherPlayer] === 40) {
                    _temp[otherPlayer] = "";
                  }

                  if (innerScore === 40 && currentScore[otherPlayer] === "AD") {
                    _temp[otherPlayer] = 40;
                  }

                  if (innerScore === 40 && currentScore[otherPlayer] === "") {
                    _temp[otherPlayer] = 40;
                  }
                  _temp[currentPlayer] = innerScore;

                  return _temp;
                });
              }}
            />
          ) : (
            <UpDownDisplay
              displayValue={currentScore[currentPlayer]}
              buttonBackground={"warning"}
              handleUp={() => {
                setCurrentScore((cs) => {
                  let tmp = [...cs];
                  tmp[currentPlayer]++;
                  return tmp;
                });
              }}
              handleDown={() =>
                setCurrentScore((cs) => {
                  let tmp = [...cs];
                  tmp[currentPlayer]--;
                  return tmp;
                })
              }
            />
          )}
        </Col>
      </Row>
    </Col>
  );
}

AllScoreControls.propTypes = {
  currentPlayer: PropTypes.number,
  sets: PropTypes.number,
  games: PropTypes.number,
  currentScore: PropTypes.array,
  setCurrentScore: PropTypes.func,
  setCurrentGames: PropTypes.func,
  setCurrentSets: PropTypes.func,
  setShowNotice: PropTypes.func,
  scoreIsTiebreaker: PropTypes.bool,
};

export default AllScoreControls;
