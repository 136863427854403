/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo } from "react";
import { useScoreboardContext } from "../../../contexts/ScoreboardContext";
import { useDartsContext } from "../../hooks/DartsContext";
import { useSubscription } from "../../../contexts/SubscriptionContext";
import useTranslations from "../../../contexts/useTranslations";
import Finishes from "../../../config/darts_finishes.json";

function searchScore(scoreToFind, data) {
  // Filter the array of objects to find the object with the desired score
  const result = data.find((item) => item.score === scoreToFind);

  // If the result is found, return the object, otherwise return null
  return result ? result.finish : [];
}

function DartsScoreboardSets() {
  const t = useTranslations();
  const { savedSettings } = useSubscription();
  const {
    legs,
    sets,
    currentScore,
    currentPlayer,
    firstPlayer,
    isFirstTo,
    firstTo,
    competitionName,
    delayedPlayer,
    showFinish,
    isRemote,
  } = useDartsContext();

  const { teamA, teamB, handleChangeColor, colors, setColors } =
    useScoreboardContext();

  useEffect(() => {
    if (savedSettings?.colors) {
      setColors({ ...savedSettings.colors });
    } else {
      setColors((cols) => {
        return {
          ...cols,
          c1: "#8f0102",
          c2: "#fff",
          c3: "#1b9714",
          c4: "#000",
        };
      });
    }
  }, [savedSettings]);

  return (
    <div style={{ marginTop: 10 }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 355.88 74"
      >
        <Finish
          colors={colors}
          currentPlayer={delayedPlayer}
          showFinish={showFinish}
          finish={currentScore[delayedPlayer - 1]}
          isRemote={isRemote}
        />
        <path
          d="M110 0h220.88v16H110z"
          fill={colors.c4}
          onClick={(e) => handleChangeColor("c4", e, [0, 0])}
        />
        <path
          d="M110 16h123v21H110zM110 37h123v21H110z"
          fill={colors.c2}
          onClick={(e) => handleChangeColor("c2", e, [0, 0])}
        />
        <path
          d="M233 16h97.88v21H233zM233 37h97.88v21H233z"
          onClick={(e) => handleChangeColor("c3", e, [0, 0])}
          fill={colors.c3}
        />
        <path
          d="M110 58h220.88v16H110z"
          fill={colors.c4}
          onClick={(e) => handleChangeColor("c4", e, [0, 0])}
        />

        {currentPlayer === 1 ? (
          <>
            <path
              d="M330.88 17.5h25v18h-25z"
              onClick={(e) => handleChangeColor("c1", e, [0, 0])}
              fill={colors.c1}
            />
            <path
              d="m345.24 32.74-6-6 6-6"
              style={{
                fill: "#fcfcfc",
              }}
            />
          </>
        ) : (
          <>
            <path
              d="M330.88 38.28h25v18h-25z"
              fill={colors.c1}
              onClick={(e) => handleChangeColor("c1", e, [0, 0])}
            />
            <path
              d="m345.24 53.52-6-6 6-6"
              style={{
                fill: "#fcfcfc",
              }}
            />
          </>
        )}
        <text
          fill={colors.c2}
          style={{
            fontFamily: "Roboto, sans-serif",
            fontSize: 16,
            isolation: "isolate",
          }}
          transform="translate(247.47 51.79)"
        >
          <tspan x={0} y={0}>
            {sets[1]}
          </tspan>
        </text>
        <text
          fill={colors.c2}
          style={{
            fontFamily: "Roboto, sans-serif",
            fontSize: 16,
            isolation: "isolate",
          }}
          transform="translate(278.96 51.79)"
        >
          <tspan x={0} y={0}>
            {legs[1]}
          </tspan>
        </text>
        <text
          fill={colors.c2}
          style={{
            fontFamily: "Roboto, sans-serif",
            fontSize: 16,
            isolation: "isolate",
          }}
          transform="translate(247.47 32.4)"
        >
          <tspan x={0} y={0}>
            {sets[0]}
          </tspan>
        </text>
        <text
          fill={colors.c2}
          style={{
            fontFamily: "Roboto, sans-serif",
            fontSize: 16,
            isolation: "isolate",
          }}
          transform="translate(278.29 32.4)"
        >
          <tspan x={0} y={0}>
            {legs[0]}
          </tspan>
        </text>

        {firstPlayer === 1 ? (
          <circle cx={226} cy={27} r={2} fill={colors.c1} />
        ) : (
          <circle
            cx={226}
            cy={47}
            r={2}
            fill={colors.c1}
            onClick={(e) => handleChangeColor("c1", e, [0, 0])}
          />
        )}
        <text
          fill={colors.c2}
          style={{
            fontFamily: "Roboto, sans-serif",
            fontSize: 16,
            isolation: "isolate",
          }}
          transform="translate(300.92 32.4)"
        >
          <tspan x={0} y={0}>
            {currentScore[0]}
          </tspan>
        </text>
        <text
          fill={colors.c2}
          style={{
            fontFamily: "Roboto, sans-serif",
            fontSize: 16,
            isolation: "isolate",
          }}
          transform="translate(300.24 51.98)"
        >
          <tspan x={0} y={0}>
            {currentScore[1]}
          </tspan>
        </text>

        <text
          style={{
            fontFamily: "Roboto, sans-serif",
            fontSize: 16,
            isolation: "isolate",
            letterSpacing: "-.01em",
          }}
          transform="translate(119.47 51.79)"
        >
          <tspan x={0} y={0}>
            {teamB}
          </tspan>
        </text>
        <text
          style={{
            fontFamily: "Roboto, sans-serif",
            fontSize: 16,
            isolation: "isolate",
          }}
          transform="translate(119.47 31.79)"
        >
          <tspan
            x={0}
            y={0}
            style={{
              letterSpacing: "-.01em",
            }}
          >
            {teamA}
          </tspan>
        </text>
        <text
          fill={colors.c2}
          style={{
            fontFamily: "Roboto, sans-serif",
            fontSize: 12,
            isolation: "isolate",
            letterSpacing: "-.03em",
          }}
          transform="translate(118.47 11.79)"
        >
          <tspan x={0} y={0}>
            {isFirstTo ? t("noun:first-to") + " " : t("noun:best-of") + " "}{" "}
            {firstTo}
          </tspan>
        </text>
        <text
          fill={colors.c2}
          style={{
            fontFamily: "Roboto, sans-serif",
            fontSize: 12,
            isolation: "isolate",
            letterSpacing: "-.01em",
          }}
          transform="translate(118.47 69.79)"
        >
          <tspan x={0} y={0}>
            {competitionName}
          </tspan>
        </text>
        <text
          fill={colors.c2}
          style={{
            fontFamily: "Roboto, sans-serif",
            fontSize: 12,
            isolation: "isolate",
          }}
          transform="translate(240.2 11.79)"
        >
          <tspan x={0} y={0}>
            {"Sets"}
          </tspan>
        </text>
        <text
          fill={colors.c2}
          style={{
            fontFamily: "Roboto, sans-serif",
            fontSize: 12,
            isolation: "isolate",
          }}
          transform="translate(270.83 11.93)"
        >
          <tspan x={0} y={0}>
            {"Legs"}
          </tspan>
        </text>
      </svg>
    </div>
  );
}

export default DartsScoreboardSets;

const Finish = ({ isRemote, colors, finish, currentPlayer, showFinish }) => {
  const finishes = useMemo(() => {
    return searchScore(finish, Finishes);
  }, [finish]);

  return (
    <g
      style={{
        transition: "transform 0.5s ease",
        transform: showFinish ? "translateX(0)" : "translateX(50%)",
      }}
    >
      <rect
        x={76.67 - 33.33 * (finishes?.length - 1)}
        y={currentPlayer === 2 ? 39 : 17.5}
        width={33.33 * finishes?.length}
        height="18"
        fill={colors.c1}
      />

      {finishes.map((item, i) => {
        return (
          <text
            key={i}
            transform={`translate(${93 + 33.3 * -i} ${
              currentPlayer === 2 ? 52 : 30.5
            })`}
            fontSize={12}
            fill="#fcfcfc"
            fontFamily="Roboto, sans-serif"
            textAnchor="middle"
          >
            {finishes?.[finishes.length - i - 1]}
          </text>
        );
      })}
    </g>
  );
};
