/* eslint-disable no-unused-vars */
import React, { useMemo } from "react";
import { useScoreboardContext } from "../../../../contexts/ScoreboardContext";
import { useTennisContext } from "../TennisContext";
import useTranslations from "../../../../contexts/useTranslations";

export default function PickleballGreen() {
  const t = useTranslations();
  const { teamA, teamB, crest } = useScoreboardContext();

  const { currentScore, isFirstServer, notice, showNotice, gameNumber } =
    useTennisContext();

  // eslint-disable-next-line react/prop-types
  const Rectangle = ({ number }) => {
    let pathValue = useMemo(() => 275.37 + number * 37, [currentScore, number]);
    let lineValue = useMemo(() => 312.37 + number * 37, [currentScore, number]);
    let textPosition = useMemo(() => 293 + number * 37, [currentScore, number]);

    return (
      <g id="layer1">
        <path
          id="Games"
          className={number < gameNumber ? "cls-3" : "cls-5"}
          d={`M${pathValue} 18.91h37.31v73.57h-37.31z`}
        />
        <text
          className="cls-6"
          textAnchor="middle"
          transform={`matrix(.96 0 0 1 ${textPosition} 48.29)`}
        >
          <tspan x={0} y={0}>
            {currentScore?.[number]?.player1}
          </tspan>
        </text>
        <text
          className="cls-6"
          textAnchor="middle"
          transform={`matrix(.96 0 0 1 ${textPosition} 82)`}
        >
          <tspan x={0} y={0}>
            {currentScore?.[number]?.player2}
          </tspan>
        </text>
        {number < gameNumber ? (
          <path className="cls-8" d={`M${lineValue} 23.83V86.7`} />
        ) : null}
      </g>
    );
  };
  return (
    <div>
      {crest[0] === "" ? (
        ""
      ) : (
        <img
          alt={`${t("noun:crest")} 1`}
          style={{
            position: "absolute",
            top: 476,
            left: 400,
            display: "box",
            width: 50,
            height: 30,
            zAxis: 100,
            objectFit: "cover",
          }}
          src={crest[0]}
        />
      )}
      {crest[1] === "" ? (
        ""
      ) : (
        <img
          alt={`${t("noun:crest")} 2`}
          style={{
            position: "absolute",
            top: 529,
            left: 400,
            display: "box",
            width: 50,
            height: 30,
            zAxis: 100,
            objectFit: "cover",
          }}
          src={crest[1]}
        />
      )}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 458.99 92.48">
        <defs>
          <style>
            {
              ".cls-2{letter-spacing:-.07em}.cls-3{fill:#044553}.cls-4{fill:#e7ec9e}.cls-5{fill:#117980}.cls-6{font-size:25.54px;font-family:Roboto-Medium,Roboto;isolation:isolate}.cls-6,.cls-8{fill:#fff}.cls-9{letter-spacing:0}.cls-8{opacity:.25;stroke:#fff;stroke-miterlimit:10;stroke-width:.5px}"
            }
          </style>
        </defs>
        <g id="Layer_1" data-name="Layer 1">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              className="cls-3"
              d="M0 18.91h275.37v73.57H0z"
              id="PlayersBG"
            />
            {isFirstServer ? (
              <ellipse
                className="cls-4"
                cx={262.04}
                cy={39.98}
                rx={3.17}
                ry={3.47}
                id="circle-p2-2"
                data-name="circle-p2"
              />
            ) : (
              <ellipse
                className="cls-4"
                cx={262.04}
                cy={74}
                rx={3.17}
                ry={3.47}
                id="circle-p2"
              />
            )}

            <text className="cls-6" transform="matrix(.96 0 0 1 5.6 48.91)">
              <tspan x={0} y={0}>
                {teamA}
              </tspan>
            </text>
            <text className="cls-6" transform="matrix(.96 0 0 1 5.87 82)">
              <tspan x={0} y={0}>
                {teamB}
              </tspan>
            </text>
          </g>
          {showNotice ? (
            <g id="Note">
              <path id="NoticeBG" className="cls-5" d="M0 0h122.89v18.91H0z" />
              <text
                textAnchor="middle"
                transform="matrix(.96 0 0 1 61.5 14.78)"
                style={{
                  fontSize: "15.33px",
                  fill: "#fff",
                  fontFamily: "Roboto-Medium,Roboto",
                  isolation: "isolate",
                }}
              >
                <tspan x={0} y={0}>
                  {notice}
                </tspan>
              </text>
            </g>
          ) : null}
        </g>
        {currentScore?.length > 0
          ? currentScore.map((number, key) => {
              return <Rectangle key={key} number={key} />;
            })
          : null}
      </svg>
    </div>
  );
}
