import en from "./en.json";
import es from "./es.json";
import fr from "./fr.json";
import ja from "./ja.json";

export default {
  en: {
    localMessages: en,
    remoteURL: "",
  },
  es: {
    localMessages: es,
    remoteURL: "",
  },
  fr: {
    localMessages: fr,
    remoteURL: "",
  },
  ja: {
    localMessages: ja,
    remoteURL: "",
  },
};
