import React from "react";
import { Redirect } from "react-router-dom";

// eslint-disable-next-line react/prop-types
const RedirectToExternalURL = ({ url }) => {
  window.location.href = url;
  return <Redirect to={url} />;
};

export default RedirectToExternalURL;
