import React from "react";

import { ScoreboardProvider } from "../../contexts/ScoreboardContext";
import Internal from "./Internal";
import useTranslations from "../../contexts/useTranslations";
import { Helmet } from "react-helmet";

// eslint-disable-next-line react/prop-types
function Monitor() {
  const t = useTranslations();

  return (
    <>
      <Helmet>
        <title>{t("noun:obscoreboard-monitor")}</title>
        <meta name="description" content={t("pricing:latest-pricing")} />
      </Helmet>
      <ScoreboardProvider>
        <Internal />
      </ScoreboardProvider>
    </>
  );
}

Monitor.propTypes = {};

export default Monitor;
