import React  from "react"
import "../../assets/fonts/font.css"
import { formatScore, useScoreboardContext } from "../../../contexts/ScoreboardContext";


function ScoreboardGraphic4() {
  const {
    // colors,
    // setColors,
    timeIsRed,
    // handleChangeColor,
    teamA,
    teamB,
    currentSport,
    time,
    currentScore,
  } = useScoreboardContext();

  return (
    <div id="container_X" style={{position: "relative"}}>

    <style>
    {
      ".prefix__st1{fill:#ffffff}.prefix__st6{font-family: 'Exo 2', sans-serif}.prefix__st7{font-size:19.8695px}"
    }
  </style>
  
  <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 647 106.6641">
    <defs>
      <style>
        {
          `.cls-1{fill:#06a},.cls-8{:#06a;} .cls-1{stroke:#02739b;stroke-width:3px;} .cls-1, .cls-2{stroke-miterlimit:10;} .cls-2{
            stroke-width:4px;stroke:url(#linear-gradient);} .cls-3, .cls-9{font-size:28px;}.cls-10, .cls-12,
             .cls-3, .cls-9{font-family:Dubai-Regular, Dubai; font-weight: 600; text-transform: uppercase;} .cls-4{fill:url(#radial-gradient);} .cls-5{fill:url(#radial-gradient-2);} 
             .cls-6{fill:url(#linear-gradient-2);} .cls-7{fill:url(#linear-gradient-3);} .cls-10, .cls-12, .cls-9{fill:#fff;} 
             .cls-10{font-size:49.8229px;letter-spacing:-0.02em;} .cls-11{letter-spacing:0em;} .cls-12{font-size:40.4293px;}`
        }
      </style>
      <linearGradient id="linear-gradient" x1="260.3119" y1="286" x2="439.9738" y2="286" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#ededed" />
        <stop offset="0.8933" stopColor="#bcbcbc" />
      </linearGradient>
      <radialGradient id="radial-gradient" cx="143" cy="246.5" r="85.6366" gradientUnits="userSpaceOnUse">
        <stop offset="0" stopColor="#046aaf" />
        <stop offset="1" stopColor="#005a96" />
      </radialGradient>
      <radialGradient id="radial-gradient-2" cx="552" r="85.6366"xlinkHref="#radial-gradient" />
      <linearGradient id="linear-gradient-2" x1="242.8181" y1="278.8181" x2="309.8181" y2="211.8181" gradientUnits="userSpaceOnUse">
        <stop offset="0.1031" stopColor="#494a49" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient id="linear-gradient-3" x1="314.8181" y1="278.8181" x2="381.8181" y2="211.8181" gradientTransform="matrix(-1, 0, 0, 1, 769, 0)"xlinkHref="#linear-gradient-2" />
    </defs>
    <title>scoreboard-blue</title>
    <g id="Layer_6" data-name="Layer 6">
      <path className="cls-1" d="M502,267.1657C496.1111,281.5875,487.2376,291,472.2031,291H229.4856c-15.0345,0-30.809-10.3282-34.4856-24.6288Z" transform="translate(-24 -205)" />
      <path fill={timeIsRed ? "red" : "#e3e3e3"} className="cls-2" d="M437.2857,266l-8.5714,28.5714C426.81,301.2381,423.5714,306,417.2857,306H283c-6.2857,0-8.5714-2.8571-11.4286-11.4286L263,266Z" transform="translate(-24 -205)" />
      <text fill={timeIsRed ? "#fff" : "#00a0d8"} className="cls-3" transform="translate(292.6597 92.4678)">{time}</text>
    </g>
    <g id="Sides">
      <path className="cls-4" d="M248.8821,269H37.1179A13.1565,13.1565,0,0,1,24,255.8821V237.1179A13.1565,13.1565,0,0,1,37.1179,224H248.8821a13.4352,13.4352,0,0,1,9.2645,3.8534A13.0777,13.0777,0,0,1,262,237.1179v18.7642A13.1565,13.1565,0,0,1,248.8821,269Z" transform="translate(-24 -205)" />
      <path className="cls-5" d="M657.8821,269H446.1179A13.1565,13.1565,0,0,1,433,255.8821V237.1179A13.1565,13.1565,0,0,1,446.1179,224H657.8821a13.4352,13.4352,0,0,1,9.2645,3.8534A13.0777,13.0777,0,0,1,671,237.1179v18.7642A13.1565,13.1565,0,0,1,657.8821,269Z" transform="translate(-24 -205)" />
    </g>
    <g id="Scores_BG">
      <path className="cls-6" d="M245,224c2-8,5.4-12,12-12h53v57H233Z" transform="translate(-24 -205)" />
      <path className="cls-7" d="M452,224c-2-8-5.4-12-12-12H387v57h77Z" transform="translate(-24 -205)" />
    </g>
    <g id="VS">
      <path className="cls-8" d="M401,269l-10-52c-2-9-5.4-12-12-12H316a12.0353,12.0353,0,0,0-12,12l-10,52Z" transform="translate(-24 -205)" />
    </g>
    <g id="Layer_7" data-name="Layer 7">
    <text className="cls-9" textAnchor="middle" transform="translate(110 50.4893)">{teamA}</text>
    <text className="cls-9" textAnchor="middle" transform="translate(540 50.4893)">{teamB}</text>
    <text className="cls-10" transform="translate(297.4058 48.7393)">V<tspan className="cls-11" x="30.0435" y="0">S</tspan></text>
    <text className="cls-12" textAnchor="middle" transform="translate(250.0918 48.502)">{formatScore(currentScore, currentSport, 0)}</text>
    <text className="cls-12" textAnchor="middle" transform="translate(400.0918 48.502)">{formatScore(currentScore, currentSport, 2)}</text></g>
  </svg>
  </div>
  )
}

export default ScoreboardGraphic4
