import { getDatabase, onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  getStorage,
  ref as refStorage,
  uploadString,
  getDownloadURL,
} from "firebase/storage";

import { getFirestore, doc, updateDoc } from "firebase/firestore";

export const useGetMonitorInfo = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");

    if (!id) {
      setError("No ID present");
      return;
    }

    const db = getDatabase();

    try {
      const monitorRef = ref(db, `monitors/${id}`);

      const unsubscribe = onValue(
        monitorRef,
        (snapshot) => {
          try {
            const data = snapshot.val();

            if (data === null) {
              setError("No data found for the provided ID.");
            } else {
              setData(data);
            }

            console.log({ data });
          } catch (error) {
            console.error("Error processing snapshot data:", error);
            setError("Error processing data.");
          }
        },
        (error) => {
          console.error("Error listening to database reference:", error);
          setError("Error listening to data.");
        }
      );

      return () => unsubscribe();
    } catch (error) {
      console.error("Error setting up database reference:", error);
      setError("Error finding monitor");
    }
  }, [location.search]);

  return { data, error };
};

export const uploadImageToFirebase = (uid, imageBase64, selectedIndex) => {
  return new Promise((resolve, reject) => {
    // Wrap the async logic inside a separate function
    const uploadAndResize = async () => {
      try {
        // Initialize Firebase storage and firestore
        const storage = getStorage();
        const firestore = getFirestore();

        // Resize the image to a max width and height of 700px
        const resizedImageBase64 = await resizeImage(imageBase64, 700, 700);

        // Define a reference to the storage location (you can customize the path as needed)
        const storageRef = refStorage(
          storage,
          `crests/${uid}/image_${selectedIndex}.png`
        );

        // Upload the image (base64 format)
        const snapshot = await uploadString(
          storageRef,
          resizedImageBase64,
          "data_url"
        );

        // Get the download URL after the upload is complete
        const downloadURL = await getDownloadURL(snapshot.ref);

        // Define the reference to the Firestore document
        const customerDocRef = doc(firestore, `customers/${uid}`);

        // Update Firestore with the new image link at the selected index
        const imagePath = `crests.${selectedIndex}`;
        await updateDoc(customerDocRef, {
          [imagePath]: downloadURL,
        });

        console.log("Image uploaded and Firestore updated successfully!");
        resolve(downloadURL);
      } catch (error) {
        console.error("Error uploading image and updating Firestore:", error);
        reject(error);
      }
    };

    // Call the async function
    uploadAndResize();
  });
};

const resizeImage = (base64Str, maxWidth, maxHeight) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = base64Str;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      let width = img.width;
      let height = img.height;

      // Calculate the new dimensions
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      // Set canvas dimensions and draw the resized image
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      // Convert the canvas back to base64
      resolve(canvas.toDataURL("image/png"));
    };
  });
};
