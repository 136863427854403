/* eslint-disable  */
import React, { useState } from "react";
import FullPageLoader from "../../components/PageLoading";
import ScoreboardGraphicX from "../../scoreboard-app/components/ScoreboardStyles/ScoreboardGraphicX";
import "./share.css";
import Timer from "../../scoreboard-app/components/Timer";
import { useMonitor } from "../../contexts/MonitorContext";
import { sportsSettings } from "../../scoreboard-app/components/Initialise_Settings";

const ShareUI = ({ activeComponents = [] }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    seconds,
    minutes,
    halfLength,
    setSeconds,
    setMinutes,
    setTime,
    setTimeIsRed,
    timeForward,
    started,
    setStarted,
    setFirstHalfCounter,
    setSecondHalfCounter,
    updateTimerState,
  } = useMonitor();

  // const [activeComponents, setActiveComponents] = useState([
  //   {
  //     type: "Scoreboard",
  //     props: {
  //       leftOffset: 0,
  //       topOffset: 15,
  //       scale: 1,
  //       position: "topLeft",
  //     },
  //   },
  //   {
  //     type: "Scoreboard",
  //     props: {
  //       leftOffset: 50,
  //       topOffset: 0,
  //       scale: 1,
  //       position: "bottomRight",
  //     },
  //   },
  // ]);

  if (isLoading) return <FullPageLoader />;

  return (
    <div className="container-share">
      <Timer
        seconds={seconds}
        minutes={minutes}
        halfLength={halfLength}
        setSeconds={setSeconds}
        setMinutes={setMinutes}
        setTime={setTime}
        setTimeIsRed={setTimeIsRed}
        timeForward={timeForward}
        started={started}
        setStarted={setStarted}
        setFirstHalfCounter={setFirstHalfCounter}
        setSecondHalfCounter={setSecondHalfCounter}
        updateTimerState={() => {}}
      />
      <div className="presentation-place">
        {activeComponents.map((component, index) => (
          <div className="slide" key={index}>
            {renderComponent({
              type: component.type,
              props: {
                ...component.props,
                index: index,
              },
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

const Scoreboard = ({
  index,
  leftOffset = 0,
  topOffset = 0,
  scale = 1,
  position = "fullscreen",
  currentScoreboard = 0,
  currentSport = "f",
}) => {
  let margins = {};
  const [height, setHeight] = useState(0);
  const { time } = useMonitor();

  switch (position) {
    case "topLeft":
      margins = {
        top: `calc(2% - ${topOffset}px)`,
        left: `calc(2% + ${leftOffset}px)`,
      };
      break;
    case "topRight":
      margins = {
        top: `calc(2% - ${topOffset}px)`,
        left: `calc(68% + ${leftOffset}px)`,
      };
      break;
    case "bottomRight":
      margins = {
        top: `calc(81% - ${topOffset}px)`,
        left: `calc(66% + ${leftOffset}px)`,
      };
      break;
    case "bottomLeft":
      margins = {
        top: `calc(81% - ${topOffset}px)`,
        left: `calc(2% + ${leftOffset}px)`,
      };
    case "fullscreen":
      margins = {
        position: "default",
        marginLeft: "auto",
        marginRight: "auto",
      };
      break;
  }

  const ScoreboardComponent =
    currentSport &&
    sportsSettings[currentSport]?.scoreboards[currentScoreboard]?.sb;

  return (
    <div
      style={{
        height: "100%",
        margin: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "red",
      }}
    >
      <div
        key={index}
        style={{
          width: `${position === "fullscreen" ? 100 : 30 * scale}%`,
          padding: "2%",
          position: "absolute",
          ...margins,
          // marginLeft: `calc(2% - ${leftOffset}px)`,
          // marginTop: `calc(2% - ${topOffset}px)`,
        }}
      >
        {/* <ScoreboardComponent /> */}
        <ScoreboardGraphicX time={time} />
      </div>
    </div>
  );
};

const renderComponent = (component) => {
  const { type, props } = component;
  switch (type) {
    case "Scoreboard":
      return <Scoreboard {...props} />;
    // case "LowerThird":
    //   return <LowerThird {...props} />;
    // case "LeaderBoard":
    //   return <LeaderBoard {...props} />;
    default:
      return null;
  }
};

export default ShareUI;
